import { createStore } from 'vuex'
import followCuratedModule from '@src/modules/discovery/store/follow-curated.js'
import customizeSidebarModule from '@src/modules/discovery/store/customize-sidebar.js'
import discoveryArticlesModule from '@src/modules/discovery/store/articles.js'
import discoveryFoldersModule from '@src/modules/discovery/store/folders.js'
import discoveryImagesModule from '@src/modules/discovery/store/images.js'
import discoveryInsightsModule from '@src/modules/discovery/store/insights.js'
import discoveryPocketModule from '@src/modules/discovery/store/pocket.js'
import discoveryQuotesModule from '@src/modules/discovery/store/quotes.js'
import discoveryTopicsModule from '@src/modules/discovery/store/topics.js'
import discoveryTwitterContentModule from '@src/modules/discovery/store/twitter-content.js'
import discoveryTwitterSharesModule from '@src/modules/discovery/store/twitter-shares.js'
import discoveryVideoModule from '@src/modules/discovery/store/video.js'
import discoverModule from '@src/modules/discovery/store/discover.js'
import discoveryArchiveModule from '@src/modules/discovery/store/archive.js'
import discoveryInfluencerModule from '@src/modules/discovery/store/influencers.js'
import feederModule from '@src/modules/discovery/store/feeder.js'
import confirmation from '@common/store/confirmation'
import appModule from '@common/store/app-store.js'
import accountModule from '@src/modules/account/store/account'
import onboardingModule from '@src/modules/onboarding/store/onboarding'
import instagramPublishingOptions from '@src/modules/publish/store/states/social/instagram-publishing-options'
// planner
import plannerModule from '@src/modules/planner/store/planner'

import { settingStore } from '@src/modules/setting/store/setting'
import { analyticsStore } from '@src/modules/analytics/store/analytics'
import { integrationStore } from '@src/modules/integration/store/integration'
import blogSubmission from '@src/modules/composer/store/blog-submission'
import composer from '@src/modules/composer/store/composer'
import publication from '@src/modules/composer/store/publication'

import { automationStore } from '@src/modules/automation/store/automation'
import { publishStore } from '@src/modules/publish/store/publish'

// discovery

// app related

// composer

// inbox
import inbox from '@src/modules/inbox/store/inbox'
import inboxRevamp from '@src/modules/inbox-revamp/store/inbox-revamp'
import alertModule from './alert.js'
import ssoModule from './sso.js'
import jwtModule from './jwt.js'
import axiosModule from './axios.js'

export const store = createStore({
  modules: {
    jwt: jwtModule,
    axios: axiosModule,
    sso: ssoModule,
    app: appModule,
    confirmation,
    alert: alertModule,
    curated: followCuratedModule,
    discover: discoverModule,
    customize_sidebar: customizeSidebarModule,
    discoveryFolders: discoveryFoldersModule,
    discoveryArchive: discoveryArchiveModule,
    discoveryQuotes: discoveryQuotesModule,
    discoveryImages: discoveryImagesModule,
    discoveryTopics: discoveryTopicsModule,
    discoveryTwitterShares: discoveryTwitterSharesModule,
    discoveryTwitterContent: discoveryTwitterContentModule,
    discoverySearch: discoveryArticlesModule,
    discoveryInsights: discoveryInsightsModule,
    discoveryVideos: discoveryVideoModule,
    discoveryInfluencer: discoveryInfluencerModule,
    feeder: feederModule,
    pocket: discoveryPocketModule,
    account: accountModule,
    onBoarding: onboardingModule,
    setting: settingStore,
    integration: integrationStore,
    automation: automationStore,
    publish: publishStore,
    composerBlog: blogSubmission,
    composer,
    publication,
    planner: plannerModule,
    analytics: analyticsStore,
    inbox,
    inboxRevamp,
    instagramPublishingOptions,
  },
})

export const useStore = () => store
