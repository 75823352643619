<template>
  <ListItem
    :selected="false"
    class="text-sm flex items-center gap-[14px] group select-none px-3 py-2"
    @click="$emit('click')"
  >
    <div class="flex items-center gap-3 flex-1">
      <div class="relative">
        <img
          :src="channel.platform_image"
          :alt="channel.platform_name"
          class="w-8 h-8 rounded-full border"
          @error="
            (event) => {
              event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            }
          "
        />
        <div class="absolute -bottom-1 -right-1">
          <img
            :src="getSocialImageRounded(platform)"
            class="w-5 h-5 rounded-full"
            :alt="platform"
          />
        </div>
      </div>
      <div class="flex flex-col max-w-[110px]">
        <span class="text-sm text-[#26303EB2] font-medium truncate" :title="channel.platform_name">{{ channel.platform_name }}</span>
        <span class="text-xs text-[#979CA0]">{{ channel.type }}</span>
      </div>
    </div>
    <img
      v-if="isReconnectRequired(channel)"
      v-tooltip="'Your Account Token is Expired'"
      src="@assets/img/composer/warning-icon.svg"
      alt=" "
      class="w-5 h-5 mx-2 ml-auto"
    />
    <Checkbox
      :model-value="selected"
    />
  </ListItem>
</template>

<script setup>
import { ListItem, Checkbox } from '@contentstudio/ui'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { getSocialImageRounded } = useComposerHelper()

const props = defineProps({
  channel: {
    type: Object,
    required: true
  },
  platform: {
    type: String,
    required: true
  },
  selected: {
    type: Boolean,
    default: false
  }
})

defineEmits(['click'])

const isReconnectRequired = (account) => {
  switch (props.platform) {
    // You can Add platforms specific logic here
    default:
      return ['invalid', 'expired'].includes(account?.validity)
  }
}
</script>
