<template>
  <Teleport to="body">
    <Transition name="drawer-backdrop">
      <div
        v-if="isOpen && !noBackdrop"
        class="fixed inset-0 bg-black-900/10 z-[999]"
        @click="backDropCloseDrawer"
      />
    </Transition>

    <Transition :name="position === 'left' ? 'drawer-left' : 'drawer-right'">
      <div
        v-if="isOpen"
        :class="[
          'fixed top-0 h-full bg-white z-[1000] shadow-xl',
          position === 'left' ? 'left-0' : 'right-0',
        ]"
        :style="{
          width: computedWidth,
          height: `calc(100% - ${topBannersHeight}px)`,
          top: `${topBannersHeight}px`,
        }"
      >
        <!-- Header -->
        <slot name="header" :close="closeDrawer">
          <div class="bg-white border-b border-gray-200 p-4">
            <div class="flex items-center justify-between">
              <slot name="title">{{ props.title }}</slot>
              <button
                class="text-gray-400 hover:text-gray-500"
                @click="closeDrawer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </slot>

        <!-- Body -->
        <div
          :class="[
            'overflow-y-auto bg-white h-[calc(100%-110px)]',
            props.bodyClass,
          ]"
        >
          <slot :close="closeDrawer"></slot>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { onMounted, onBeforeUnmount, computed, ref, watch } from 'vue'
import usePlatform from "@common/composables/usePlatform";
const { topBannersHeight } = usePlatform()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: 'right',
    validator: (value) => ['left', 'right'].includes(value),
  },
  width: {
    type: [String, Number],
    default: '400px',
  },
  noCloseOnBackdrop: {
    type: Boolean,
    default: true,
  },
  noBackdrop: {
    type: Boolean,
    default: false,
  },
  bodyClass: {
    type: String,
    default: 'px-4 py-6',
  },
})

const emit = defineEmits(['update:modelValue'])

// Local state
const isOpen = ref(props.modelValue)

// Computed width value
const computedWidth = computed(() => {
  if (typeof props.width === 'number') {
    return `${props.width}px`
  }
  return props.width
})

// Watch for prop changes
watch(
  () => props.modelValue,
  (newVal) => {
    console.log('Prop changed:', newVal)
    isOpen.value = newVal
  },
  { immediate: true }
)

const closeDrawer = () => {

    isOpen.value = false
    emit('update:modelValue', false)

}

const backDropCloseDrawer = () => {
  if (!props.noBackdrop || !props.noCloseOnBackdrop) {
    isOpen.value = false
    emit('update:modelValue', false)
  }
}

const handleEscape = (e) => {
  if (e.key === 'Escape' && isOpen.value && !props.noCloseOnBackdrop) {
    closeDrawer()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscape)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleEscape)
})
</script>

<style>
/* Backdrop animations */
.drawer-backdrop-enter-active,
.drawer-backdrop-leave-active {
  transition: opacity 0.3s ease;
}

.drawer-backdrop-enter-from,
.drawer-backdrop-leave-to {
  opacity: 0;
}

/* Left drawer animations */
.drawer-left-enter-active,
.drawer-left-leave-active {
  transition: transform 0.3s ease;
}

.drawer-left-enter-from,
.drawer-left-leave-to {
  transform: translateX(-100%);
}

/* Right drawer animations */
.drawer-right-enter-active,
.drawer-right-leave-active {
  transition: transform 0.3s ease;
}

.drawer-right-enter-from,
.drawer-right-leave-to {
  transform: translateX(100%);
}
</style>
