<template>
  <div class="platform-pill rounded-full bg-white shadow-sm px-2 py-1 justify-center" :class="[customClass, { 'flex items-center gap-3': true }]">
    <img
      :src="currentConversationPlatform?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
      :alt="currentConversationPlatform?.platform_name"
      class="w-6 h-6 rounded-full border object-cover"
    />
    <div v-if="showName" class="flex flex-col gap-1">
      <span v-tooltip="currentConversationPlatform?.platform_name" class="text-sm max-w-[17rem] truncate leading-none">
        {{ currentConversationPlatform?.platform_name }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'

// Get currentConversationPlatform directly from useInbox
const { currentConversationPlatform } = useInbox()

defineProps({
  showName: {
    type: Boolean,
    default: true
  },
  customClass: {
    type: String,
    default: ''
  }
})
</script>
