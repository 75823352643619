<template>
  <div
    class="mx-auto w-auto max-w-[500px] my-1 px-3 py-2 rounded-full text-center text-sm leading-none"
    :class="[
      actionMapObject?.colors?.border,
      actionMapObject?.colors?.background,
      { 'opacity-50': !isValidAction },
    ]"
    role="status"
    :aria-label="getAriaLabel"
  >
    <div class="flex flex-wrap items-center gap-1">
      <template v-if="isValidAction">
        <span class="inline-block font-semibold">{{actionPerformedBy?.user_name || 'Unknown User'}}</span>
        <span class="inline-block">{{actionMessage || 'performed an action'}}</span>
        <template v-if="action?.type === 'ASSIGNED' && action?.assigned_to?.user_name">
          <span class="inline-block font-semibold">{{action.assigned_to.user_name}}</span>
        </template>
      </template>
      <template v-else>
        <span class="inline-block">Invalid or unsupported action type</span>
      </template>
      <span class="inline-block">
        {{ `| ${timeAgo}` }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'

const { getUTCFormattedTimeZoneDate } = useComposerHelper()
/**
 * Action type constants to avoid string literals
 * @type {Object.<string, string>}
 */
const ACTION_TYPES = {
  MARKED_AS_DONE: 'MARKED_AS_DONE',
  ARCHIVED: 'ARCHIVED',
  ASSIGNED: 'ASSIGNED'
}

/**
 * Mapping of action types to their visual and textual representations
 * @type {Object.<string, {colors: {border: string, background: string}, message: {true: string, false: string}, booleanKey: string}>}
 */
const actionMap = {
  [ACTION_TYPES.MARKED_AS_DONE]: {
    colors:{
      border: 'border border-[#69B4FF33]',
      background: 'bg-[#ECFFD1]'
    },
    message: {
      true: 'marked this as done',
      false: 'marked this as undone'
    },
    booleanKey: 'is_marked_done'
  },
  [ACTION_TYPES.ARCHIVED]: {
    colors:{
      border: 'border border-[#69B4FF33]',
      background: 'bg-[#FFEBD1]'
    },
    message: {
      true: 'archived this conversation',
      false: 'unarchived this conversation'
    },
    booleanKey: 'is_archived'
  },
  [ACTION_TYPES.ASSIGNED]: {
    colors:{
      border: 'border border-[#69B4FF33]',
      background: 'bg-[#D1F1FF]'
    },
    message: {
      true: 'assigned this conversation to',
      false: 'unassigned this conversation from'
    },
    booleanKey: 'is_assigned'
  }
}

/**
 * Props definition for ChatActionUpdate component
 * @typedef {Object} ChatActionMessage
 * @property {Object} action - The action details
 * @property {string} action.type - Type of action (MARKED_AS_DONE, ARCHIVED, ASSIGNED)
 * @property {Object} action.action_performed_by - User who performed the action
 * @property {Object} [action.assigned_to] - User to whom the conversation was assigned (for ASSIGNED type)
 */
const props = defineProps({
  /** @type {ChatActionMessage} The message object containing action details */
  message: {
    type: Object,
    required: true,
    validator: (value) => {
      return value && value.action && value.action.type;
    }
  }
})

/**
 * Computed property for the action object
 * @returns {Object|null} The action object from the message
 */
const action = computed(() => {
  return props.message?.action
})

/**
 * Validates if the action type is supported and data is valid
 * @returns {boolean} Whether the action is valid
 */
const isValidAction = computed(() => {
  const actionType = action.value?.type
  return actionType && Object.values(ACTION_TYPES).includes(actionType)
})

/**
 * Gets the action map object for the current action type
 * @returns {Object|null} The action map object containing colors and messages
 */
const actionMapObject = computed(() => {
  return isValidAction.value ? actionMap?.[action.value?.type] : null
})

/**
 * Gets the appropriate message based on the action type and boolean value
 * @returns {string|null} The formatted action message
 */
const actionMessage = computed(() => {
  if (!isValidAction.value || !actionMapObject.value) return null

  const key = actionMapObject.value?.booleanKey
  const booleanValue = action.value?.[key]
  return actionMapObject.value?.message[booleanValue] || null
})

/**
 * Gets the user who performed the action
 * @returns {Object|null} The user object who performed the action
 */
const actionPerformedBy = computed(() => {
  return action.value?.action_performed_by
})

/**
 * Generates an accessible label for screen readers
 * @returns {string} The ARIA label describing the action
 */
const getAriaLabel = computed(() => {
  if (!isValidAction.value) return 'Invalid action update'

  const performer = actionPerformedBy.value?.user_name || 'Unknown user'
  const message = actionMessage.value || 'performed an action'
  let label = `${performer} ${message}`

  if (action.value?.type === ACTION_TYPES.ASSIGNED && action.value?.assigned_to?.user_name) {
    label += ` ${action.value.assigned_to.user_name}`
  }

  return label
})

const timeAgo = computed(() => {
  return getUTCFormattedTimeZoneDate(props.message?.created_time).formatTime()
})
</script>
