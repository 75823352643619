<template>
  <div class="relative h-full">
    <!-- Overlay for mobile -->
    <div
      v-if="
        (isMobileView && hoverExpand) ||
        (isExpanded && isMobileView && !hoverExpand)
      "
      class="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300"
      :class="{ hidden: !isHovering && !isMobileOpen && !isTransitioning }"
    ></div>
    <!-- Fixed width wrapper for non-mobile -->
    <div
      class="h-full transition-all duration-300 ease-in-out"
      :class="[
        !isMobileView ? (isExpanded ? 'w-[250px]' : 'w-16') : '',
        isMobileView ? 'w-16' : '',
      ]"
    >
      <!-- Sidebar -->
      <aside
        class="flex flex-col relative inset-y-0 left-0 z-50 bg-white transition-[width] duration-300 ease-in-out h-full"
        :class="[
          {
            'w-[250px]':
              isExpanded || (isMobileView && isHovering && hoverExpand),
            'w-16':
              (isMobileView && (!isHovering || !hoverExpand)) ||
              (!isMobileView && !isExpanded),
            '!absolute shadow': isAbsolute,
            'transform translate-x-0': true,
          },
        ]"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <!-- Header slot with transition -->
        <transition
          name="fade"
          mode="out-in"
          @before-enter="startTransition"
          @after-leave="endTransition"
        >
          <div
            :key="
              isExpanded || (isMobileView && isHovering && hoverExpand)
                ? 'expanded'
                : 'shrunk'
            "
          >
            <slot
              v-if="isExpanded || (isMobileView && isHovering && hoverExpand)"
              name="head-expanded"
            ></slot>
            <slot v-else name="head-shrink"></slot>
          </div>
        </transition>

        <!-- Body slot with transition -->
        <transition
          name="fade"
          mode="out-in"
          @before-enter="startTransition"
          @after-leave="endTransition"
        >
          <div
            :key="
              isExpanded || (isMobileView && isHovering && hoverExpand)
                ? 'expanded'
                : 'shrunk'
            "
            class="overflow-auto flex-1"
          >
            <slot
              v-if="isExpanded || (isMobileView && isHovering && hoverExpand)"
              name="body-expanded"
            ></slot>
            <slot v-else name="body-shrink"></slot>
          </div>
        </transition>
      </aside>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  expanded: {
    type: Boolean,
    default: true,
  },
  hoverExpand: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'update:expanded'])

const isMobileOpen = ref(props.modelValue)
const isExpanded = ref(props.expanded)
const isHovering = ref(false)
const isTransitioning = ref(false)
const isMobileView = ref(false)
let transitionTimeout = null

const isAbsolute = computed(() => {
  return (
    (isMobileView.value && props.hoverExpand && isHovering.value) ||
    (isExpanded.value && isMobileView.value && !props.hoverExpand) ||
    isTransitioning.value
  )
})

// Check if mobile view
const checkMobileView = () => {
  const wasMobile = isMobileView.value
  isMobileView.value = window.innerWidth <= 1366 // lg breakpoint

  // Reset hover state and collapse sidebar when switching to mobile
  if (!wasMobile && isMobileView.value) {
    isHovering.value = false
    isExpanded.value = false
    emit('update:expanded', false)
  }
}

// Set up resize listener
onMounted(() => {
  checkMobileView() // This will collapse on initial load if mobile
  window.addEventListener('resize', checkMobileView)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkMobileView)
  if (transitionTimeout) {
    clearTimeout(transitionTimeout)
  }
})

// Watch for prop changes
watch(
  () => props.modelValue,
  (newVal) => {
    isMobileOpen.value = newVal
  }
)

watch(
  () => props.expanded,
  (newVal) => {
    isExpanded.value = newVal
  }
)

// Watch for internal state changes
watch(isMobileOpen, (newVal) => {
  emit('update:modelValue', newVal)
})

watch(isExpanded, (newVal) => {
  if (!newVal) {
    isTransitioning.value = true
    if (transitionTimeout) {
      clearTimeout(transitionTimeout)
    }
    transitionTimeout = setTimeout(() => {
      isTransitioning.value = false
    }, 300) // Match the duration in the transition class
  }
  emit('update:expanded', newVal)
})

watch(isMobileView, (newVal) => {
  if (newVal) {
    isExpanded.value = false
    emit('update:expanded', false)
  }
})

const handleMouseEnter = () => {
  if (props.hoverExpand && isMobileView.value) {
    isHovering.value = true
    isTransitioning.value = true
    if (transitionTimeout) {
      clearTimeout(transitionTimeout)
    }
    transitionTimeout = setTimeout(() => {
      isTransitioning.value = false
    }, 300)
  }
}

const handleMouseLeave = () => {
  if (props.hoverExpand && isMobileView.value) {
    isHovering.value = false
    isTransitioning.value = true
    if (transitionTimeout) {
      clearTimeout(transitionTimeout)
    }
    transitionTimeout = setTimeout(() => {
      isTransitioning.value = false
    }, 300)
  }
}

const toggleMobile = () => {
  if (isMobileView.value) {
    isMobileOpen.value = !isMobileOpen.value
    isHovering.value = false
  }
}

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}

const startTransition = () => {
  isTransitioning.value = true
}

const endTransition = () => {
  isTransitioning.value = false
}

// Expose functions for parent component
defineExpose({
  toggleMobile,
  toggleExpand,
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
