<template>
  <div class="leading-none">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <g clip-path="url(#clip0_34334_12188)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.19915 1.67423C7.92297 1.62923 7.64171 1.71923 7.44315 1.91779L1.43734 7.92361C1.35664 8.00431 1.29262 8.10011 1.24894 8.20555C1.20527 8.311 1.18279 8.42401 1.18279 8.53814C1.18279 8.65227 1.20527 8.76528 1.24894 8.87072C1.29262 8.97616 1.35664 9.07197 1.43734 9.15267L5.1684 12.8832C5.2491 12.9639 5.34491 13.0279 5.45035 13.0716C5.55579 13.1152 5.6688 13.1377 5.78293 13.1377C5.89706 13.1377 6.01007 13.1152 6.11552 13.0716C6.22096 13.0279 6.31676 12.9639 6.39746 12.8832L12.4033 6.87736C12.6013 6.67935 12.6918 6.39811 12.6463 6.12192L12.1192 2.91792C12.0896 2.73862 12.0046 2.57311 11.876 2.44467C11.7475 2.31622 11.5819 2.2313 11.4026 2.20185L8.19915 1.67423ZM6.80696 1.28104C7.00564 1.08226 7.24882 0.933679 7.51636 0.847598C7.78389 0.761517 8.06809 0.740416 8.3454 0.786042L11.5488 1.31367C11.9139 1.37382 12.2508 1.54688 12.5124 1.80851C12.7739 2.07015 12.9468 2.40719 13.0068 2.77223L13.5345 5.97567C13.6267 6.53817 13.4428 7.11079 13.0395 7.51354L7.03365 13.5205C6.86938 13.6848 6.67435 13.8151 6.4597 13.904C6.24505 13.9929 6.01499 14.0387 5.78265 14.0387C5.55032 14.0387 5.32025 13.9929 5.10561 13.904C4.89096 13.8151 4.69593 13.6848 4.53165 13.5205L0.801152 9.78886C0.469509 9.45711 0.283203 9.00722 0.283203 8.53814C0.283203 8.06905 0.469509 7.61916 0.801152 7.28742L6.80696 1.28048V1.28104Z" fill="currentColor" stroke="currentColor" stroke-width="0.482143"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.84404 3.84226C9.70232 3.84226 9.5664 3.89856 9.46618 3.99878C9.36597 4.09899 9.30967 4.23491 9.30967 4.37664C9.30967 4.51836 9.36597 4.65428 9.46618 4.7545C9.5664 4.85471 9.70232 4.91101 9.84404 4.91101C9.98577 4.91101 10.1217 4.85471 10.2219 4.7545C10.3221 4.65428 10.3784 4.51836 10.3784 4.37664C10.3784 4.23491 10.3221 4.09899 10.2219 3.99878C10.1217 3.89856 9.98577 3.84226 9.84404 3.84226ZM8.40967 4.37664C8.40967 4.18827 8.44677 4.00175 8.51885 3.82772C8.59094 3.6537 8.69659 3.49557 8.82979 3.36238C8.96298 3.22919 9.12111 3.12353 9.29513 3.05145C9.46916 2.97936 9.65568 2.94226 9.84404 2.94226C10.0324 2.94226 10.2189 2.97936 10.393 3.05145C10.567 3.12353 10.7251 3.22919 10.8583 3.36238C10.9915 3.49557 11.0971 3.6537 11.1692 3.82772C11.2413 4.00175 11.2784 4.18827 11.2784 4.37664C11.2784 4.75706 11.1273 5.12189 10.8583 5.39089C10.5893 5.65989 10.2245 5.81101 9.84404 5.81101C9.46362 5.81101 9.09878 5.65989 8.82979 5.39089C8.56079 5.12189 8.40967 4.75706 8.40967 4.37664Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_34334_12188">
          <rect width="14" height="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script setup>
</script>
