<script>
import {defineAsyncComponent} from "vue";
import MediaRemoveConfirmBox from '@src/modules/common/components/dialogs/MediaRemoveConfirmBox'
import { mapGetters, mapActions } from 'vuex'
import { authenticationTypes } from '@state/mutation-types'
import moment from 'moment'
import SocialApprovalModal from '@src/modules/common/components/dialogs/approval/SocialApprovalModal'
import * as _ from 'underscore'
import MediaStorageLimitsExceededModal from '@src/modules/common/components/subscription-limits-exceeded/MediaStorageLimitsExceededModal'
import IncreaseLimitsDialog from '@src/modules/setting/components/billing/dialogs/IncreaseLimitsDialog'
import CardInfoDialog from '@src/modules/setting/components/billing/dialogs/Card-info-dialog'
import SubscriptionLimitsExceededModal from '@src/modules/common/components/subscription-limits-exceeded/SubscriptionLimitsExceededModal'
import createWorkspaceModal from '@src/modules/account/components/CreateWorkspaceModel'

import SocialModal from '@src/modules/composer_v2/views/SocialModal'
import { EventBus } from '@common/lib/event-bus'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import AIChatMain from '@src/modules/AI-tools/AIChatMain.vue'
import AIChatWidget from '@src/modules/AI-tools/AIChatWidget.vue'
import AIChatModal from '@src/modules/AI-tools/AIChatModal.vue'
import GetStartedNewModal from '@src/modules/account/views/onboarding/GetStartedNewModal'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import ComposerWidget from "@modules/composer_v2/components/ComposerWidget.vue";
import { planner } from '@modules/planner/store/mutation-types'

// loading common components
import SubscriptionPlansModal from "@modules/billing/components/SubscriptionPlansModal.vue";
import { baseUrl } from '@src/config/api-utils'
import useDateFormat from '@common/composables/useDateFormat'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import SocialShare from './modules/publish/components/dialogs/SocialShare'
import BlogShare from './modules/publish/components/dialogs/BlogPost'
import TopHeaderBar from './components/layout/TopHeaderBar.vue'
import GettingStarted from './modules/common/components/widgets/GettingStarted'

import UploadMediaModal from './modules/publish/components/media-library/components/UploadMediaModal'
import WorkspaceLimitsDialog from './modules/setting/components/billing/dialogs/WorkspaceLimitsDialog'
import InboxAddOnModal from '@/src/components/addons/InboxAddOnModal.vue'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

const DashboardNotificationBanner = defineAsyncComponent(() => import('@src/modules/dashboard/components/DashboardNotificationBanner.vue'))
const BannerNotificationModal = defineAsyncComponent(() => import('@common/components/dialogs/BannerNotificationModal.vue'))
const UpdateFeaturePlan = defineAsyncComponent(() => import('@common/components/dialogs/UpdateFeaturePlan'))
const CommentImagePreview = defineAsyncComponent(() => import('./modules/planner/components/view/feed-view/ImagePreview'))
const ActivateVideoCampaign = defineAsyncComponent(() => import('./modules/automation/components/video/dialogs/ActivateCampaign'))
const RemoveCsvPlansConfirmation = defineAsyncComponent(() => import('./modules/automation/components/csv/dialogs/RemoveCsvPlansConfirmation'))
const RemoveCsvPlanConfirmation = defineAsyncComponent(() => import('./modules/automation/components/csv/dialogs/RemoveCsvPlanConfirmation'))
const InsertFileModal = defineAsyncComponent(() => import('./modules/publish/components/widget/file/InsertFileModal'))
const PasteFromWord = defineAsyncComponent(() => import('./modules/publish/components/widget/file/PasteFromWord'))
const DisplayFile = defineAsyncComponent(() => import('./modules/publish/components/dialogs/DisplayFile'))

const EnhanceImagePintura = defineAsyncComponent(() => import('./modules/publish/components/dialogs/EnhanceImagePintura'))
const HelpDropdown = defineAsyncComponent(() => import('@common/components/dropdowns/HelpDropdown'))
const offlineJs = defineAsyncComponent(() => import('@src/modules/common/components/offline-status/OfflineJs'))
const HeadNotificationSlider = defineAsyncComponent(() => import('@common/components/header-notifications/HeadNotificationSlider'))
const HeaderBillingNotifications = defineAsyncComponent(() => import('@common/components/header-notifications/HeaderBillingNotifications'))

const StickyBanner = defineAsyncComponent(() => import('./components/common/StickyBanner'))
const NoticeBanner = defineAsyncComponent(() => import('./components/common/NoticeBanner'))
const ChangeTrialPlanDialog = defineAsyncComponent(() => import('@src/modules/setting/components/billing/dialogs/ChangeTrialPlanDialog'))
const YoutubeVideoPlayerModal = defineAsyncComponent(() => import('./components/common/YoutubeVideoPlayerModal'))

export default {
  components: {
    SubscriptionPlansModal,
    ComposerWidget,
    AIChatModal,
    AIChatWidget,
    AIChatMain,
    createWorkspaceModal,
    EnhanceImagePintura,
    MediaRemoveConfirmBox,
    SubscriptionLimitsExceededModal,
    UploadMediaModal,
    UpdateFeaturePlan,
    TopHeaderBar,
    CommentImagePreview,
    ActivateVideoCampaign,
    RemoveCsvPlanConfirmation,
    RemoveCsvPlansConfirmation,
    InsertFileModal,
    PasteFromWord,
    SocialShare,
    SocialApprovalModal,
    BlogShare,
    DisplayFile,
    HelpDropdown,
    offlineJs,
    HeadNotificationSlider,
    HeaderBillingNotifications,
    StickyBanner,
    NoticeBanner,
    MediaStorageLimitsExceededModal,
    IncreaseLimitsDialog,
    CardInfoDialog,
    WorkspaceLimitsDialog,
    GettingStarted,
    ChangeTrialPlanDialog,
    SocialModal,
    BannerNotificationModal,
    YoutubeVideoPlayerModal,
    GetStartedNewModal,
    DashboardNotificationBanner,
    InboxAddOnModal,
  },
  setup(){
    const {appData, isWhiteLabelDomain, isWhiteLabeledWorkspace} = useWhiteLabelApplication()
    const {isDirty, buttonText} = useSocialAccountModal()
    const { isDraftComposer } = useComposerHelper()
    const { momentWrapper } = useDateFormat()
    const {canAccess} = useFeatures()
    const inboxAccess = canAccess('social_inbox')

    return {
      isDirty,
      buttonText,
      isDraftComposer,
      appData,
      isWhiteLabelDomain,
      isWhiteLabeledWorkspace,
      momentWrapper,
      inboxAccess
    }
  },
  data() {
    return {
      is_loading: true,
      instagramCompetitorsChannel: '',
      facebookCompetitorsChannel: '',
      twitterJobsChannel: '',
      routesToIgnoreAIChatBot: ['composerBlog', 'planner_instagram_grid_view', 'trial_expired', 'planner_tiktok_grid_view'],
      dashboardBannerState: true,
    }
  },
  /* onIdle() {
      this.logoutBroadcastUser(this.getProfile._id)
    },
    onActive() {
      this.loadUserBroadCasters()
    }, */
  computed: {
    ...mapGetters([
      'getActiveWorkspaceTriggers',
      'getJWTToken',
      'getCurrentNotification',
      'getPlans',
      'getActiveWorkspace',
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'getUpgradeOfferForStarterPlan',
      'getInstagramAccounts',
      'getBillingDetails',
      'getPaymentFailedDetails',
      'getWorkspaces',
    ]),
    hasGetStartedModal() {
      return this.$route.path.includes('dashboard')
    },
    /**
     * checking application is loaded or not
     **/
    showLoader() {
      return this.is_loading
    },

    /**
     * to show onboarding widget
     **/
    showOnboardingWidget() {

      // If user workspace onboarding completed then we will not show the onboarding widget
      if(this.onBoardingWorkspaceProgressValue()) {
        return false
      }

      return (
          !this.getActiveWorkspace.onboarding &&
          (this.getUserRole === 'Super Admin' || this.getUserRole === 'Admin') &&
          this.$route.name !== 'workspaces' &&
          this.$route.name !== 'composerSocial' &&
          this.$route.name !== 'analytics_pdf_report' &&
          this.$route.params?.workspace
      )
    },

    /**
     * checking top_notification is required or not
     **/
    addTopNotificationClass() {
      return (
          this.isStarterPlan &&
          this.$store.getters.getInAppNotifications.upgrade_starter_limits &&
          this.getUpgradeOfferForStarterPlan &&
          this.$route &&
          this.$route.name !== 'upgrade_your_limits' &&
          this.canViewUpgradeOffer()
      )
    },

    /**
     * Show the notification if billing is failed.
     **/
    isBillingActionRequired() {
      const profile = this.getWorkspaceSuperAdminDetails()
      return !!(profile && profile.state === 'past_due')
    },

    showTopHeaderBar() {
      return (
          this.showTopNavigationBar() &&
          this.$route.name !== 'onboardingWorkspace' &&
          Object.hasOwn(this.$route.meta, 'guest') === false &&
          (!this.getProfile.state ||
              (this.getProfile.state !== 'canceled' &&
                  this.getProfile.state !== 'cancelled'))
      )
    },

    addPostingComponents() {
      return (
          this.$route.name === 'list_plans' ||
          this.$route.name === 'calender_plans' ||
          this.$route.name === 'feed_view'
      )
    },

    isVideoAutomationSavingRoute() {
      return (
          this.$route.name === 'saveVideoBlogAutomation' ||
          this.$route.name === 'saveVideoSocialAutomation'
      )
    },

    isCSVAutomationSavingRoute() {
      return this.$route.name === 'saveBulkCsvAutomation'
    },

    /**
     * Show the notification if billing is failed.
     **/
    isPurchasedLifetimeAddons() {
      return (
          this.getActiveWorkspaceAddonsLifetime.analytics &&
          this.getActiveWorkspaceAddonsLifetime.influencers
      )
    },
    shouldShowBanner () {
      // check if user is on lifetime plan and inbox revamp is not allowed (only for inbox revamp banner)
      const inboxRevampCheck = this.getPlan?.subscription?.is_lifetime && !this.inboxAccess?.allowed
      return(
        this.getProfile?.dashboard_banner_view_status &&
        this.dashboardBannerState &&
        this.isValidDeadline() &&
        !inboxRevampCheck &&
        (this.hasPermission('can_see_subscription') || this.inboxAccess?.allowed)
      )
    },
  },

  watch: {
    'getActiveWorkspace._id'(id, oldId) {
      if (id) {
        this.defaultLandingPage(this.getActiveWorkspace)
        if (this.instagramCompetitorsChannel) {
          this.instagramCompetitorsChannel.unbind(
              'ig-competitor-analytics-' + oldId
          )
          this.instagramCompetitorsChannel.unsubscribe(
              'ig-competitor-analytics-' + oldId
          )
        }

        if(this.facebookCompetitorsChannel){
          this.facebookCompetitorsChannel.unbind(
              'fb-competitor-analytics-' + oldId
          )
          this.facebookCompetitorsChannel.unsubscribe(
              'fb-competitor-analytics-' + oldId
          )
        }
        if (this.twitterJobsChannel) {
          this.twitterJobsChannel.unbind(
              'analytics-channel-' + oldId
          )
          this.twitterJobsChannel.unsubscribe(
              'analytics-channel-' + oldId
          )
        }
        // reset the twitter jobs tracker
        this.resetTwitterJobsTracker()


        // Subscribe to the new channel
        this.handleInstagramCompetitorPusher()
        this.handleFacebookCompetitorPusher()
        this.handleTwitterJobsPusher()

        if (
            this.$route?.params?.workspace &&
            (this.getActiveWorkspace.super_admin_state === 'trial_finished' ||
                this.getActiveWorkspace.super_admin_state === 'deleted' || this.getActiveWorkspace.has_payment_issue)
        ) {
          console.debug('Workspace locked', id)
          this.$router.replace({ name: 'workspaces' })
        }
        // this.$store.dispatch('fetchLabels')
        // this.$store.dispatch('fetchWorkspaceHashtags')
        // this.$store.dispatch('fetchContentCategories')
        // show the update lifetime plan if the plan is lifetime
        if (
            this.isOnLifetimePlan() &&
            !this.getActiveWorkspaceTriggers.influencers_and_analytics &&
            this.getUserRole !== 'Approver'
        ) {
          setTimeout(() => {
            this.$bvModal.show('update-feature-plan')
          }, 3000)
        }
      }
    },
  },
  created() {
    console.debug('Created::Home.vue', this.getWorkspaces)
    localStorage.removeItem('composerWidgetPosition')
    this.loadApplication()
    this.loadJqueryOnCreated()
  },
  mounted() {
    this.loadJqueryOnMounted()
    // this.checkWorkspaceExistance()
    EventBus.$on('workspace-changed', async () => {
      console.log('workspace-changed')
      // await this.fetchWorkspaces(true, true)
      if (
        this.getActiveWorkspace.super_admin_state === 'deleted' ||
        this.getActiveWorkspace.has_payment_issue
      ) {
        const isUserStateActive = this.isUserStateActive(this.getProfile)
        if(isUserStateActive){
          this.$router.push({
            name: 'workspaces',
          })

        }
      }

      // We will not show this account connect modal on workspace change or new workspace creation

      // if (
      //     steps !== undefined &&
      //     steps.accounts_connection_modal_closed !== undefined &&
      //     steps.accounts_connection_modal_closed.status === false
      // ) {
      //   this.$bvModal.show('get-started-modal')
      // }
    })

    EventBus.$on(
    'hide-dashboard-banner',
    () => (this.dashboardBannerState = false)
    )
  },

  beforeUnmount() {
    if (this.instagramCompetitorsChannel) {
      this.instagramCompetitorsChannel.unbind(
          'ig-competitor-analytics-' + this.getActiveWorkspace?._id
      )
      this.instagramCompetitorsChannel.unsubscribe(
          'ig-competitor-analytics-' + this.getActiveWorkspace?._id
      )
    }
    if(this.facebookCompetitorsChannel){
      this.facebookCompetitorsChannel.unbind(
          'fb-competitor-analytics-' + this.getActiveWorkspace?._id
      )
      this.facebookCompetitorsChannel.unsubscribe(
          'fb-competitor-analytics-' + this.getActiveWorkspace?._id
      )
    }
    if (this.twitterJobsChannel) {
      this.twitterJobsChannel.unbind(
          'syncing-' + this.getActiveWorkspace?._id
      )
      this.twitterJobsChannel.unsubscribe(
          'analytics-channel-' + this.getActiveWorkspace?._id
      )
    }
    this.isDirty = false
    this.buttonText = `Skip & Explore`
    EventBus.$off('workspace-changed')
    EventBus.$off('hide-dashboard-banner')
  },

  /* eslint-disable no-undef, camelcase */
  methods: {
    ...mapActions(['removeFromTwitterJobsTracker','resetTwitterJobsTracker']),
    /**
     * this method is responsible for handling the workspace onboarding
     * @returns {number}
     */
    onBoardingWorkspaceProgressValue() {
      const totalSteps = 5
      let doneSteps = 0

      if (this.getActiveWorkspace.onboarding_steps) {
        if (
            this.getActiveWorkspace.onboarding_steps?.connect_social_account?.status
        ) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps?.create_first_post?.status) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps?.content_category?.status) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps?.discover_content?.status) {
          doneSteps += 1
        }

        if (this.getActiveWorkspace.onboarding_steps?.invite_team?.status) {
            doneSteps += 1
        }

        return doneSteps === totalSteps
      }

      return -1
    },
    /**
     * this method is responsible for handling the default landing page preference
     */
     defaultLandingPage(activeWorkspace = {}) {
      const pathname = window.location.pathname
      if(pathname === '/') {
        if(activeWorkspace && activeWorkspace.slug) {
          this.$router.push({
            name: this.getProfile?.preferences?.default_landing_page || 'dashboard',
            params: { workspace: activeWorkspace.slug },
          })
        }
      }
    },

    /**
     * this method is responsible for handling the composer modal
     * @param type
     */
    handleOpenComposer(type) {
      if(type === 'open') {
        const workspace = this.$route.params.workspace
        history.pushState({}, null, `/${workspace}/composer/${this.$refs.composerModal.planId}`)
        this.$bvModal.show('composer-modal')
        this.isDraftComposer = false
        this.$refs.composerModal.startAutoSave()
        return
      }
      this.$refs.composerModal.onCloseConfirmation()
    },
    isActiveUserSuperAdmin() {
      const teamMembers =
          this.$store.getters.getWorkspaces.activeWorkspace.members
      if (teamMembers) {
        for (let i = 0; i < teamMembers.length; i++) {
          const member = teamMembers[i]
          if (
              member.user &&
              this.$store.getters.getProfile.email === member.user.email
          ) {
            return member.role === 'super_admin'
          }
        }
      }

      return false
    },
    /**
     * @returns {boolean}
     */
    async loadApplication() {
      console.debug('Method::loadApplication')

      // logging out if the route in logout
      if (this.$route.name === 'logout') {
        await this.isLogout()
        return
      }

      // checking logged in status
      if (this.$store.getters.isUserAuthenticated) {
        // fetching the common data which is required to load application(e.g profile,workspace, accounts etc)
        await this.loadRequiredData(false)
        this.is_loading = false
        this.handleInstagramCompetitorPusher()
        this.handleFacebookCompetitorPusher()
        this.handleTwitterJobsPusher()
        return true
      }

      // clearing token states and redirecting to logout if token is invalid
      await this.isLogout()
      this.is_loading = false
    },

    handleInstagramCompetitorPusher() {
      if (this.getActiveWorkspace?._id && !this.instagramCompetitorsChannel) {
        const instagramAnalyticsPusher = pusherAuthSocketAnalytics()
        this.instagramCompetitorsChannel = instagramAnalyticsPusher.subscribe(
            'ig-competitor-analytics-' + this.getActiveWorkspace?._id
        )
        this.bindCompetitorAnalyticsSocketListeners('ig')
      }
    },

    handleFacebookCompetitorPusher() {
      if (this.getActiveWorkspace?._id && !this.facebookCompetitorsChannel) {
        const facebookAnalyticsPusher = pusherAuthSocketAnalytics()
        this.facebookCompetitorsChannel = facebookAnalyticsPusher.subscribe(
            'fb-competitor-analytics-' + this.getActiveWorkspace?._id
        )
        this.bindCompetitorAnalyticsSocketListeners('fb')
      }
    },
    handleTwitterJobsPusher() {
      if (this.getActiveWorkspace?._id && !this.twitterJobsChannel) {
        const twitterJobsPusher = pusherAuthSocketAnalytics()
        this.twitterJobsChannel = twitterJobsPusher.subscribe(
            'analytics-channel-' + this.getActiveWorkspace?._id
        )
        this.bindTwitterJobsSocketListeners()
      }
    },
    /**
     * @description binds to the pusher channel of competitor analytics for events.
     */
    bindCompetitorAnalyticsSocketListeners(platform = '') {
      const event = `${platform}-competitor-analytics-` + this.getActiveWorkspace?._id
      if (platform === 'fb') {
        this.facebookCompetitorsChannel.bind(event, (data) => {
          this.handleCompetitorAnalyticsEvent(platform, data)
        });
      } else if (platform === 'ig') {
        this.instagramCompetitorsChannel.bind(event, (data) => {
          this.handleCompetitorAnalyticsEvent(platform,data)
        });
      }
    },

    /**
     * @description binds to the pusher channel of twitter jobs for events.
     */
    bindTwitterJobsSocketListeners() {
      const event = `syncing-` + this.getActiveWorkspace?._id
      this.twitterJobsChannel.bind(event, (data) => {
        this.handleTwitterJobsEvent(data)
      })
    },

    /**
     * @description handles the twitter jobs event.
     * @param data
     */
    handleTwitterJobsEvent(data = {}) {
      if (data.state === 'Processed') {
        this.alertMessage(
            '<p>' +
            '<strong>X (Twitter) Analytics Data Synced Successfully!</strong><br/>' +
            'Your Analytics for X (Twitter) Account ' +
            '<strong>' + data.name + '</strong> ' +
            'has been successfully synced.'+
            '<br/>' +
            '<a onclick="handleTwitterJobNotification(event, \'' + data.account + '\')" ' +
            `href="#">Go to ${data.name} Analytics</a>` +
            '</p>',
            'success',
            10000
        );
      } else {
        this.alertMessage(
            '<p>' +
            '<strong>X (Twitter) Data Sync Failed!</strong><br/>' +
            'X (Twitter) Job ' +
            '<strong>' + data.name + '</strong> ' +
            'has been failed. ' +
            '<br/>' +
            '<a onclick="handleTwitterJobNotification(event, \'' + data.account + '\')" ' +
            `href="#">Go to ${data.name} Analytics</a>` +
            ' to try again.' +
            '</p>',
            'error',
            10000
        );
      }
      // if the user is on the twitter analytics page, then refresh the data
      if (this.$route?.name === 'twitter_analytics_v3' && this.$route.params?.accountId === data.account) {
        EventBus.$emit('re-fetch-twitter-data')
        EventBus.$emit('sync-twitter-data',Date.now())
      }

      this.removeFromTwitterJobsTracker(data?.account)

    },

    handleCompetitorAnalyticsEvent(platform = '', data = {}){
      if(!platform) return
      if (data.error === 'NoError') {
        this.alertMessage(
            '<p>' +
            '<strong>Data Fetching Successful!</strong><br/>' +
            'Data fetching of ' + (platform === 'fb' ? 'Facebook' : 'Instagram') + ' Competitor ' +
            '<strong>' + (data.display_name ? data.display_name : data.slug) + '</strong> ' +
            'has been successful.' +
            '<br/>' +
            '<a onclick="handleCompetitorNotification(event, \'' + data.report_id + '\', \'' + platform + '\' )" ' +
            'href="#">Go to ' + data.report_name + ' Dashboard</a>' +
            '</p>',
            'success',
            10000
        );
      }
      else {
        this.alertMessage(
            '<p>' +
            '<strong>Data Fetching Failed!</strong><br/>' +
            'Data fetching of ' + (platform === 'fb' ? 'Facebook' : 'Instagram') + ' Competitor ' +
            '<strong>' + (data.display_name ? data.display_name : data.slug) + '</strong> ' +
            'has been failed. ' +
            '<br/><span style="color:#c84e6f">' + data.error + '</span>' +
            '<br/>' +
            '<a onclick="handleCompetitorNotification(event, \'' + data.report_id + '\', \'' + platform + '\')" ' +
            'href="#">Go to ' + data.report_name + ' Dashboard</a>' +
            ' to try again.' +
            '</p>',
            'error',
            10000
        );
      }

      if (
          this.$route?.name === 'instagram_competitor_v3' || this.$route?.name === 'facebook_competitor_v3' &&
          this.$route.params?.reportId === data.report_id
      ) {
        EventBus.$emit('re-fetch-report-data')
      }
    },

    /**
     * clearing token states and redirecting to login page
     * @returns {Promise<any>}
     */
    isLogout() {
      return new Promise((resolve, reject) => {
        this.$store.commit(authenticationTypes.SET_EXPIRE_TOKEN)
        this.$store.commit(authenticationTypes.SET_LOGGED_USER_EXPIRE)
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, false)

        // checking redirected_url
        const ignore_redirected = ['/', '/login', '/logout']
        const path = this.$route.fullPath
        ignore_redirected.includes(path) === false
            ? this.$router.push({
              name: 'login',
              query: { redirected_url: this.$route.fullPath },
            })
            : this.$router.push({ name: 'login' })
        this.is_loading = true
        resolve()
      })
    },

    loadJqueryOnCreated() {
      console.debug('Method::loadJqueryOnCreated')
      const stateObject = this
      // ................JQUERY CODE FOR ONLOAD..................
      $(document).ready(() => {
        setTimeout(() => {
          console.debug('change log')
          const HW_config = {
            selector: '.change_logs', // CSS selector where to inject the badge
            account: 'LJ1b0y', // your account ID
          }
          if (window.Headway) Headway.init(HW_config)
        }, 10000)

        // body overflow issue for multiple model...
        $('.modal').on('hidden.bs.modal', (e) => {
          if ($('.modal:visible').length) {
            $('body').addClass('modal-open')
          }
        })

        // TODO::Check Not in Use
        $(document).on('click', '.calendar_actions ', (e) => {
          // let item = false
          const plans = stateObject.getPlans.items
          const id = $(e.currentTarget)
              .parents('.planer_event_box_week')
              .attr('data-id')

          let item = plans.find(plan => plan._id === id);
          if (!item) {
            item = false;
          }


          // plans.forEach((plan) => {
          //   if (plan._id === id) {
          //     item = plan
          //     return false
          //   }
          // })

          console.debug('Method::calendar_actions click', id, item)
          if (item) {
            if ($(e.currentTarget).hasClass('check')) {
              $(e.currentTarget).siblings('.custom_loader').removeClass('hide')
              stateObject.$store.dispatch('changePlanStatus', {
                id: item._id,
                status: 'scheduled',
              })
              return false
            }

            if ($(e.currentTarget).hasClass('cross')) {
              $(e.currentTarget).siblings('.custom_loader').removeClass('hide')
              stateObject.$store.dispatch('changePlanStatus', {
                id: item._id,
                status: 'rejected',
              })
              return false
            }

            if ($(e.currentTarget).hasClass('refresh')) {
              stateObject.$store.commit(planner.SET_CONFIRMATION_PLAN, item)
              stateObject.$bvModal.show('replacePlan')
              return false
            }

            if ($(e.currentTarget).hasClass('edit')) {
              item.stateObject = stateObject
              stateObject.$store.dispatch('editPlan', item)
              return false
            }

            if ($(e.currentTarget).hasClass('clone')) {
              item.stateObject = stateObject
              stateObject.$store.dispatch('clonePlan', item)
              return false
            }

            if ($(e.currentTarget).hasClass('trash')) {
              stateObject.$store.commit(planner.SET_CONFIRMATION_PLAN, item._id)
              stateObject.$bvModal.show('removePlan')
              return false
            }
          }
        })

        // make box editable

        $(document).on('click', '.edit_box', (e) => {
          const loaderID = stateObject.generateRandomId() + '_loader'
          const currentObject = e.currentTarget
          const editor_dragable_box = stateObject.getClosestClass(
              currentObject,
              'editor_dragable_box'
          )

          if (editor_dragable_box.classList.contains('editor_flickr_block')) {
            const url = editor_dragable_box
                .querySelector('.flickr_inner_embed')
                .getAttribute('data-link')

            editor_dragable_box.querySelector(
                '.flickr_inner_embed'
            ).innerHTML = `
                               <div class="inner_embed"><br>
                                   <div id="${loaderID}" class="custom_loader">
                                      <div class="loader_inner"></div>
                                   </div>
                                   <a data-flickr-embed="true">
                                     <img src="${url}">
                                   </a>
                                   <br>

                                </div>
                             `

            // for removing loader
            stateObject.processLoaderPreview(loaderID)
          } else if (
              editor_dragable_box.classList.contains('editor_twitter_block')
          ) {
            const tweetDescription = $(editor_dragable_box)
                .find('.twitter_inner_embed .tweet_description')
                .html()
            const tweetScreenname = $(editor_dragable_box)
                .find('.twitter_inner_embed .tweet_screenname')
                .html()
            const tweetTitle = $(editor_dragable_box)
                .find('.twitter_inner_embed .tweet_title')
                .html()
            const tweetLink = $(editor_dragable_box)
                .find('.twitter_inner_embed .tweet_link')
                .html()
            $(editor_dragable_box)
                .find('.twitter_inner_embed')
                .attr('data-description', tweetDescription)
            $(editor_dragable_box)
                .find('.twitter_inner_embed')
                .attr('data-screenname', tweetScreenname)
            $(editor_dragable_box)
                .find('.twitter_inner_embed')
                .attr('data-title', tweetTitle)
            $(editor_dragable_box)
                .find('.twitter_inner_embed')
                .attr('data-link', tweetLink)

            editor_dragable_box.querySelector(
                '.twitter_inner_embed'
            ).innerHTML = `
                                <div id="${loaderID}"  class="custom_loader">
                                      <div class="loader_inner"></div>
                                   </div>
                                   <div class="inner_embed" id=""><blockquote class=" twitter-tweet"  data-width="350">
                        <p lang="en" dir="ltr">${tweetDescription}</p>
                        <p>— ${tweetTitle} (${tweetScreenname})
                        <a href="${tweetLink}"><span class="hide">November 24, 2016</span></a></p>
                      </blockquote>

                                </div>

                             `

            // for removing loader
            stateObject.processLoaderPreview(loaderID)
          }

          stateObject
              .getClosestClass(currentObject, 'top_option')
              .classList.add('top_option_remove')
          $(editor_dragable_box)
              .find('.editor_dragable_box_inner')
              .children()
              .unwrap()
          $(editor_dragable_box).children().unwrap()
          $('.top_option_remove').remove()
          stateObject.loadFlickrPreview(500)
          if (editor_dragable_box.classList.contains('editor_twitter_block')) {
            stateObject.loadTwitterPreview(0)
          }
        })

        // remove box
        // $(document).on('click', '.remove_box', () => {
        //   $(this).parents('.editor_dragable_box').remove()
        // })
        $(document).on('click', '.remove_box', function () {
          $(this).parents('.editor_dragable_box').remove()
        })

        window.notificationActionPerform = async () => {
          if(await stateObject.onClickNotification(stateObject.getCurrentNotification))
            EventBus.$emit('refreshPlannerTableV2')
        }

        // instagram competitor notifications <a> onCLick handler
        window.handleCompetitorNotification = (event, reportId, platform = '') => {
          event.preventDefault()
          stateObject.handleNotification(reportId, platform)
          return null
        }
        // twitter job notifications <a> onClick handler
        window.handleTwitterJobNotification = (event,account) => {
          event.preventDefault()
          stateObject.handleTwitterJobNotification(account)
          return null
        }

        // align box left
        $(document).on('click', '.move_left', (e) => {
          //                        var self_inner = $(this).closest(".top_option");
          const self_inner = stateObject.getClosestClass(
              e.currentTarget,
              'top_option'
          )
          $(self_inner)
              .siblings('.align_box,.text_alignment')
              .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'left',
          })
        })

        // align box center
        $(document).on('click', '.move_center', (e) => {
          const self_inner = stateObject.getClosestClass(
              e.currentTarget,
              'top_option'
          )
          $(self_inner)
              .siblings('.align_box,.text_alignment')
              .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'center',
          })
        })

        // align box right
        $(document).on('click', '.move_right', (e) => {
          const self_inner = stateObject.getClosestClass(
              e.currentTarget,
              'top_option'
          )
          $(self_inner)
              .siblings('.align_box,.text_alignment')
              .removeAttr('style')
          $(self_inner).siblings('.align_box,.text_alignment').css({
            textAlign: 'right',
          })
        })

        // Bootstrap slider
        $(document).on(
            'click',
            '.prevent_close_dropdown .dropdown-menu',
            (e) => {
              e.stopPropagation()
            }
        )

        $(document).on('click', '.close_this', (e) => {
          e.stopPropagation()
        })
      })
    },

    loadJqueryOnMounted() {
      console.debug('Method::loadJqueryOnMounted')
      // set the gtm
      $('.add_class_on_hide').on('hide.bs.modal', () => {
        setTimeout(() => {
          $('body').addClass('modal-open')
        }, 500)
      })

      $(document).on('show.bs.modal', '.modal', (event) => {
        const zIndex = 1040 + 10 * $('.modal:visible').length
        $(this).css('z-index', zIndex)
        setTimeout(() => {
          $('.modal-backdrop')
              .not('.modal-stack')
              .css('z-index', zIndex - 1)
              .addClass('modal-stack')
        }, 0)

        setTimeout(() => {
          $('body').addClass('modal-open')
        }, 500)
      })
    },

    // Find closest up ward parent node
    getClosestClass(eventTarget, className) {
      // Traverse the DOM up with a while loop
      console.debug(eventTarget, '-----Method:getClosestClass-----', className)
      // while (eventTarget.className != className) {
      if (eventTarget && eventTarget.classList) {
        while (!eventTarget.classList.contains(className)) {
          // Increment the loop to the parent node
          eventTarget = eventTarget.parentNode
          if (!eventTarget) {
            return null
          }
        }
      }

      // At this point, the while loop has stopped and `el` represents the element that has
      // the class you specified in the second parameter of the function `clazz`

      // Then return the matched element
      return eventTarget
    },
    getInstagramAccountsWithPermissionsIssue() {
      const selectedAccounts = [] // will contain all the details for the IG accounts selected
      for (const acc in this.getInstagramAccounts.all_items) {
        if (Object.hasOwn(this.getInstagramAccounts.all_items, 'acc')) {
          selectedAccounts.push(this.getInstagramAccounts.all_items[acc])
        }
      }

      const allAccessAccounts = [] // will contain profiles with IG publishing permissions
      const noAccessAccounts = [] // will contain profiles which do not have IG publishing permissions

      // if selected accounts are found ...
      if (selectedAccounts.length > 0) {
        for (let x = 0; x < selectedAccounts.length; x++) {
          // loop over each account and segregate into seperate arrays accordingly
          const item = selectedAccounts[x]
          if (!this.hasInstaDirectAPIPermission(item)) {
            noAccessAccounts.push(item)
          } else {
            allAccessAccounts.push(item)
          }
        }
      }

      // update the instagramSelection variable
      return {
        no_access_accounts: noAccessAccounts,
        all_access_accounts: allAccessAccounts,
      }
    },
    hasInstaDirectAPIPermission(channel) {
      if (channel.permissions) {
        const publishAPIPermission = _.findWhere(channel.permissions, {
          permission: 'instagram_content_publish',
        })
        return publishAPIPermission
            ? publishAPIPermission.status === 'granted'
            : false
      }
      return false
    },
    cardHasExpired() {
      if (this.getBillingDetails.payment_information) {
        const expiryDate =
            this.getBillingDetails.payment_information.expiry_date
        if (expiryDate) {
          const now = moment()
          const f = moment(expiryDate, 'MM/YYYY')
          const diff = f.diff(now, 'days')

          if (diff > 0 && diff <= 30) {
            // has not expired yet .. still 30 days to go
            return false
          } else if (diff <= 0) {
            // card has expired or will expire today
            return true
          } else {
            return null
          }
        }
      }

      return null
    },

    /**
     * @description used in window.handleCompetitorNotification. used for redirecting to specific report.
     * @param reportId
     * @param platform
     */
    handleNotification(reportId, platform = '') {
      this.$router.push({
        name: platform === 'fb' ? 'facebook_competitor_v3' : 'instagram_competitor_v3',
        params: { reportId },
      })
    },
    /**
     * @description used in window.handleTwitterJobNotification. used for redirecting the user to specific report.
     * @param accountId
     */
    handleTwitterJobNotification(accountId) {
      this.$router.push({
        name: 'twitter_analytics_v3',
        params: { accountId },
      })
    },
    isValidDeadline(){
      return true
      if (!this.isTrialPlan()) return true

      const now = this.momentWrapper()?.formatTimezone()
      const startTime = this.momentWrapper('2025-01-06 00:00:00')?.formatTimezone()
      if (now.isBefore(startTime)) return false;

      const deadline = this.momentWrapper('2025-01-27 00:00:00')?.formatTimezone()
      return now.isBefore(deadline)

      // const now = moment.utc()
      // const start = moment.utc('2024-08-13T19:00:00');
      // const end = moment.utc('2024-08-15T04:00:00');
      //
      // return now?.isBetween(start, end, null, '[]') // Using inclusive comparison
    }
  },
}
</script>

<template>
  <div class="application-box">
    <MediaRemoveConfirmBox />

    <div
        class="app-wrapper"
        :class="{
        sticky_bar:
          isTrialPlan() ||
          cardHasExpired() ||
          shouldShowBanner ,
          '!flex !flex-col !h-screen': $route.path.includes('/dashboard'),
      }"
    >
      <div
          v-if="showLoader"
          class="loading-logo flex justify-center items-center"
      >
        <clip-loader
            v-if="isWhiteLabelDomain && !isWhiteLabeledWorkspace && !appData?.brandLogo"
            :color="'#436aff'"
            :size="'2rem'"
        />
        <img
            v-else
            :src="isWhiteLabelDomain && appData?.brandLogo ? appData.brandLogo : require('@assets/img/logo/logo-animation.gif')"
            class="w-[50px]"
            :class="{ 'animate-bounce': isWhiteLabelDomain && appData?.brandLogo }"
            :alt="appData.businessName"
        />
      </div>
      <template v-if="!showLoader">
        <div
            class="fixed bottom-0 left-1/2 w-max z-[1049]"
            style="transform: translateX(-50%)"
        >
          <HeadNotificationSlider
              v-if="
              !(
                $route.name === 'analytics_pdf_report' ||
                $route.name === 'social'
              )
            "
          ></HeadNotificationSlider>

          <HeaderBillingNotifications
              :display-billing-notification="isBillingActionRequired"
          >
          </HeaderBillingNotifications>
        </div>
        <BannerNotificationModal />
        <ChangeTrialPlanDialog />
        <WorkspaceLimitsDialog />
        <upgrade-plan-dialog />
        <IncreaseLimitsDialog />
        <CardInfoDialog />
        <UpdateFeaturePlan></UpdateFeaturePlan>
        <createWorkspaceModal />
        <SubscriptionLimitsExceededModal
            v-if="
            checkIfActiveWorkspaceHasLimitExceeded() &&
            ($route.name !== 'manageLimits' &&  $route.name !== 'workspaces')
          "
            :is-super-admin="isActiveUserSuperAdmin()"
        ></SubscriptionLimitsExceededModal>

        <StickyBanner
            v-if="showTopHeaderBar && isTrialPlan() && hasPermission('can_see_subscription')"
            :profile="getProfile"
        />
        <DashboardNotificationBanner v-if="showTopHeaderBar && shouldShowBanner" />

        <NoticeBanner
            v-if="cardHasExpired()"
            :is_for="'credit_card_expire'"
            :has_expired="cardHasExpired()"
        />

        <TopHeaderBar v-if="showTopHeaderBar" @openComposer="handleOpenComposer"></TopHeaderBar>
        <div
            class="main_inner_container"
            :style="{
            'padding-top':
              $route.name === 'email_verification' ? '0px!important' : '',
          }"
            :class="{
            workspace_main_page: isWorkspaceMainPageRoute(),
            is_guest_page:
              $route.meta.hasOwnProperty('guest') && $route.meta.guest,
              'overflow-auto no-scrollbar': $route.path.includes('/dashboard'),
          }"
        >
          <!-- Draft Composer -->
          <ComposerWidget v-if="isDraftComposer" @openComposer="handleOpenComposer" />
          <UploadMediaModal :modal-id="'global-upload-media-modal'" type="social" ></UploadMediaModal>

          <!-- End Draft Composer -->
          <AIChatMain v-if="!['composerBlog'].includes($route.name)" :type="'modal'">
            <template v-slot:child="slotProps">
              <AIChatWidget :open-modal="slotProps.toggleChatDialogs" />
              <AIChatModal :parent-state="slotProps" />
            </template>
          </AIChatMain>
          <CommentImagePreview></CommentImagePreview>
          <ActivateVideoCampaign
              v-if="isVideoAutomationSavingRoute"
          ></ActivateVideoCampaign>
          <template v-if="isCSVAutomationSavingRoute">
            <RemoveCsvPlanConfirmation></RemoveCsvPlanConfirmation>
            <RemoveCsvPlansConfirmation></RemoveCsvPlansConfirmation>
            <SocialApprovalModal></SocialApprovalModal>
          </template>
          <template v-if="addPostingComponents">
            <InsertFileModal
                class="planner_insertFile_modal"
                type="BlogEditorFile"
            ></InsertFileModal>
            <PasteFromWord class="planner_pasteWord_modal"></PasteFromWord>
            <SocialShare class="planner_socialshare_modal"></SocialShare>
            <BlogShare class="planner_blogshare_modal"></BlogShare>
          </template>

          <MediaStorageLimitsExceededModal
              v-if="!($route.name === 'analytics_pdf_report')"
              :is-super-admin="isActiveUserSuperAdmin()"
          ></MediaStorageLimitsExceededModal>

          <!--We are using this to show the preview of the video and image-->
          <DisplayFile></DisplayFile>
          <!-- New Version of Doka (Pintura Editor) -->
          <EnhanceImagePintura></EnhanceImagePintura>
          <div v-if="getWorkspaces.items.length > 0">
            <transition name="slide-bottom">
              <GettingStarted v-if="showOnboardingWidget"></GettingStarted>
            </transition>
          </div>
          <HelpDropdown v-if="!(getTeamMembership === 'client' && getUserRole === 'Approver')"></HelpDropdown>
          <offline-js></offline-js>
          <YoutubeVideoPlayerModal />
          <SocialModal ref="composerModal" />
          <GetStartedNewModal v-if="hasGetStartedModal" />
          <SubscriptionPlansModal />
          <InboxAddOnModal />
          <router-view></router-view>
        </div>
      </template>
    </div>
  </div>
</template>
