<template>
  <Button
    variant="text"
    :size="size"
    :disabled="loading"
    class="flex items-center border !border-transparent hover:!border-gray-200 transition-all !py-0"
    :class="[customClass]"
    @click="$emit('click')"
  >
    <img
      :src="inboxSyncIcon"
      alt="Sync"
      class="w-[16px] h-[16px]"
      :class="[
        iconClass,
        {
          'animate-spin': loading,
        },
      ]"
    />
  </Button>
</template>

<script setup>
import { Button } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'

const { inboxSyncIcon } = useIcons()

defineEmits(['click'])

defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'xs'
  },
  customClass: {
    type: String,
    default: ''
  },
  iconClass: {
    type: String,
    default: ''
  }
})

</script>
