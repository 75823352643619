<template>
  <b-modal
    id="sendDirectMessage"
    hide-footer
    hide-header
    modal-class="sendDirectMessageModal  normal_modal"
  >
    <div class="modal_head d-flex align-items-center">
      <h2>Reply as Private</h2>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        @click="$bvModal.hide('sendDirectMessage')"
      >
        &times;
      </button>
    </div>

    <div class="modal_body basic_form m_t_15">
      <p
        >Replying via direct/private message to
        <span class="mention"
          >@<span v-if="userName" class="user_name mention">{{
            userName
          }}</span>
          <span v-else class="user_name mention">User</span></span
        ></p
      >
      <div class="input_field">
        <div class="text_area relative">
          <div class="comment_tool">
              <CstEmojiPicker
                v-if="showEmojiPicker"
                position="left"
                :is-open="isEmojiPickerOpen"
                @on-select="onEmojiSelect"
                @handle-click="isEmojiPickerOpen = false"
              >
              </CstEmojiPicker>
              <div
                v-tooltip="'Add Emoji'"
                class="relative flex justify-center items-center rounded-lg bg-[#E6F0FC] cursor-pointer w-7 h-7"
                @click.stop="handleIconClick('emoji')"
              >
                <i class="fal fa-smile-beam text-base leading-none text-[#75797C]"></i>
              </div>
          </div>
          <textarea
            id="common_sharing_message"
            v-model="message"
            name=""
            placeholder="Write a private message..."
            cols="30"
            rows="4"
          >
          </textarea>
        </div>
      </div>

      <div class="input_field">
        <button :disabled="isSending || message.length === 0" class="btn gradient_btn large_btn " @click="sendPrivateMessage">
          <div class="flex gap-2 items-center">
            <span>Send</span>
            <template v-if="isSending">
              <clip-loader
                  :color="'#ffffff'"
                  :size="'14px'"
              ></clip-loader>
            </template>
          </div>
        </button>
        <button
          class="btn light_gray ml-2 large_btn"
          @click="$bvModal.hide('sendDirectMessage')"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { computed, ref } from 'vue'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'

const props = defineProps({
  replyTo: {
    type: Object,
    required: true
  },
  isSending: {
    type: Boolean,
    required: true
  }
})
const emit = defineEmits(['reply'])

const message = ref('')
const isEmojiPickerOpen = ref(false)
const showEmojiPicker = ref(true)

const handleIconClick = (value) => {
  if (value === 'emoji') {
    isEmojiPickerOpen.value = !isEmojiPickerOpen.value
  }
}

const onEmojiSelect = (emojiUnicode) => {
  message.value += emojiUnicode
}

const userName = computed(() => {
  return `${props.replyTo?.from?.[0]?.first_name} ${props.replyTo?.from?.[0]?.last_name}` || 'User'
})

const sendPrivateMessage = () => {
  emit('reply', {
    comment: props.replyTo,
    message: {
      text: message.value
    }
  })
}
</script>

<style>
.sendDirectMessageModal .emoji-mart-wrap {
  z-index: 1060;
}
</style>
