<template>
  <div class="flex flex-col flex-1 bg-[#f4f7fa] py-8 px-6 overflow-hidden relative">
    <template v-if="newMessageAlert">
      <Button
        class="absolute top-10 left-1/2 transform -translate-x-1/2 z-20"
        radius="full"
        size="sm"
        color="primary"
        variant="filled"
        @click="handleNewMessageClick"
      >
        <i class="fas fa-arrow-down text-sm"></i>
        <span>New message(s)</span>
      </Button>
    </template>
    <div class="flex items-center justify-center relative">
      <PlatformPill custom-class="absolute z-10" />
    </div>
    <div
      ref="chatContainer"
      v-infinite-scroll="[fetchMoreConversation, { distance: 30, canLoadMore, direction: 'top' }]"
      class="flex-1 flex flex-col-reverse overflow-y-auto pb-3"
      >
      <template v-for="(date, index) in conversationDates" :key="index">
        <template v-for="(message) in conversation[date]" :key="message._id">
          <template v-if="isAnAction(message) && !isNoteAction(message)">
            <ChatActionUpdate :message="message" />
          </template>
          <template v-else-if="isNoteAction(message)">
            <ChatNoteBubble :message="message" />
          </template>
          <template v-else>
            <ChatBubble
              :message="message"
              :is-message-mine="isMessageMine(message)"
              @toggle-bookmark="toggleBookmark(message)"
            />
          </template>
        </template>
        <!-- date splitter -->
        <DateDivider :date="date" />
      </template>
      <div v-if="isFetchingConversation" class="flex justify-center items-center">
        <clip-loader class="p-3" :color="'#436aff'" :size="'2rem'" />
      </div>
      <div v-if="!canLoadMore" class="flex justify-center items-center my-3">
        <p class="mt-3 flex items-center justify-center leading-none h-8 text-sm px-3 rounded-full cstu-bg-blue-50 cstu-border-blue-400 border-[1px] !border-opacity-20 mx-4 text-center whitespace-nowrap">
          Start of conversation
        </p>
      </div>
    </div>
    <MessageComposer ref="messageBox" :active-inbox-detail="chat" :file-accept="fileAccept" @send="handleMessageSend"/>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, onUnmounted, nextTick } from 'vue'
import { vInfiniteScroll } from '@vueuse/components'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import { useStore } from '@state/base'
import useDateFormat from '@common/composables/useDateFormat'
import { set, debounce } from 'lodash'
import ChatActionUpdate from '@src/modules/inbox-revamp/components/ChatActionUpdate.vue'
import ChatNoteBubble from '@src/modules/inbox-revamp/components/ChatNoteBubble.vue'
import DateDivider from '@src/modules/inbox-revamp/components/DateDivider.vue'
import ChatBubble from '@src/modules/inbox-revamp/components/ChatBubble.vue'
import PlatformPill from '@src/modules/inbox-revamp/components/PlatformPill.vue'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import { EventBus } from '@common/lib/event-bus'
import { Button } from '@contentstudio/ui'

const { momentWrapper } = useDateFormat()
const { dispatch, getters } = useStore()

const {
  currentConversationNotes,
  currentConversationBookmarks,
  markAsRead,
  isMessageNewer,
} = useInbox()

const props = defineProps({
  chat: {
    type: Object,
    required: true
  },
  platformDetails: {
    type: Object,
    required: true
  }
})

const chatContainer = ref(null)
const isFetchingConversation = ref(false)
const conversation = ref({})
const page = ref(1)
const limit = ref(20)
const canLoadMore = ref(true)
/* socketMessageDict is to keep track of the messages received
  over socket and via API to avoid duplicates. */
const socketMessageDict = ref({})
const newMessageAlert = ref(false)
const messageBox = ref(null)

const recipientId = computed(() => {
  return props.chat?.inbox_details?.posted_by?.user_id || ''
})

const postDetails = computed(() => {
  return props.chat?.element_details || {}
})

const conversationDates = computed(() => {
  return Object.keys(conversation.value).sort((a, b) => momentWrapper(b).diff(momentWrapper(a)))
})

const fileAccept = computed(() => {
  return props.chat?.platform === 'facebook' ? 'image/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.odt,.ods,.odp,.csv' : 'image/*,video/*'
})

watch(() => props.chat?.element_details?.element_id, () => {
  messageBox.value?.focusTextarea()
  conversation.value = {}
  page.value = 1
  canLoadMore.value = true
  fetchConversation()
}, { immediate: true })

onMounted(() => {

  EventBus.$on('remove-conversation-bookmark', async(message) => {
    if(message?.conversation_id === postDetails.value?.element_id) {
      const currentDate = momentWrapper(message?.created_at).format('YYYY-MM-DD')
      await toggleBookmark(message)
      message.is_starred = false
      replaceMessage(message, message, currentDate)
    }
  })

  // Listen for new messages pushed through the EventBus
  EventBus.$on('push_new_message', (data) => {
    console.log('PUSHER:: push_new_message', data)

    // Format the current date
    const currentDate = momentWrapper(data?.created_time).format('YYYY-MM-DD')

    // Ensure the conversation object has an entry for the current date
    if (!(currentDate in conversation.value)) {
      conversation.value = { [currentDate]: [], ...conversation.value }
    }

    try {
      // Check if the message is not a duplicate
      if (!socketMessageDict.value[data.message_id] &&
          !conversation.value[currentDate].some(msg => msg.message_id === data.message_id)
      ) {
        // Add the new message to the conversation
        if (conversation.value[currentDate].length === 0 || isMessageNewer(conversation.value[currentDate][0]?.created_time, data.created_time)) {
          conversation.value[currentDate].unshift(data) // Add to the beginning if it's the newest
        } else {
          // Find the correct index to insert the new message based on its timestamp
          const insertIndex = conversation.value[currentDate].findIndex(msg =>
            isMessageNewer(msg.created_time, data.created_time)
          );
          // Comment: Insert the new message at the correct chronological position
          conversation.value[currentDate].splice(insertIndex === -1 ? conversation.value[currentDate].length : insertIndex, 0, data);
        }

        // Mark the message as processed
        socketMessageDict.value[data.message_id] = true

        // Debounce the markAsRead function to avoid excessive calls
        const debouncedMarkAsRead = debounce(() => {
          markAsRead(props.chat)
        }, 1000)
        debouncedMarkAsRead()

        // Scroll to bottom if user is near the bottom, otherwise show new message alert
        if (chatContainer.value?.scrollTop > -300) {
          scrollToBottom()
        } else {
          newMessageAlert.value = true
        }
      }
    } catch (error) {
      console.error('Error handling new message:', error)
    }
  })

  EventBus.$on('sync-message-list', (data) => {
    markAsRead(props.chat)
    Object.keys(data || {}).forEach((date) => {
      data[date].forEach((message) => {
        socketMessageDict.value[message.message_id] = true
      })
    })
    conversation.value = data
  })

  EventBus.$on('jump-to-conversation', (data) => {
    findAndJumpToConversation(data)
  })

  nextTick(() => {
    messageBox.value?.focusTextarea()
  })
})

onUnmounted(() => {
  EventBus.$off('remove-conversation-bookmark')
  EventBus.$off('push_new_message')
  EventBus.$off('sync-message-list')
  EventBus.$off('jump-to-conversation')
})

async function fetchConversation(append = false, targetedMessage) {
  try {
    isFetchingConversation.value = true
    const payload = {
      conversation_id: postDetails.value?.element_id,
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      platform: props.chat?.platform,
      sort_order: 'desc',
      page: page.value,
      limit: limit.value,
      targeted_message: targetedMessage
    }

    const res = await dispatch('fetchMessages_v2', payload)
    console.log('messages le aaya', res)
    if (append) {
      Object.entries(res?.messages || {}).forEach(([date, messages]) => {
        if (conversation.value[date]) {
          messages.forEach(message => {
            if (!socketMessageDict.value[message.message_id]) {
              conversation.value[date].push(message);
              socketMessageDict.value[message.message_id] = true;
            }
          });
        } else {
          messages.forEach(message => {
            socketMessageDict.value[message.message_id] = true
          })
          conversation.value[date] = messages;
        }
      });
    } else {
      conversation.value = res?.messages || {};
      Object.values(res?.messages || {}).flat().forEach(message => {
        socketMessageDict.value[message.message_id] = true
      })
    }
    if (!res?.messages || Object.keys(res.messages).length === 0) {
      canLoadMore.value = false
    }
    // canLoadMore.value = res?.can_load_more
    isFetchingConversation.value = false
    // scroll to targeted message if provided
    if (targetedMessage) {
      page.value = res.page + (page.value - 1)
      nextTick(() => {
        scrollMessageToView(targetedMessage)
      })
    }
  } catch (err) {
    console.log('err', err)
  }
}
const fetchMoreConversation = (targetedMessage) => {
  targetedMessage = typeof targetedMessage === 'string' ? targetedMessage : undefined
  if (canLoadMore.value && !isFetchingConversation.value) {
    page.value++
    fetchConversation(true, targetedMessage)
  }
}

const scrollMessageToView = (messageId) => {
  const messageElement = document.getElementById(`message-${messageId}`)
  if (messageElement) {
    messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    // Highlight the message by adding a background color class
    messageElement.classList.add('cstu-bg-blue-100/50')
    // Remove the highlight after 3 seconds
    setTimeout(() => {
      messageElement.classList.remove('cstu-bg-blue-100/50')
    }, 3000)
    return true
  }
  return false
}
const findAndJumpToConversation = (data) => {
  const messageId = data?.message_id
  if (!scrollMessageToView(messageId)) {
    // scroll to top to load conversation]
    const scrollHeight = chatContainer.value?.scrollHeight + 100
    // negative scrollHeight to scroll to top because div is col-reverse
    fetchMoreConversation(messageId)
    nextTick(() => {
      chatContainer.value?.scrollTo({ top: -scrollHeight })
    })
  }
}

const isMessageMine = (message) => {
  return message.from?.[0]?.id !== recipientId.value
}

const getAttachmentType = (file) => {
  const fileMimeType = file?.type || ''
  if (fileMimeType.startsWith('image/')) {
    return 'image'
  }
  if (fileMimeType.startsWith('video/')) {
    return 'video'
  }
  return 'file' // Default to 'file'
}

const generateUnsentMessage = (message) => {
  const [firstName, ...lastNameParts] = props.platformDetails?.platform_name.split(' ')
  const lastName = lastNameParts.join(' ')
  return {
    _id: Date.now(),
    message,
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    from: [
      {
        id: props.platformDetails?.platform_id,
        first_name: firstName,
        last_name: lastName,
        profile_pic: props.platformDetails?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
        platform_type: props.platformDetails?.platform_type,
        created_at: momentWrapper().utc()
      }
    ]
  }
}

const generateUnsentNote = (message) => {
  const firstName = getters.getProfile?.firstname
  const lastName = getters.getProfile?.lastname
  return {
    _id: Date.now(),
    message,
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    action: {
      type: 'NOTE',
      action_performed_by: {
        id: getters.getProfile?._id,
        user_name: `${firstName} ${lastName}`,
        user_image: getters.getProfile?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
      }
    }
  }
}

const generateUnsentMediaMessage = (file) => {
  const [firstName, ...lastNameParts] = props.platformDetails?.platform_name.split(' ')
  const lastName = lastNameParts.join(' ')
  const type = getAttachmentType(file)
  const mediaKey = `${type}_url`

  if (!file) return
  return {
    _id: Date.now(),
    isMessageSending: true,
    created_time: momentWrapper().utc(),
    attachments:[
      {
        id: file?.id,
        file_name: file?.file_name,
        file_type: file?.file_type,
        [mediaKey]: URL.createObjectURL(file),
        type,
      }
    ],
    from: [
      {
        id: props.platformDetails?.platform_id,
        first_name: firstName,
        last_name: lastName,
        profile_pic: props.platformDetails?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
        platform_type: props.platformDetails?.platform_type,
        created_at: momentWrapper().utc()
      }
    ]
  }
}

const handleMessageSend = (message) => {
  if (message?.isNote) {
    addNote(message)
  } else {
    if(message?.text?.length > 0) {
      addMessage(message)
    }
    if (message?.attachment) {
      addMediaMessage(message)
    }
  }
}
/**
 * Add a new message to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.text - The text of the message.
 */
const addMessage = async (message) => {
  const messageObj = generateUnsentMessage(message.text)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);
  scrollToBottom()
  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    recipient_id: recipientId.value,
    element_id: postDetails.value?.element_id,
    message: message.text,
  }
  const res = await dispatch('sendTextMessage_v2', payload)
  if (res?.isValid) {
    const newMessage = res.message?.data?.payload
    if(!socketMessageDict.value[newMessage.message_id]){
      replaceMessage(messageObj, newMessage, currentDate)
      socketMessageDict.value[newMessage.message_id] = true
    }
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
};

/**
 * Add a note to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.text - The text of the message.
 */
const addNote = async (message) => {
  const messageObj = generateUnsentNote(message.text)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);
  scrollToBottom()

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    conversation_id: postDetails.value?.element_id,
    message: message.text,
    mentioned_users: message.mentionedUsers.map(user => user.id)
  }
  const res = await dispatch('addNoteToChat_v2', payload)
  if (res?.isValid) {
    const newMessage = res?.note
    if(!socketMessageDict.value[newMessage.message_id]){
      replaceMessage(messageObj, newMessage, currentDate)
      socketMessageDict.value[newMessage.message_id] = true
      currentConversationNotes.value.unshift(newMessage)
    }
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
}

/**
 * Add a media message to the conversation.
 * @param {Object} message - The message object.
 * @param {String} message.attachment - The attachment of the message.
 */
 const addMediaMessage= async (message) => {
  const messageObj = generateUnsentMediaMessage(message.attachment)
  const currentDate = momentWrapper().format('YYYY-MM-DD');
  if (!(currentDate in conversation.value)) {
    conversation.value = { [currentDate]: [], ...conversation.value }
  }
  conversation.value[currentDate].unshift(messageObj);
  scrollToBottom()

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.chat?.platform_id,
    platform_type: props.chat?.platform,
    element_id: postDetails.value?.element_id,
    recipient_id: recipientId.value,
    file: message.attachment,
    file_type: getAttachmentType(message.attachment)
  }
  const res = await dispatch('sendMediaMessage_v2', payload)
  if (res?.isValid) {
    const newMessage = res?.message
    if(!socketMessageDict.value[newMessage.message_id]){
      replaceMessage(messageObj, newMessage, currentDate)
      socketMessageDict.value[newMessage.message_id] = true
    }
  }
  else {
    messageObj.isMessageSending = false
    messageObj.error = true
    messageObj.errorMessage = res.message
    replaceMessage(messageObj, messageObj, currentDate)
  }
}

/**
 * Replace a message in the conversation object with a new one.
 * @param {Object} message - The old message object
 * @param {Object} newMessage - The new message object
 * @param {String} date - The date of the conversation
 */
const replaceMessage = (message, newMessage, date) => {
  // Find the index of the message to replace in the conversation array
  const index = conversation.value[date].findIndex((item) => item._id === message._id)
  // Replace the old message with the new one using Lodash's set() method
  set(conversation.value[date], index, { ...newMessage })
}

const isAnAction = (message) => {
  // message.action key exists and is not null or undefined
  return !!message?.action;
}

const isNoteAction = (message) => {
  return message?.action?.type === 'NOTE'
}

const toggleBookmark = async(message) => {
  const payload = {
    workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
    message_id: message?.message_id,
    is_starred: !message?.is_starred
  }
  message.is_starred = !message?.is_starred
  const res = await dispatch('setMessageBookmark_v2', payload)
  if (res.isValid) {
    // add it in the bookmark array res.message
    if (message?.is_starred) {
      currentConversationBookmarks.value.unshift(message)
    }
    else {
      // remove it from the bookmark array
      currentConversationBookmarks.value = currentConversationBookmarks.value.filter((item) => item?._id !== message?._id)
    }
  }
  else {
    message.is_starred = !message?.is_starred

    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while Bookmarking Message',
      type: 'error',
    })
  }
}

const scrollToBottom = () => {
  nextTick(() => {
    chatContainer.value?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  })
}

const handleNewMessageClick = () => {
  scrollToBottom()
  newMessageAlert.value = false
}
</script>
