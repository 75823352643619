<template>
  <b-modal
    id="createTagModalSetting"
    hide-footer
    hide-header
    modal-class="createLabelModal"
  >
    <div class="modal_head">
      <h4 v-if="action === 'create'">Create Tag</h4>
      <h4 v-else-if="action === 'update'">Edit Tag</h4>
      <h4 v-else>Merge Tags</h4>
      <button
        type="button"
        class="modal_head__close"
        data-dismiss="modal"
        @click="$bvModal.hide('createTagModalSetting')"
      >
        &times;
      </button>
    </div>

    <div v-if="action !== 'create'" class="setting_tag_list">
      <div class="tag_list_new filter_label old_label_list ml-4">
        <template v-for="tag in selectedTags" :key="tag._id">
          <label :for="tag._id + 'create'" class="checkbox_left">
            <span
              class="mb-1 mr-1 label_tag_round_dark"
              :class="[tag.tag_color]"
            >
              {{ tag.tag_name }}
            </span>
          </label>
        </template>
      </div>
    </div>

    <div class="modal_body">
      <div class="create_label_common">
        <div class="field_group">
          <label for="" class="label-animated">Name</label>
          <input
            v-model="tagName"
            type="text"
            placeholder="Enter tag name..."
          />
        </div>
        <div class="field_group">
          <label for="" class="p15">Choose Color</label>
          <div class="create_label_common__label_boxes">
            <template v-for="(value, index) in labelsCount" :key="index" >
              <div class="create_label_common__box_item">
                <input
                  :id="'color_' + index + '_label'"
                  v-model="tagColor"
                  type="radio"
                  name="color_select"
                  :v-bind="tagColor"
                  class="icon"
                  :value="'color_' + index"
                  @input="tagColor = 'color_' + index"
                />
                <label
                  :for="'color_' + index + '_label'"
                  :class="'item_inner color_' + index"
                >
                  <i
                    class="check_icon_planner far fa-check d-flex h-100 align-items-center justify-content-center"
                  >
                  </i>
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="label_bottom">
        <button
          v-if="action === 'create'"
          class="btn btn-studio-theme-space"
          @click="createTag"
        >
          <span>Create</span>
        </button>
        <button
          v-else-if="action === 'update'"
          class="btn btn-studio-theme-space"
          @click="createTag"
        >
          <span>Update</span>
        </button>
        <button v-else class="btn btn-studio-theme-space" @click="createTag">
          <span>Merge</span>
        </button>
        <button
          class="btn btn-studio-theme-grey-space"
          @click="$bvModal.hide('createTagModalSetting')"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['action', 'selectedTags', 'id'],
  data () {
    return {
      labelsCount: 20,
      tagName: null,
      tagColor: null
    }
  },
  computed: {},
  watch: {
    id: async function () {
      // watch it
      this.tagName =
        this.action === 'update' ? this.selectedTags[0].tag_name : null
      this.tagColor =
        this.action === 'update' ? this.selectedTags[0].tag_color : null
    }
  },
  mounted () {
    this.tagName =
      this.action === 'update' ? this.selectedTags[0].tag_name : null
    this.tagColor =
      this.action === 'update' ? this.selectedTags[0].tag_color : null
  },
  created () {},
  methods: {
    ...mapActions(['createTagDetails','updateTagDetails','mergeTagDetails']),
    async createTag () {
      const workspaceId = this.getActiveWorkspace._id
      if (this.tagName == null || this.tagColor == null) {
        this.alertMessage('Tag name or tag color is missing.', 'error')
        return
      }

      const tagIdList = []

      this.selectedTags.forEach((item) => {
        tagIdList.push(item._id)
      })

      const payload = {
        workspace_id: workspaceId,
        tag_name: this.tagName,
        tag_color: this.tagColor,
      }

      let tagResp
      if (this.action === 'merge') {
        payload.tag_ids = tagIdList
        tagResp = await this.mergeTagDetails(payload)
      } else if (this.action === 'create') {
        payload.tag_id = tagIdList[0]
        tagResp = await this.createTagDetails(payload)
      }
      else if (this.action === 'update') {
        payload.tag_id = tagIdList[0]
        tagResp = await this.updateTagDetails(payload)
      }

      if (tagResp.isValid) {
        this.tagColor = null
        this.tagName = null
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedTags.splice(0, this.selectedTags.length) // emptying the array
        // eslint-disable-next-line vue/require-explicit-emits
        this.$emit('create-tag', {
          tag: tagResp.tagDetails
        })
        this.$bvModal.hide('createTagModalSetting')

        // this.close()
      } else {
        this.alertMessage(tagResp.message, 'error')
        // commonMethods.showToast(this, 0, tagResp.message)
      }
    }
  },

}
</script>
