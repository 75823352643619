import proxy from '@common/lib/http-common'
import { LUMOTIVE_INBOX, LUMOTIVE_INBOX_SAVED_REPLIES } from './constants'
import { commonMethods } from './common-methods'

const cancelTokens = {}
const createCancelToken = (key) => {
  // Cancel previous request if exists for this specific API
  if (cancelTokens[key]) {
    cancelTokens[key].cancel('Operation canceled due to new request.')
  }
  // Create new token
  cancelTokens[key] = proxy.CancelToken.source()
  return cancelTokens[key].token
}

export default {
  actions: {
    // Inbox revamp compatible start
    syncAllData_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/trigger_platform_job', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    fetchSideBarDetails_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        channels: null,
        tags: null,
        conversation_counts: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/sidebar_details', payload, {
          cancelToken: createCancelToken('fetchSideBarDetails_v2')
        })
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data?.message
            responseObj.channels = response.data?.channels
            responseObj.tags = response.data?.tags
            responseObj.conversation_counts = response.data?.element_counts
            return responseObj
          }
        })
        .catch((error) => {
          if (!error?.__CANCEL__) {
            return commonMethods.getFailedResponseObject(
              responseObj,
              error?.response?.data?.message
            )
          }
        })
    },
    fetchFilteredInboxDetails_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        conversations: null,
        totalCount: null,
        currentPage: null,
        lastPage: null
      }
      return proxy
        .post(
          LUMOTIVE_INBOX + '/details',
          payload,
          {
            cancelToken: createCancelToken('fetchFilteredInboxDetails_v2')
          }
        )
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.conversations = response.data.elements
            responseObj.totalCount = response.data.total_count
            responseObj.currentPage = response.data.current_page
            responseObj.lastPage = response.data.last_page

            return responseObj
          }
          return response
        })
        .catch((error) => {
          if (!error?.__CANCEL__) {
            return commonMethods.getFailedResponseObject(
              responseObj,
              error?.response?.data?.message
            )
          }
        })
    },
    fetchFilteredInboxDetails_NoCancel ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        conversations: null,
        totalCount: null,
        currentPage: null,
        lastPage: null
      }
      return proxy
        .post(
          LUMOTIVE_INBOX + '/details',
          payload
        )
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.conversations = response.data.elements
            responseObj.totalCount = response.data.total_count
            responseObj.currentPage = response.data.current_page
            responseObj.lastPage = response.data.last_page

            return responseObj
          }
          return response
        })
        .catch((error) => {
          if (!error?.__CANCEL__) {
            return commonMethods.getFailedResponseObject(
              responseObj,
              error?.response?.data?.message
            )
          }
        })
    },
    fetchPostComments_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        total: null,
        conversations: null,
        remaining_results: 0,
        total_comment_count: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/comments', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.total = response.data.total
            responseObj.totalCommentCount = response.data.total_comment_count
            responseObj.comments =
              payload.platform === 'twitter'
                ? response.data.tweets
                : response.data.comments
            responseObj.remaining_results = response.data.remaining_results
            return responseObj
          }
          return response
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // fetch all messages against a conversation
    fetchMessages_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        messages: null,
        next: null,
        page: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/messages', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.messages = response.data?.messages
            responseObj.next = response.data?.next
            responseObj.page = response.data?.page
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    assignInboxToUser_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        data: [],
        message: null,
        status: null,
        size: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/assign_to_user', payload)
        .then((response) => {
          if (response.status === 200) {
            responseObj.data = response.data.data || []
            responseObj.message = response.data.message
            responseObj.status = response.data.status
            responseObj.size = response.data.size
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    archiveInboxItems_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        data: [],
        message: null,
        status: null,
        size: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/archive', payload)
        .then((response) => {
          if (response.status === 200) {
            responseObj.data = response.data.data || []
            responseObj.message = response.data.message
            responseObj.status = response.data.status
            responseObj.size = response.data.size
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    markAsDoneInboxItems_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        data: [],
        message: null,
        status: null,
        size: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/mark_done', payload)
        .then((response) => {
          if (response.status === 200) {
            responseObj.data = response.data.data || []
            responseObj.message = response.data.message
            responseObj.status = response.data.status
            responseObj.size = response.data.size
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    sendTextMessage_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        status: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/send_text_message', payload)
        .then((response) => {
          if (response.status === 200) {
            console.log('response send_text_message', response)
            responseObj.isValid = true
            responseObj.message = response.data?.message
            responseObj.status = response.data?.status
            return responseObj
          }
        })
        .catch((error) => {
          console.log(error)
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response?.data?.detail
          )
        })
    },
    sendMediaMessage_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        status: null
      }
      const formData = new FormData();
      Object.keys(payload).forEach(key => {
        formData.append(key, payload[key]);
      });
      return proxy
        .post(LUMOTIVE_INBOX + '/send_media_message', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('response send_text_message', response)
            responseObj.isValid = true
            responseObj.message = response.data?.message?.data?.payload
            responseObj.status = response.data?.status
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response?.data?.detail
          )
        })
    },
    sendComment_v2({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        status: null
      }
      const formData = new FormData();
      Object.keys(payload).forEach(key => {
        if (payload[key] != null) formData.append(key, payload[key]);
      });
      return proxy
        .post(LUMOTIVE_INBOX + '/send_comment', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('response send_text_message', response)
            responseObj.isValid = true
            responseObj.message = response.data?.message?.data?.payload
            responseObj.status = response.data?.status
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error.response?.data?.detail
          )
        })
    },
    addNoteToChat_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        note: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/add_note', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.note = response.data.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    getNotes_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        note: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/get_notes', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.notes = response.data.notes
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // Workspace tags start
    deleteTagsFromWorkspace ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        counts: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/delete_tags', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data?.message
            responseObj.counts = response.data?.count
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    fetchWorkspaceTags ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tags: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/workspace_tags', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.tags = response.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    createTagDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tagDetails: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/create_tag', payload)
        .then((response) => {
          // service call successful
          const resp = response.data
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = resp?.message
            responseObj.tagDetails = resp

            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    mergeTagDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tagDetails: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/merge_tags', payload)
        .then((response) => {
          // service call successful
          const resp = response.data
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = resp?.message
            responseObj.tagDetails = resp

            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    updateTagDetails ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        tagDetails: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/update_tag', payload)
        .then((response) => {
          // service call successful
          const resp = response.data
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = resp?.message
            responseObj.tagDetails = resp

            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // Workspace tags end
    // assign a new tag to conversation
    updateInboxTags_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        inbox_details: null
      }
      console.log(payload)
      return proxy
        .post(LUMOTIVE_INBOX + '/assign_tags_to_inbox', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data?.message
            responseObj.inbox_details = response.data?.inbox_details
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // saved replies start here
    fetchSavedReplyVariables ({ commit, getters, dispatch }) {
      const responseObj = {
        isValid: null,
        message: null,
        variables: null
      }
      return proxy
        .get(LUMOTIVE_INBOX_SAVED_REPLIES + '/variable/fetch')
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.variables = response.data.variables
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    fetchSavedReplies ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReplies: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/fetch', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReplies = response.data.saved_replies
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    createSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/create', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    updateSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/update', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    deleteSaveReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/delete', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    selectSavedReply ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        savedReply: null
      }
      return proxy
        .post(LUMOTIVE_INBOX_SAVED_REPLIES + '/select', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            console.log(response.data)
            responseObj.isValid = response.data.status
            responseObj.message = response.data.message
            responseObj.savedReply = response.data.saved_reply
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // saved replies end
    setMessageBookmark_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/set_message_bookmark', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    getBookmarks_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        bookmarks: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/get_bookmarks', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.bookmarks = response.data.bookmarks
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    markAsRead_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        inboxDetail: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/mark_as_read', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.status = response.data.status
            responseObj.message = response.data.message
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    deleteComment_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/delete_comment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message || error?.response?.data?.detail
          )
        })
    },
    addReviewReply_v2 ({ commit, getters, dispatch }, payload){
      const responseObj = {
        isValid: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/add_review_reply', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.data = response.data.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    deleteReviewReply_v2 ({ commit, getters, dispatch }, payload){
      const responseObj = {
        isValid: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/delete_review_reply', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.data = response.data.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    hideUnhideComment_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        response: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/hide_unhide_comment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.response
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    getUserDetails_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/get_user_details', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message?.message
            responseObj.data = response.data?.message?.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    updateUserDetails_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/update_user_details', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message?.message
            responseObj.data = response.data?.message?.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    likeUnlikeComment_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        comment: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/like_unlike_comment', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.response = response.data.comment
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    syncCommentData_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/sync_comment_data', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.message = response.data.message
            responseObj.data = response?.data?.data?.[0]
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // sync_conversation_data
    syncConversationData_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/sync_conversation_data', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.data = response.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.message
          )
        })
    },
    // sync_post_data
    syncPostData_v2 ({ commit, getters, dispatch }, payload) {
      const responseObj = {
        isValid: null,
        message: null,
        data: null
      }
      return proxy
        .post(LUMOTIVE_INBOX + '/sync_post_data', payload)
        .then((response) => {
          // service call successful
          if (response.status === 200) {
            responseObj.isValid = true
            responseObj.data = response?.data
            return responseObj
          }
        })
        .catch((error) => {
          return commonMethods.getFailedResponseObject(
            responseObj,
            error?.response?.data?.detail
          )
        })
    },
    // Inbox revamp compatible end
  }
}
