import inboxAll from '@src/modules/inbox-revamp/assets/icons/inbox-all.svg'
import inboxArchived from '@src/modules/inbox-revamp/assets/icons/inbox-archived.svg'
import inboxAssigned from '@src/modules/inbox-revamp/assets/icons/inbox-assigned.svg'
import inboxMarkedDone from '@src/modules/inbox-revamp/assets/icons/inbox-marked-done.svg'
import inboxMine from '@src/modules/inbox-revamp/assets/icons/inbox-mine.svg'
import inboxUnassigned from '@src/modules/inbox-revamp/assets/icons/inbox-unassigned.svg'
import inboxExpand from '@src/modules/inbox-revamp/assets/icons/inbox-expand.svg'
import inboxSpam from '@src/modules/inbox-revamp/assets/icons/inbox-spam.svg'
import inboxTickMark from '@src/modules/inbox-revamp/assets/icons/inbox-tick-mark.svg'
import inboxShareIcon from '@src/modules/inbox-revamp/assets/icons/inbox-share-icon.svg'
import inboxTagOrangeIcon from '@src/modules/inbox-revamp/assets/icons/inbox-tag-orange.svg'
import inboxMarkDone from '@src/modules/inbox-revamp/assets/icons/inbox-mark-done.svg'
import inboxTypeChat from '@src/modules/inbox-revamp/assets/icons/inbox-type-chat.svg'
import inboxTypeComment from '@src/modules/inbox-revamp/assets/icons/inbox-type-comment.svg'
import inboxTypeReview from '@src/modules/inbox-revamp/assets/icons/inbox-type-review.svg'
import inboxAssignTo from '@src/modules/inbox-revamp/assets/icons/inbox-assign-to.svg'
import inboxEditProfile from '@src/modules/inbox-revamp/assets/icons/inbox-edit-profile.svg'
import inboxLocation from '@src/modules/inbox-revamp/assets/icons/inbox-location.svg'
import inboxJob from '@src/modules/inbox-revamp/assets/icons/inbox-job.svg'
import inboxInfo from '@src/modules/inbox-revamp/assets/icons/inbox-info.svg'
import inboxEmail from '@src/modules/inbox-revamp/assets/icons/inbox-email.svg'
import inboxCompany from '@src/modules/inbox-revamp/assets/icons/inbox-company.svg'
import inboxPhone from '@src/modules/inbox-revamp/assets/icons/inbox-phone.svg'
import inboxNoteIcon from '@src/modules/inbox-revamp/assets/icons/inbox-note-icon.svg'
import inboxReplyIcon from '@src/modules/inbox-revamp/assets/icons/inbox-reply-icon.svg'
import inboxFbLike from '@src/modules/inbox-revamp/assets/icons/inbox-fb-like.svg'
import inboxInstaLike from '@src/modules/inbox-revamp/assets/icons/inbox-insta-like.svg'
import inboxNoResult from '@src/modules/inbox-revamp/assets/icons/inbox-no-result.png'
import inboxInfoCircle from '@src/modules/inbox-revamp/assets/icons/inbox-info-circle.svg'
import inboxSyncIcon from '@src/modules/inbox-revamp/assets/icons/inbox-sync-icon.svg'

export const useIcons = () => {
  return {
    inboxAll,
    inboxArchived,
    inboxAssigned,
    inboxMarkedDone,
    inboxMine,
    inboxUnassigned,
    inboxExpand,
    inboxSpam,
    inboxTickMark,
    inboxShareIcon,
    inboxTagOrangeIcon,
    inboxMarkDone,
    inboxTypeChat,
    inboxTypeComment,
    inboxTypeReview,
    inboxAssignTo,
    inboxEditProfile,
    inboxLocation,
    inboxJob,
    inboxInfo,
    inboxEmail,
    inboxCompany,
    inboxPhone,
    inboxNoteIcon,
    inboxReplyIcon,
    inboxFbLike,
    inboxInstaLike,
    inboxNoResult,
    inboxInfoCircle,
    inboxSyncIcon
  }
}
