<template>
  <div
    :id="`message-${message?.message_id}`"
    class="flex gap-3 mt-2 flex-row-reverse"
    :class="{
      'opacity-50': message?.isMessageSending,
      }"
  >
  <template v-if="message?.error">
      <div class="h-[35px] flex items-center mt-1">
        <i v-tooltip="message?.errorMessage" class="fas fa-exclamation-triangle text-red-500"></i>
      </div>
    </template>
    <div v-tooltip="message?.action?.action_performed_by?.user_name" class="">
      <img
        :src="getUserImage"
        alt=""
        class="w-[35px] h-[35px] rounded-full mt-1 float-right"
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
    </div>
    <div class="space-y-1 flex-1">
      <div
        id="message"
        class="inline-block rounded-3xl px-3.5 py-3 max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%] bg-[#FFFCF3] border !border-[#FFE48B] float-right"
      >
        <span class="text-sm whitespace-pre-wrap">
          {{ message?.message }}
        </span>
      </div>
      <div>
        <span
          class="text-opacity-50 cstu-text-gray-500 text-xs float-right clear-both min-h-[17px]"
        >
          {{ getFormattedTime(message?.created_time) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'

const { getUTCFormattedTimeZoneDate } = useComposerHelper()

const props = defineProps({
  message: {
    type: Object,
    required: true
  }
})

const getUserImage = computed(() => {
  return props.message?.action?.action_performed_by?.user_image || `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
})

/**
 * Format a time string to display in the chat.
 * @param {String} time - The time string to format
 * @returns {String} - The formatted time string
 */
const getFormattedTime = (time) => {
  return getUTCFormattedTimeZoneDate(time).formatTime();
}
</script>
