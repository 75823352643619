<template>
  <div class="flex flex-col flex-1 items-center gap-4 bg-[#f4f7fa] py-8 px-6 overflow-hidden">
    <div class=" flex flex-col w-full items-center overflow-hidden">
      <div :key="postDetails?.element_id" class="post-view max-w-[650px] w-full overflow-y-auto overflow-x-hidden">
        <!-- Post Header -->
        <div class="flex items-center gap-10 px-4 py-3 sticky top-0 bg-white -mr-1 z-10">
          <div class="flex flex-1 items-center gap-2">
            <img
              :src="userDetails?.image"
              alt=""
              class="w-[40px] h-[40px] rounded-full"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <div>
              <p class="font-medium text-sm">{{ userDetails?.name }}</p>
              <p class="text-xs">{{ momentWrapper(post?.element_details?.created_time).formatDateTime() }}</p>
            </div>
          </div>
          <div>
            <a
              v-tooltip="{
                content: tooltipHtml(`View post on ${getPlatformName(post?.platform)}`),
                allowHTML: true,
                theme: 'light',
              }"
              :href="sanitizeUrl(postDetails?.link)"
              target="_blank" rel="noopener"
            >
              <img :src="externalLinkIcon" alt="External Link Icon" />
            </a>
          </div>
        </div>

        <!-- Post Content -->
        <div class="flex flex-col gap-4">
          <!-- Text Content -->
           <template v-if="inboxType === 'review'">
            <div class="flex items-center px-4">
              <span
                v-for="star in 5"
                :key="star"
                class="flex items-center"
              >
                <svg
                  v-if="star <= getStarLimit(postDetails?.rating)"
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Rating {{ star }}</title>
                  >
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
                <svg
                  v-else
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-300 dark:text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Rating {{ star }}</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  ></path>
                </svg>
              </span>
            </div>
          </template>
          <div
            :class="{
              'order-last': post?.platform === 'instagram',
              'mb-2': postDetails?.post_attachment?.length === 0 || post?.platform === 'instagram',
             }"
          >
            <HtmlRenderer
              v-if="postDetails?.post_message?.length > 0"
              tag="div"
              class="text-sm whitespace-pre-wrap px-4"
              :html-content="parseDescriptionHtml(trimmedPostMessage, trimmedPostMessage?.length, true, post?.platform === 'linkedin')"
            />
            <template v-if="isPostContentLarge">
              <div class="px-4">
                <span class="cursor-pointer hover:underline text-cs-primary" @click="togglePostReadMore">
                  {{ !postReadMore ? 'Read more' : 'Read less' }}
                </span>
              </div>
            </template>
        </div>
        <!-- Media Content -->
        <template v-if="postDetails?.post_attachment?.length > 0">
          <template v-if="isLinkedin && postDetails?.post_type?.toLowerCase().includes('carousel')">
            <img
              v-tooltip="{ content: 'No preview available' }"
              src="https://storage.googleapis.com/lumotive-web-storage/default/carousel_pdf_empty_state.jpg"
              alt="No preview available"
              class="w-full object-cover max-h-[400px]"
              @error="
                (event) => {
                  event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
                }
              "
            >
          </template>
          <template v-else-if="['images', 'photo'].includes(postAttachments?.is_type?.toLowerCase()) || ['images', 'photo', 'image'].includes(postAttachments?.type?.toLowerCase())">
            <img
              :src="postAttachments?.post_image || postAttachments?.url || postAttachments?.image || postAttachments?.image_url"
              alt=""
              class="w-full object-cover max-h-[400px]"
                @error="
                  (event) => {
                    event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
                  }
                "
              >
            </template>
            <template v-else-if="postAttachments?.type?.toLowerCase() === 'video' || postAttachments?.is_type?.toLowerCase() === 'video' || postAttachments?.type?.toLowerCase() === 'videos' || postAttachments?.type?.toLowerCase() === 'video_inline'">
              <video
                :src="postAttachments?.post_video || postAttachments?.video || postAttachments?.url || postAttachments?.video_url"
                controls
                class="w-full max-h-[400px]"
              ></video>
            </template>
            <template v-else-if="isAblum">
              <div
                class="grid gap-1 max-h-[400px]"
                :class="gridColumns"
              >
                <template v-for="(item, index) in displayedAttachments" :key="index">
                  <div
                    class="relative overflow-hidden"
                    :class="{ 'h-[200px]': displayedAttachments.length === 4, 'h-[400px]': displayedAttachments.length < 4 }"
                  >
                    <img
                      :src="item.image || item.url"
                      :alt="`Album image ${index + 1}`"
                      class="w-full h-full object-cover"
                      @error="
                        (event) => {
                          event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
                        }
                      "
                    />
                    <!-- Overlay for the last item if there are more images -->
                    <div
                      v-if="index === 3 && remainingCount > 0"
                      class="absolute inset-0 bg-black-900 bg-opacity-30 flex items-center justify-center"
                    >
                      <span class="text-white text-2xl font-semibold">+{{ remainingCount }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else-if="postAttachments?.is_type === 'image_album'">
              <Carousel
                id="preview-carousel"
                class="facebook-carousel-preview"
                :navigation-next-label="navigationNext()"
                :navigation-prev-label="navigationPrev()"
                :navigation-enabled="true"
                :pagination-enabled="false"
                :scroll-per-page="false"
                :space-padding-max-offset-factor="20"
                :per-page="1"
            >
              <template
                v-for="(img, index) in postAttachments?.post_image_album"
                :key="`carousel-index-${index}`"
              >
                <Slide class="">
                  <img
                      v-tooltip="{
                    content: 'Carousel Preview',
                  }"
                      class="w-full object-cover max-h-[400px]"
                      :class="getSlideClasses"
                      :src="img"
                      alt=""
                      @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                    }
                  "
                  />
                </Slide>
              </template>
            </Carousel>
            </template>
            <template v-else-if="postAttachments?.type === 'carousel_album'">
              <Carousel
                id="preview-carousel"
                class="facebook-carousel-preview"
                :navigation-next-label="navigationNext()"
                :navigation-prev-label="navigationPrev()"
                :navigation-enabled="true"
                :pagination-enabled="false"
                :scroll-per-page="false"
                :space-padding-max-offset-factor="20"
                :per-page="1"
            >
              <template
                v-for="(item, index) in postAttachments?.['sub-attachments']"
                :key="`carousel-index-${index}`"
              >
                <Slide class="">
                  <template v-if="item?.type?.toLowerCase() === 'photo' || item?.type?.toLowerCase() === 'image'">
                    <img
                        v-tooltip="{
                      content: 'Carousel Preview',
                    }"
                        class="w-full object-cover max-h-[400px]"
                        :class="getSlideClasses"
                        :src="item?.url"
                        alt=""
                        @error="
                      (event) => {
                        event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      }
                    "
                    />
                  </template>
                  <template v-else-if="item?.type?.toLowerCase() === 'video'">
                    <video
                        :src="item?.url"
                        controls
                        class="w-full max-h-[400px]"
                    ></video>
                  </template>
                  <template v-else-if="item?.type?.toLowerCase() === 'share'">
                    <img
                      v-tooltip="{
                        content: 'Carousel Preview',
                      }"
                      class="w-full object-cover max-h-[400px]"
                      :class="getSlideClasses"
                      :src="item?.url"
                      alt=""
                      @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        }
                      "
                    />
                  </template>
                </Slide>
              </template>
            </Carousel>
            </template>
            <template v-else-if="post?.platform === 'instagram' && postDetails?.post_type === 'CAROUSEL_ALBUM' && postDetails?.post_attachment?.length > 0">
              <Carousel
                id="instagram-carousel"
                class="facebook-carousel-preview"
                :navigation-next-label="navigationNext()"
                :navigation-prev-label="navigationPrev()"
                :navigation-enabled="true"
                :pagination-enabled="true"
                :scroll-per-page="false"
                :space-padding-max-offset-factor="20"
                :per-page="1"
              >
                <template
                  v-for="(item, index) in postDetails?.post_attachment"
                  :key="`instagram-carousel-${index}`"
                >
                  <Slide>
                    <template v-if="item?.media_type === 'IMAGE'">
                      <img
                        v-tooltip="{
                          content: 'Carousel Preview',
                        }"
                        class="w-full object-cover max-h-[400px]"
                        :src="item?.media_url"
                        alt=""
                        @error="
                          (event) => {
                            event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                          }
                        "
                      />
                    </template>
                    <template v-else-if="item?.media_type === 'VIDEO'">
                      <video
                        :src="item?.media_url"
                        controls
                        class="w-full max-h-[400px]"
                      ></video>
                    </template>
                  </Slide>
                </template>
              </Carousel>
            </template>
            <template v-else-if="['link', 'article','share'].includes(postAttachments?.type?.toLowerCase() || postAttachments?.is_type?.toLowerCase())">
              <div class="cursor-pointer " @click="openLink(postAttachments?.link || postAttachments?.source)">
                <img
                  :src="postAttachments?.image || postAttachments?.url || postAttachments?.post_image"
                  alt=""
                  class="w-full object-cover max-h-[400px]"
                  @error="
                    (event) => {
                      event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
                    }
                  "
                />
                <div class="cstu-bg-gray-100 py-4 px-3 ">
                  <p class="text-xs cstu-text-gray-400 truncate">{{ extractDomain(postAttachments?.link || postAttachments?.source) }}</p>
                  <p class="text-sm font-semibold truncate">{{ postAttachments?.title }}</p>
                  <p class="text-xs cstu-text-gray-400 truncate">{{ postAttachments?.description }}</p>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div>
          <hr v-if="inboxType !== 'review'" class="my-0"/>
          <!-- Post Actions -->
          <div v-if="inboxType !== 'review'" class="flex items-center justify-between px-4 py-3">
            <!-- stats -->
            <div class="flex items-center gap-1.5">
              <div class="flex items-center gap-3">
                <div v-if="!isLinkedin" class="flex items-center gap-1">
                  <svg v-if="isInstagram" xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M7.44527 13.5C6.99832 13.5 6.55138 13.3299 6.21115 12.9896L1.29826 8.07674C-0.432755 6.34572 -0.432755 3.52918 1.29826 1.79819C2.98606 0.110387 5.70601 0.0683896 7.44532 1.67193C9.18475 0.0696112 11.9035 0.112191 13.5909 1.79955C14.4294 2.63809 14.8912 3.75298 14.8912 4.93882C14.8912 6.12462 14.4293 7.23957 13.5909 8.07808L8.67941 12.9896C8.33915 13.3299 7.89221 13.5 7.44527 13.5ZM4.43755 1.6614C3.56249 1.6614 2.73981 2.00216 2.12103 2.62093C1.50226 3.2397 1.16148 4.06241 1.16148 4.93748C1.16148 5.81254 1.50223 6.63522 2.12101 7.254L7.03389 12.1669C7.26072 12.3937 7.62981 12.3937 7.85664 12.1669L12.7682 7.25536C13.3869 6.63659 13.7277 5.81391 13.7277 4.93884C13.7277 4.06378 13.387 3.2411 12.7682 2.62232C11.4908 1.34499 9.41248 1.34499 8.13512 2.62232L7.85664 2.90081C7.62943 3.12805 7.26107 3.12802 7.03387 2.90081L6.75404 2.62099C6.1353 2.00219 5.31262 1.6614 4.43755 1.6614Z" fill="#1E1E1E" fill-opacity="0.6"/>
                  </svg>
                  <svg v-else-if="isFacebook" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M4.04837 6.39997L6.48707 1C6.97215 1 7.43737 1.18964 7.78038 1.5272C8.12339 1.86477 8.31609 2.3226 8.31609 2.79999V5.19997H11.7668C11.9436 5.198 12.1187 5.23388 12.2799 5.3051C12.4412 5.37633 12.5848 5.48121 12.7008 5.61248C12.8168 5.74375 12.9024 5.89826 12.9516 6.06532C13.0009 6.23238 13.0127 6.40798 12.9862 6.57996L12.1448 11.9799C12.1007 12.2661 11.9531 12.5269 11.729 12.7143C11.5049 12.9018 11.2196 13.0032 10.9255 12.9999H4.04837M4.04837 6.39997V12.9999M4.04837 6.39997H2.21935C1.89596 6.39997 1.58581 6.52639 1.35714 6.75144C1.12847 6.97648 1 7.2817 1 7.59996V11.7999C1 12.1182 1.12847 12.4234 1.35714 12.6485C1.58581 12.8735 1.89596 12.9999 2.21935 12.9999H4.04837" stroke="#1E1E1E" stroke-opacity="0.6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>{{ postDetails?.likes_count || 0 }}</span>
                </div>
                <div class="flex items-center gap-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_35153_8182)">
                      <path d="M8.27341 14.4454C8.17652 14.4454 8.07943 14.4214 7.99146 14.3731C7.80401 14.2702 7.6875 14.0733 7.6875 13.8595V12.6877C7.6875 12.3646 7.42465 12.1017 7.10156 12.1017H6.16406C3.25629 12.1017 0.890625 9.73605 0.890625 6.82828C0.890625 3.9205 3.25629 1.55484 6.16406 1.55484H10.6172C13.525 1.55484 15.8906 3.9205 15.8906 6.82828C15.8906 6.85251 15.8905 6.87662 15.8902 6.90067C15.8764 8.32107 15.4449 9.3856 14.4912 10.3524C13.6185 11.2371 12.3433 12.0236 10.5783 13.1122C9.98394 13.4788 9.31028 13.8943 8.58794 14.3538C8.49223 14.4147 8.38292 14.4454 8.27341 14.4454ZM6.16406 2.72672C3.90246 2.72672 2.0625 4.56668 2.0625 6.82828C2.0625 9.08988 3.90246 10.9298 6.16406 10.9298H7.10156C8.07082 10.9298 8.85938 11.7184 8.85938 12.6877V12.7982C9.25008 12.5546 9.6198 12.3265 9.96313 12.1148C11.6584 11.0691 12.8833 10.3137 13.6569 9.52945C14.3999 8.77626 14.7075 8.01134 14.7184 6.8893C14.7186 6.86906 14.7188 6.8487 14.7188 6.82828C14.7188 4.56668 12.8788 2.72672 10.6172 2.72672H6.16406Z" fill="#1E1E1E" fill-opacity="0.6"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_35153_8182">
                        <rect width="15" height="15" fill="white" transform="translate(0.890625 0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <span> {{ totalComments || 0 }}</span>
                </div>
              </div>
              <span>&#x2022;</span>
              <span>
                 {{ getUTCFormattedTimeZoneDate(post?.updated_at).fromNow() }}
              </span>
            </div>
            <div>
              <Dropdown
                placement="bottom"
                dropdown-classes="!min-w-[150px]"
                button-classes="flex items-center gap-2"
                :unstyled="true"
              >
                <template v-slot:selected>
                <i class="cstu-text-gray-400" :class="selectedSort.value === 'desc' ? 'fa fa-sort-amount-asc' : 'fa fa-sort-amount-desc'" aria-hidden="true"></i>

                  <span v-tooltip="'Sort by'">
                    {{ selectedSort.label }}
                  </span>
                </template>
                <DropdownItem
                  v-for="option in sortingOptions"
                  :key="option.value"
                  class="flex items-center justify-between"
                  size="sm"
                  :selected="option.value === selectedSort.value"
                  @click="updateSelectedSort(option)"
                >
                  {{ option.label }}
                  <template v-if="selectedSort.value === option.value">
                    <img
                      :src="inboxTickMark"
                      alt="selected list icon"
                    />
                  </template>
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
          <hr class="my-0" :class="{ 'mt-4': inboxType === 'review' }"/>

        </div>
        <div class="mx-auto w-full max-w-2xl">
          <CommentBlock
            v-for="comment in commentsList"
            :key="comment._id"
            :comment="comment"
            :active-inbox-detail="post"
            :add-comment="addComment"
            :delete-comment="deleteComment"
            :allow-attachment="allowAttachment"
            :depth="0"
            :max-depth="maxDepth"
            :generate-unsent-comment="generateUnsentComment"
            :allow-sync="!isReview"
            @like="handleLikeComment"
            @hide="handleHideComment"
            @remove-child="handleRemoveChild(comment, $event)"
            @edit="handleEditComment(comment, $event)"
            @private-reply="handlePrivateReply"
            @sync-comment="handleSyncComment"
          />
          <template v-if="isFetchingComments">
            <div class="flex justify-center">
              <clip-loader class="p-3" :color="'#436aff'" :size="'2rem'" />
            </div>
          </template>
          <template v-else-if="canLoadMore">
            <div class="flex justify-center mb-2">
              <Button
                variant="light"
                color="secondary"
                size="sm"
                class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
                @click="loadMoreComments"
              >
                Load more
              </Button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <MessageComposer
      v-if="!hasReviewReply"
      ref="commentBox"
      :placeholder="'Write a comment...'"
      custom-class="!max-w-[650px]"
      :active-inbox-detail="post"
      :allow-note="false"
      :allow-attachment="allowAttachment"
      @send="createComment($event)"
     />
     <template v-if="privateReplyTo !== null">
       <ReplyPrivate
       :reply-to="privateReplyTo"
       :is-sending="isPrivateSending"
       @reply="sendPrivateMessage"
       ></ReplyPrivate>
      </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, inject , nextTick } from 'vue'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import externalLinkIcon from '@src/assets/img/analytics/external-link.svg'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { Button, Dropdown, DropdownItem } from '@contentstudio/ui'
import useDateFormat from '@common/composables/useDateFormat'
import { parseDescriptionHtml } from '@common/lib/helper'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import { useStore } from '@state/base'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import CommentBlock from '@src/modules/inbox-revamp/components/CommentBlock.vue'
import { swalAttributes } from '@common/constants/common-attributes'
import {useInbox} from '@src/modules/inbox-revamp/composables/useInbox'
import ReplyPrivate from '@src/modules/inbox/components/ReplyPrivate.vue'
import { EventBus } from '@common/lib/event-bus'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const root = inject('root')
const { $bvModal } = root

const { dispatch, getters } = useStore()
const { momentWrapper } = useDateFormat()
const { tooltipHtml, getUTCFormattedTimeZoneDate } = useComposerHelper()
const { inboxTickMark } = useIcons()
const { currentConversation, refreshReviewObject, updateLastComment, fetchTargetedConversation, updateCurrentConversation, scrollMessageIntoView } = useInbox()

const sortingOptions = [
  {
    label: 'Newest',
    value: 'desc',
  },
  {
    label: 'Oldest',
    value: 'asc',
  },
]
const props = defineProps({
  post: {
    type: Object,
    required: true
  },
  platformDetails: {
    type: Object,
    required: true
  }
})

const commentsList = ref([]);
const isFetchingComments = ref(true);
const selectedSort = ref(sortingOptions[0])
const page = ref(1)
const limit = ref(10)
const canLoadMore = ref(false)
const postReadMore = ref(false)
const privateReplyTo = ref(null)
const commentBox = ref(null)
const isPrivateSending = ref(false)
const totalComments = ref(0)

const allowAttachment = computed(() => {
  const notAllowedPlatforms = ['google_my_business','gmb', 'instagram']
  return !notAllowedPlatforms.includes(props.post?.platform)
})

const isLinkedin = computed(() => {
  return props.post?.platform.toLowerCase() === 'linkedin'
})

const isInstagram = computed(() => {
  return props.post?.platform.toLowerCase() === 'instagram'
})

const isFacebook = computed(() => {
  return props.post?.platform.toLowerCase() === 'facebook'
})

const maxDepth = computed(() => {
  if(props.post?.platform === 'facebook') {
    return 2
  }
  else if(props.post?.platform === 'google_my_business' || props.post?.platform === 'gmb') {
    return 0
  }
  return 1
})

const postDetails = computed(() => {
  return props.post?.element_details || {}
})

const inboxType = computed(() => {
  return props.post?.inbox_type || ''
})
const userDetails = computed(() => {
  return props.post?.inbox_details?.posted_by || {}
})

const postAttachments = computed(() => {
  return postDetails.value?.post_attachment?.[0] || {}
})

const isAblum = computed(() => {
  return (
    postAttachments.value?.is_type === 'album' ||
    postAttachments.value?.type === 'album' ||
    postAttachments.value?.type === 'multi_image'
  )
})

const displayedAttachments = computed(() => {
  if (isAblum.value) {
    return postAttachments.value?.['sub-attachments'].slice(0, 4)
  }
  return []
})

const remainingCount = computed(() => {
  if (isAblum.value) {
    return postAttachments.value?.['sub-attachments'].length - 4
  }
  return 0
})

const isPostContentLarge = computed(() => {
  return postDetails.value?.post_message?.length > 200
})

const trimmedPostMessage = computed(() => {
  if (postReadMore.value || !isPostContentLarge.value) return postDetails.value?.post_message
  return postDetails.value?.post_message?.substr(0, 200) + '...'
})

const gridColumns = computed(() => {
  const count = displayedAttachments.value.length
  switch (count) {
    case 3:
      return 'grid-cols-3'
    case 2:
      return 'grid-cols-2'
    case 1:
      return 'grid-cols-1'
    default:
      return 'grid-cols-2'
  }
})

const isReview = computed(() => {
  return props.post?.inbox_type === 'review'
})

const hasReviewReply = computed(() => {
  if(!isReview.value) return false
  return props.post?.review_reply?.review_message || commentsList.value.length > 0
})

onMounted(() => {
  EventBus.$on('sync-comment-list', (data) => {
    console.log('sync-comment-list', data)
    commentsList.value = data
  })

  if(isReview.value){
    canLoadMore.value = false
    isFetchingComments.value = false
    generateReviewAsComment()
    return
  }
  fetchPostComments()
  nextTick(() => {
    commentBox.value?.focusTextarea()
  })
})

onUnmounted(() => {
  EventBus.$off('sync-comment-list')
})

watch(
  () => props.post?.review_reply,
  (newVal, oldVal) => {
    if (newVal === oldVal) {
      return
    }
    canLoadMore.value = false
    isFetchingComments.value = false
    commentsList.value = []
    generateReviewAsComment()
  },
)

watch(
  () => postDetails.value.element_id,
  (newVal, oldVal) => {
    if (newVal === oldVal) {
      return
    }
    commentBox.value?.focusTextarea()
    page.value = 1
    privateReplyTo.value = null
    selectedSort.value = sortingOptions[0]
    postReadMore.value = false
    commentsList.value = []
    if(isReview.value){
      canLoadMore.value = false
      isFetchingComments.value = false
      generateReviewAsComment()
      return
    }
    canLoadMore.value = false

    fetchPostComments()
  },
)
function navigationNext() {
  return `<i class="fas fa-chevron-right"></i>`
}

function navigationPrev() {
  return `<i class="fas fa-chevron-left"></i>`
}

function generateReviewAsComment( customData = {}, forceAdd = false ){
  if(!hasReviewReply.value && !forceAdd){
    commentsList.value = []
    return
  }
  const comment = {
    message:props.post?.review_reply?.review_message,
    can_edit:true,
    can_remove:true,
    created_time: props.post?.review_reply?.updated_time,
    from: [{
      first_name: props?.platformDetails?.platform_name ,
      last_name: '' ,
      profile_pic: props?.platformDetails?.platform_image ,
    }],
    ...customData
  }
  commentsList.value = [comment]
}
const getStarLimit = (stars) =>{
  switch (stars) {
    case 'ONE':
      return 1
    case 'TWO':
      return 2
    case 'THREE':
      return 3
    case 'FOUR':
      return 4
    case 'FIVE':
      return 5
    default:
      return 0
  }
}

async function fetchPostComments(append = false) {
  try {
    isFetchingComments.value = true
    const payload = {
      post_id: postDetails.value?.element_id,
      workspace_id: getters.getWorkspaces.activeWorkspace._id,
      platform: props.post?.platform,
      sort_order: selectedSort.value.value,
      page: page.value,
      limit: limit.value,
    }
    const res = await dispatch('fetchPostComments_v2', payload)


    if (isReview.value) return // check if user switched to a review post while fetching comments

    if (res.isValid) {
      totalComments.value = res?.totalCommentCount || 0
      if (append) {
        commentsList.value.push(...res?.comments)
      } else {
        commentsList.value = res?.comments || []
      }
      if(res?.remaining_results > 0) {
        canLoadMore.value = true
      }
      else {
        canLoadMore.value = false
      }
    }
    else {
      commentsList.value = []
      canLoadMore.value = false
    }
    isFetchingComments.value = false
  } catch (err) {
    console.log('err', err)
  }
}

const generateUnsentComment = (message) => {
  const [firstName, ...lastNameParts] = props.platformDetails?.platform_name.split(' ')
  const lastName = lastNameParts.join(' ')
  const attachment = []
  if(message?.attachment) {
    const type = message?.attachment?.type?.split('/')[0]
    attachment.push({
      [type]: URL.createObjectURL(message?.attachment),
      type,
    })
  }
  return {
    _id: Date.now(),
    comment_id: '',
    workspace_id: props.platformDetails?.workspace_id,
    attachment ,
    attachment_type: message?.attachment?.type?.split('/')[0],
    can_comment: true,
    can_hide: false,
    can_like: false,
    can_remove: false,
    can_reply_privately: false,
    comment_count: null,
    created_time: momentWrapper().utc(),
    from: [
      {
        id: props.platformDetails?.platform_id,
        first_name: firstName,
        last_name: lastName,
        profile_pic: props.platformDetails?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg',
        platform_type: props.platformDetails?.platform_type,
        created_at: momentWrapper().utc()
      }
    ],
    is_reply: false,
    like_count: 0,
    message: message?.text,
    parent_id: '',
    permalink_url: '',
    platform_id: props.platformDetails?.platform_id,
    post_id: '',
    reactions: {},
    text_link: '',
    children: [],
    isCommentSending: true
  }
}

const replaceMessage = (oldMessage, newMessage) => {
  const index = commentsList.value.findIndex(comment => comment._id === oldMessage?._id);
  if (index !== -1) {
    commentsList.value[index] = newMessage;
  }
}

const createComment = async (data) => {
  if(isReview.value) {
    handleReviewComment(data, undefined)
    return;
  }
  const messageObj = generateUnsentComment(data)
  commentsList.value.unshift(messageObj);

  const res = await addComment({ message: data , isReply: false });
  if (res) {
    replaceMessage(messageObj, res);
  }
  else {
    commentsList.value.shift();
  }
}

async function addComment (data , privateReply = false) {
  console.log('comment',data)
  const payload = {
    platform: props.post?.platform,
    platform_id: props.post?.platform_id,
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    element_id: postDetails.value?.element_id,
    message: data.message?.text,
    attachment_file: data.message?.attachment,
    attachment_type: data.message?.attachment?.type?.split('/')[0],
    comment_id: data?.comment?.comment_id,
    comment_urn: data?.comment?.comment_urn,
    isPrivateReply: privateReply,
  }
  console.log('payload',payload)
  const res = await dispatch('sendComment_v2', payload)
  if (res.isValid) {
    totalComments.value++
    await updateLastComment(res?.message)
    scrollMessageIntoView(res?.message?._id)
    return res?.message
  }
  else{
    await dispatch('toastNotification', {
      message: res.message || 'Something went wrong while Posting Comment',
      type: 'error',
    })
    return undefined;
  }
}

const togglePostReadMore = () => {
  postReadMore.value = !postReadMore.value
}

const updateSelectedSort = (value) => {
  selectedSort.value = value
  page.value = 1
  postReadMore.value = false
  commentsList.value = []
  fetchPostComments()
}

const loadMoreComments = async () => {
  page.value++
  await fetchPostComments(true)
}

const deleteComment = async ({comment , isReply = false}) => {
  const options = {
      ...swalAttributes(),
      modalClass:
        'sweet-alert-confirmation fade sweet-alert-minimize-composer',
      hideHeader: true,
    }
  const consent = await $bvModal.msgBoxConfirm(
    'Are you sure you want to delete this comment?',
    {
      title: 'Delete Comment',
      ...options,
      cancelTitle: 'Cancel',
      okTitle: 'Delete',
    }
  )
  if (consent === null || consent === false) {
    return
  }
  comment.isDeleting = true
  if(isReview.value){
    handleReviewCommentDelete(comment)
   return
  }
  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_type: props.platformDetails?.platform_type,
    platform_id: props.post?.platform_id,
    post_id: postDetails.value?.element_id,
    comment_id: comment.comment_id || comment.comment_urn,
  }
  const res = await dispatch('deleteComment_v2', payload)
  if (res.isValid) {
    // If the comment is a reply, return the result without modifying the list, it will be handled by the CommentBlock
    // Otherwise, remove the deleted comment from the commentsList and update total count
    if (isReply) {
      return res;
    } else {
      commentsList.value = commentsList.value.filter(c => c?.comment_id !== comment?.comment_id);
      totalComments.value = Math.max(0, totalComments.value - 1);
    }
    // Update current conversation
    const conversationToUpdate = await fetchTargetedConversation(postDetails.value?.element_id)
    if(conversationToUpdate) {
      currentConversation.value = conversationToUpdate
      updateCurrentConversation(conversationToUpdate)
      scrollMessageIntoView(conversationToUpdate._id)
    }
    dispatch('toastNotification', {
      message: 'Comment deleted successfully',
      type: 'success'
    })
  }
  else {
    comment.isDeleting = false
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while deleting the comment',
      type: 'error',
    })
  }
}

const handleRemoveChild = (parentComment, comment) => {
  // find index of comment
  const index = parentComment.children.findIndex(c => c._id === comment._id)
  if (index !== -1) {
    parentComment.children.splice(index, 1)
  }
}

const handleEditComment = (comment, newMessage) => {
  if (isReview.value) {
    handleReviewComment(newMessage, comment)
  }
}

const handleReviewComment = async (newMessage, comment = {}) => {
  // local comment is only to restore old content if needed. while comment is reference of orginial comment
  const localComment = JSON.parse(JSON.stringify(comment))
  console.log(localComment, newMessage)

  const isNewComment = !comment?._id
  console.log(isNewComment)
  if(!isNewComment){
    comment.message = newMessage.text
    comment.isCommentSending = true
  }
  else{
    generateReviewAsComment({
      message: newMessage.text,
      isCommentSending: true,
      created_time: momentWrapper().utc(),
    }, true)
  }

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.post?.platform_id,
    element_id: postDetails.value?.element_id,
    review_reply: newMessage.text,
  }

  const res = await dispatch('addReviewReply_v2', payload)
  console.log(res)
  if (res?.isValid) {
    refreshReviewObject(res?.data)
  }
  else {
    comment.isCommentSending = false
    comment.message = localComment.message
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while updating the comment',
      type: 'error',
    })
  }
}

const handleHideComment = async (comment) => {
  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.post?.platform_id,
    platform_type: props.platformDetails?.platform_type,
    comment_id: comment.comment_id,
    is_hidden: !comment.is_hidden,
  }
  comment.isHiding = true
  const res = await dispatch('hideUnhideComment_v2', payload)
  if (res?.isValid) {
    comment.is_hidden = !comment.is_hidden
    comment.isHiding = false
  }
  else {
    comment.isHiding = false
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while hiding the comment',
      type: 'error',
    })
  }
}

const handleLikeComment = async (comment) => {
  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.post?.platform_id,
    platform_type: props.platformDetails?.platform_type,
    comment_id: comment.comment_id,
    is_liked: !comment.liked,
  }
  // toggle the like status
  comment.liked = !comment.liked
  // update like_count
  comment.like_count = comment.liked ? comment.like_count + 1 : comment.like_count - 1
  const res = await dispatch('likeUnlikeComment_v2', payload)
  if (!res?.isValid) {
    // revert the like status
    comment.liked = !comment.liked
    comment.like_count = comment.liked ? comment.like_count + 1 : comment.like_count - 1
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while liking the comment',
      type: 'error',
    })
  }
}

const handleReviewCommentDelete = async ( comment ) => {

  const payload = {
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_id: props.post?.platform_id,
    element_id: postDetails.value?.element_id,
  }
  comment.isDeleting = true
  const res = await dispatch('deleteReviewReply_v2', payload)
  console.log(res)
  if (res?.isValid) {
    refreshReviewObject(res?.data, false)
    dispatch('toastNotification', {
      message: 'Review reply deleted successfully',
      type: 'success'
    })
  }
  else {
    comment.isDeleting = false
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while deleting the review reply',
      type: 'error',
    })
  }
}

const openLink = (url) => {
  window.open(url, '_blank')
}

const extractDomain = (url) => {
  try {
    const newUrl = new URL(url)
    return newUrl.hostname
  }
  catch (e) {
    return ''
  }
}
const handlePrivateReply = (comment) => {
  privateReplyTo.value = comment
  nextTick(() => {
    $bvModal.show('sendDirectMessage')
  })
}

const sendPrivateMessage = async (data) => {
  isPrivateSending.value = true
  const res = await addComment(data, true)
  if (res) {
    privateReplyTo.value = null
    $bvModal.hide('sendDirectMessage')
    dispatch('toastNotification', {
      message: 'Private reply sent successfully',
      type: 'success'
    })
  }
  isPrivateSending.value = false
}

const handleSyncComment = async (comment) => {
  const payload = {
    post_id: comment.post_id,
    comment_id: comment.comment_id,
    workspace_id: getters.getWorkspaces.activeWorkspace._id,
    platform_type: props.platformDetails?.platform_type,
    platform_id: props.post?.platform_id,
    comment_count: 1000
  }
  comment.isSyncing = true
  const res = await dispatch('syncCommentData_v2', payload)
  if (res?.isValid) {
    console.log(res)
    const commentIndex = commentsList.value.findIndex(c => c.comment_id === comment.comment_id);
    if (commentIndex !== -1) {
      commentsList.value[commentIndex] = {
        ...res?.data,
        children: res?.data?.children || []
      };
    }
    dispatch('toastNotification', {
      message: 'Comment synced successfully',
      type: 'success'
    })
  }
  else {
    dispatch('toastNotification', {
      message: res.message || 'Something went wrong while syncing the comment',
      type: 'error',
    })
  }
  comment.isSyncing = false
}

const getPlatformName = (platform) => {
  switch (platform.toLowerCase()) {
    case 'facebook':
      return 'Facebook'
    case 'instagram':
      return 'Instagram'
    case 'linkedin':
      return 'LinkedIn'
    case 'gmb':
      return 'GMB'
    case 'google_my_business':
      return 'GMB'
    default:
      return 'Unknown'
  }
}
</script>

<style scoped>
  .post-view {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.05);
  }
</style>
