import proxy from '@common/lib/http-common'
import {
  accountBaseUrl,
  billingBaseUrl,
  cookieDomain,
} from '@src/config/api-utils'
import { pusherAuthSocketInbox } from '@common/lib/pusher'
import { AppAnalyticsMixin } from '@src/app_analytics'
import {
  fetchProfileUrl,
  // fetchSubscriptionUrl,
  appStatusUrl,
  createPromoterUrl,
} from '@src/modules/account/config/api-utils'
import { discoveryDefaultView } from '@src/modules/publish/config/api-utils'
import { accountTypes } from './mutation-types'

const vueCookie = require('vue-cookie')
const getDefaultAccountState = () => {
  return {
    profile: {
      _id: null,
      firstname: null,
      lastname: null,
      username: null,
      email: null,
      phone_no: '',
      image: '',
      reff: '',
      role: 2,
      permissions: [],
      utmStatus: false,
      shortener: null,
      insights_unlocked: false,
      onBoarding: false,
      migration: false,
      isAdminLogin: false,
      planner_default_view: 'list',
      discovery_default_view: 'grid',
      planner_default_sort: { column: 'post_created_at', order: 'newest' },
      // The 'dayGridMonth' view is set as the default for the planner calendar to provide a monthly overview.
      planner_default_calendar_view: 'dayGridMonth',
      planner_default_notes_view: true,
      api_key: '',
      extended_trial_date: null,
      trial_overs_in: 14,
      need_to_reset_password: false,
      in_app_notifications: {
        upgrade_starter_limits: true,
      },
      '2fa_enabled': false,
      black_friday_banner_status: true,
      dashboard_banner_view_status: true,
      preferences: {
        composer_toolkit_view: true,
        default_landing_page: 'dashboard',
        fb_analytics_banner: true,
        analytics_data_deleted: false,
        bulk_uploader_view: true,
        show_inbox_sync_button_alert: true,
      },
    },
    // subscription: {
    //   display_name: '',
    //   limits: '',
    //   slug: ''
    // },
    loaders: {
      profile: false,
      subscription: false,
    },
    insights_unlocked: false,
    trialExpired: false,
    subscriptionExpired: false,
    fetchProfile: false,
    // fetchSubscription: false,
    appStatus: {},
    billing_details: {},
    payment_failed: [],
  }
}

export default {
  state: getDefaultAccountState(),
  actions: {
    async fetchBillingDetails({ commit, getters }) {
      await proxy
        .post(`${billingBaseUrl}paddle/user_history`, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data) {
            const response = res.data
            if (response.status) {
              const subscription = Object.assign(
                response.subscription ?? {},
                response.subscription?.paddle_details ?? {}
              )
              commit('SET_SUBSCRIPTION_DETAILS', subscription)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchBillingPaymentFailed({ commit, getters, dispatch }) {
      console.log(getters, dispatch)
      await proxy
        .post(`${accountBaseUrl}transactions/failed`, {
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
        })
        .then((res) => {
          if (res.data) {
            const response = res.data
            if (response.status) {
              commit('SET_PAYMENT_FAILED_DETAILS', response.transactions)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchAppStatus({ commit, getters, dispatch }) {
      console.log('method:fetchAppStatus Action...', getters, dispatch)

      return proxy
        .get(appStatusUrl)
        .then((resp) => {
          // set app status in state
          commit('SET_APP_STATUS', resp.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchProfile({ commit, getters, dispatch }) {
      console.log('method:fetchProfile Action...', getters)

      return proxy
        .post(fetchProfileUrl)
        .then((resp) => {
          // set social inbox beta access in cookie
          vueCookie.set(
            'has_social_inbox_beta',
            resp.data.profile.addons_lifetime,
            {
              expires: '1Y',
              domain: cookieDomain,
            }
          )

          const profileDetails = resp.data.profile

          AppAnalyticsMixin.methods.analyticsRegisterUser(
            profileDetails._id,
            profileDetails
          )

          const respAddOns = profileDetails.addons_lifetime
          if (respAddOns != null) {
            if (respAddOns.social_inbox_beta != null) {
              localStorage.setItem(
                'has_social_inbox_beta',
                respAddOns.social_inbox_beta
              )
            }
            if (respAddOns.social_inbox != null) {
              localStorage.setItem(
                'has_social_inbox_beta',
                respAddOns.social_inbox
              )
            }
          }

          const pusher = pusherAuthSocketInbox(
            profileDetails._id,
            profileDetails.full_name,
            profileDetails.email
          )
          const pusherSub = pusher.subscribe(
            'presence-logged-in-' + profileDetails._id
          )
          pusherSub.bind('active-user', (data) => {
            console.log('active user event received ...', data)
          })

          commit('SET_PROFILE', profileDetails)
        })
        .then(() => {
          // dispatch('fetchSubscription')
          // now billing details is dependent on workspace,
          // so we need to fetch it after workspaces are fetched
          // dispatch('fetchBillingDetails')
          // dispatch('fetchBillingPaymentFailed')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setDefaultView({ commit }, defaultView) {
      commit('SET_PLANNER_DEAFAULT_VIEW', defaultView)
    },
    setBlackFridayBannerStatus({ commit }, status) {
      commit('SET_BLACK_FRIDAY_BANNER_STATUS', status)
    },
    setDashboardBannerStatus({ commit }, status) {
      commit('SET_DASHBOARD_BANNER_VIEW_STATUS', status)
    },
    setComposerToolkitInfoStatus({ commit }, status) {
      commit('SET_COMPOSER_TOOLKIT_INFO_VIEW_STATUS', status)
    },
    setFacebookBannerViewStatus({ commit }, status) {
      commit('SET_FACEBOOK_BANNER_VIEW_STATUS', status)
    },
    setBulkUploaderViewStatus({ commit }, status) {
      commit('SET_BULK_UPLOADER_VIEW', status)
    },
    setShowInboxSyncButtonAlert({ commit }, status) {
      commit('SET_SHOW_INBOX_SYNC_BUTTON_ALERT', status)
    },
    async setDiscoveryDefaultView({ commit }, defaultView) {
      await proxy.post(discoveryDefaultView, {
        default_view: defaultView,
      })
      commit('SET_DISCOVERY_DEFAULT_VIEW', defaultView)
    },
    setDefaultSort({ commit }, defaultSort) {
      commit('SET_PLANNER_DEFAULT_SORT', defaultSort)
    },
    setDefaultCalendarView({ commit }, defaultCalendarView) {
      commit('SET_PLANNER_DEFAULT_CALENDAR_VIEW', defaultCalendarView)
    },
    setDefaultNotesView({ commit }, defaultNotesView) {
      commit('SET_PLANNER_DEFAULT_NOTES_VIEW', defaultNotesView)
    },
    async createPromoter({ commit, getters, dispatch }) {
      console.log('method:createPromoter', getters, dispatch)
      return proxy
        .post(createPromoterUrl)
        .then((resp) => {
          if (resp.data.status) commit('SET_PROFILE', resp.data.profile)
          return resp
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // fetchSubscription ({ commit, getters, actions }) {
    //   console.log('method:fetchSubscription Action...')
    //   proxy
    //     .post(fetchSubscriptionUrl, {})
    //     .then((resp) => {
    //       commit('SET_SUBSCRIPTION', resp.data.subscription)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    storeInAppNotification(context, payload) {
      return new Promise((resolve, reject) => {
        proxy
          .post(`${accountBaseUrl}notifications/in_app/store`, payload)
          .then((res) => {
            if (res.data.data) {
              context.commit(
                accountTypes.SET_IN_APP_NOTIFICATION,
                res.data.data.type_of_notification
              )
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    setIsAdminLogin({ commit }, status) {
      commit('setIsAdminLogin', status)
    },
  },
  mutations: {
    [accountTypes.SET_TRIAL_EXPIRED](state, value) {
      state.trialExpired = value
    },
    [accountTypes.SET_SUBSCRIPTION_EXPIRED](state, value) {
      state.subscriptionExpired = value
    },
    [accountTypes.SET_IN_APP_NOTIFICATION](state, value) {
      if (Object.hasOwn(state.profile.in_app_notifications, value)) {
        // value has been set to the false.

        state.profile.in_app_notifications[value] = false
      }
    },
    SET_SUBSCRIPTION_DETAILS(state, details) {
      state.billing_details = Object.assign(
        getDefaultAccountState().billing_details,
        details
      )
    },
    SET_PAYMENT_FAILED_DETAILS(state, details) {
      state.payment_failed = Object.assign(
        getDefaultAccountState().payment_failed,
        details
      )
    },
    SET_PROFILE(state, profile) {
      state.profile = Object.assign(getDefaultAccountState().profile, profile)
    },

    SET_PLANNER_DEAFAULT_VIEW(state, defaultView) {
      state.profile.planner_default_view = defaultView
    },
    SET_BLACK_FRIDAY_BANNER_STATUS(state, status) {
      state.profile.black_friday_banner_status = status
    },
    SET_DASHBOARD_BANNER_VIEW_STATUS(state, status) {
      state.profile.dashboard_banner_view_status = status
    },
    SET_COMPOSER_TOOLKIT_INFO_VIEW_STATUS(state, status) {
      state.profile.preferences.composer_toolkit_view = status
    },
    SET_FACEBOOK_BANNER_VIEW_STATUS(state, status) {
      state.profile.preferences.fb_analytics_banner = status
    },
    SET_DISCOVERY_DEFAULT_VIEW(state, defaultView) {
      state.profile.discovery_default_view = defaultView
    },
    SET_PLANNER_DEFAULT_SORT(state, defaultSort) {
      state.profile.planner_default_sort = defaultSort
    },
    SET_PLANNER_DEFAULT_CALENDAR_VIEW(state, defaultCalendarView) {
      state.profile.planner_default_calendar_view = defaultCalendarView
    },
    SET_BULK_UPLOADER_VIEW(state, status) {
      state.profile.preferences.bulk_uploader_view = status
    },
    SET_SHOW_INBOX_SYNC_BUTTON_ALERT(state, status) {
      state.profile.preferences.show_inbox_sync_button_alert = status
    },
    SET_PLANNER_DEFAULT_NOTES_VIEW(state, defaultNotesView) {
      state.profile.planner_default_notes_view = defaultNotesView
    },

    SET_APP_STATUS(state, status) {
      state.appStatus = Object.assign(
        getDefaultAccountState().appStatus,
        status
      )
    },

    // SET_SUBSCRIPTION (state, value) {
    //   state.subscription = value
    // },
    setIsAdminLogin(state, status) {
      state.profile.isAdminLogin = status
    },

    SET_DEVELOPERS_API_KEY(state, value) {
      state.profile.api_key = value
    },
  },
  getters: {
    getProfile: (state) => {
      return state.profile
    },
    getTrialExpired: (state) => {
      return state.trialExpired
    },
    getSubscriptionExpired: (state) => {
      return state.trialExpired
    },
    getInAppNotifications: (state) => {
      return state.profile.in_app_notifications
    },
    getAppStatus: (state) => {
      return state.appStatus
    },
    getBillingDetails: (state) => {
      return state.billing_details
    },
    getPaymentFailedDetails: (state) => {
      return state.payment_failed
    },
    getSortPreference: (state) => {
      return state.profile.planner_default_sort
    },
    getCalendarViewPreference: (state) => {
      return state.profile.planner_default_calendar_view
    },
    getCalendarNotesPreference: (state) => {
      return state.profile?.planner_default_notes_view ?? true
    },
    getDiscoveryView: (state) => {
      return state.profile.discovery_default_view
    },
  },
}
