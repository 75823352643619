<template>
  <FeatureAddOnModal
    :show-close-button="true"
    :title="'Inbox Add-On'"
    :description="'Unified Inbox Add-On: Manage messages and interactions from Facebook, Instagram, LinkedIn, and Google My Business all in one place. Easily assign team members, add labels, filter, and respond faster.'"
    :features="[
      'Manage all your messages and comments in one unified inbox.',
      'Assign messages and tasks to team members for better collaboration.',
      'Organize your inbox with labels, tags, and detailed notes for every interaction.',
    ]"
    feature-id="inbox"
    video-src="https://www.youtube.com/embed/lu2Z3s2kCAI?si=l_tYs07AuD5eUeMM"
    video-title="Social Inbox: Engage with Your Followers from One Platform"
  />
</template>

<script setup>
import FeatureAddOnModal from '@/src/components/common/FeatureAddOnModal.vue'

</script>
