import Pusher from 'pusher-js'
import { apiUrl } from '@src/config/api-utils'

Pusher.logToConsole = false

export const pusherSocket = new Pusher(process.env.VUE_APP_PUSHER_SOCKET_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
})

export const pusherSocketPublish = new Pusher(
  process.env.VUE_APP_PUSHER_PUBLISH,
  {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
  }
)

export const pusherAuthSocketInbox = (userId, fullName, email) => {
  return new Pusher(process.env.VUE_APP_PUSHER_INBOX_KEY, {
    encrypted: true,
    authTransport: 'ajax',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: apiUrl + 'pusher/auth',
    auth: {
      params: {
        user_id: userId,
        user_name: fullName,
        email,
      },
    },
  })
}

export const pusherAuthSocketAnalytics = () => {
  return new Pusher(process.env.VUE_APP_PUSHER_ANALYTICS_KEY, {
    encrypted: true,
    authTransport: 'ajax',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    auth: {
      params: {},
    },
  })
}

export const pusherAuthSocketCaptions = (userId, fullName, email) => {
  return new Pusher(process.env.VUE_APP_PUSHER_CAPTION_KEY, {
    encrypted: true,
    authTransport: 'ajax',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: apiUrl + 'pusher/auth',
    auth: {
      params: {
        user_id: userId,
        user_name: fullName,
        email,
        module: 'caption-generation',
      },
    },
  })
}

export const pusherInboxRevamp = () => {
  return new Pusher(process.env.VUE_APP_PUSHER_INBOX_REVAMP_KEY, {
    cluster: 'eu'
  })
}
