const ConversationView = () => import('@src/modules/inbox-revamp/views/ConversationView.vue')

export const inboxRevampRoutes = [
  {
    path: '/:workspace/inbox',
    redirect: to => `/${to.params.workspace}/inbox/all`
  },
  {
    path: '/:workspace/inbox/:filter',
    name: 'inbox-revamp',
    component: () => import('@src/modules/inbox-revamp/views/InboxView.vue'),
    meta: {
      title: 'Inbox | ContentStudio',
    },
    children: [
      {
        path: ':conversation',
        name: 'inbox-revamp-conversation',
        component: ConversationView,
        meta: {
          title: 'Conversation | ContentStudio'
        }
      }
    ]
  }
]
