<template>
  <div class="comment-block relative" :class="{ 'cursor-not-allowed opacity-50': localComment?.isCommentSending || localComment?.isDeleting || localComment?.is_hidden || localComment?.isHiding }">
    <!-- Vertical line for parent comments -->
    <div
      v-if="hasReplies || showReplyBox"
      class="absolute bottom-[70px] left-[35px] top-[59px] hidden w-[2px] bg-gray-200 sm:block"
    ></div>

    <!-- Corner elbow for replies -->
    <div
      v-if="isReply"
      class="absolute top-[-10px] left-[-18px] h-12 w-7 rounded-bl-3xl border-2 border-r-0 border-t-0 border-gray-200"
    ></div>

    <!-- Comment Card -->
    <div class="rounded bg-white p-3">
      <div class="flex items-start space-x-3">
        <img
          :src="getUserProfilePic()"
          :alt="userName"
          class="h-10 w-10 rounded-full object-cover"
          @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
        />
        <div class="flex-1">
          <template v-if="isEditBox">
            <MessageComposer
              ref="editMessageComposer"
              :placeholder="'Write a reply...'"
              :initial-message="localComment.message"
              :active-inbox-detail="activeInboxDetail"
              :allow-note="false"
              :mini-box="true"
              :skip-modal-render="true"
              :allow-attachment="allowAttachment"
              @send="$emit('edit', $event), toggleEditBox()"
            />
          </template>
          <template v-else>
            <div class="rounded-3xl bg-gray-100 p-3">
              <!-- Name & Time -->
              <div class="flex items-center justify-between">
                <h4 class="font-semibold text-sm">{{ userName }}</h4>
              </div>

              <!-- Comment Text -->
              <HtmlRenderer
                v-if="localComment.message?.length > 0"
                tag="p"
                class="mt-1 text-sm whitespace-pre-wrap"
                :html-content="parseDescriptionHtml(trimmedMessage, trimmedMessage?.length, true, post?.platform === 'linkedin')"
              />
              <template v-if="isContentLarge">
                <span class="cursor-pointer hover:underline text-cs-primary" @click="toggleReadMore">{{ !readMore ? 'Read more' : 'Read less' }}</span>
              </template>
              <template v-for="(attachment, index) in localComment?.attachment" :key="index">
                <template v-if="attachment?.type === 'video'">
                  <video :src="attachment?.video" controls class="mt-2 max-h-60 max-w-full rounded-lg"></video>
                </template>
                <template v-else-if="attachment?.type === 'animated_image_share' || attachment?.type === 'gif'">
                  <video autoplay loop :poster="attachment?.image" class="mt-2 max-h-40 max-w-full rounded-lg">
                    <source :src="attachment?.gif">
                  </video>
                </template>
                <template v-else>
                  <img :src="attachment?.gif || attachment?.image || attachment?.url || attachment?.sticker" alt="Attachment" class="mt-2 max-h-40 max-w-full rounded-lg" />
                </template>
              </template>
            </div>
          </template>

          <div class="mt-2 flex justify-between">
            <div class="flex items-center space-x-4 text-sm">
              <span v-if="!isEditBox" class="text-xs">{{ formatTime(localComment.created_time) }}</span>
              <button
                v-if="localComment?.can_like && !localComment?.is_hidden"
                class="hover:underline font-bold"
                :class="{
                  'text-cs-primary': localComment?.liked,
                  'cstu-text-gray-300': !localComment?.liked,

                 }"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="$emit('like', localComment)"
              >
                {{ localComment?.liked ? 'Liked' : 'Like' }}
              </button>
              <button
                v-if="localComment?.can_edit"
                class="hover:underline font-bold cstu-text-gray-300"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="toggleEditBox"
              >
                {{ isEditBox ? 'Cancel' : 'Edit' }}
              </button>
              <button
                v-if="depth < maxDepth && !localComment?.is_hidden"
                class="hover:underline font-bold cstu-text-gray-300"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="toggleReplyBox"
              >
                Reply
              </button>
              <button
                v-if="localComment?.can_remove && !isEditBox && !localComment?.is_hidden"
                class="hover:underline font-bold cstu-text-red-300"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="handleDeleteComment(localComment)"
              >
                Delete
              </button>
              <button
                v-if="localComment?.can_hide"
                class="hover:underline font-bold cstu-text-orange-300"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="$emit('hide', localComment)"
              >
                {{ localComment?.isHiding ? 'Hiding...' : localComment?.is_hidden ? 'Unhide' : 'Hide' }}
              </button>
              <button
                v-if="localComment?.can_reply_privately"
                class="hover:underline font-bold cstu-text-gray-300"
                :disabled="localComment?.isCommentSending || localComment?.isDeleting || localComment?.isHiding"
                @click="$emit('private-reply', localComment)"
              >
                Reply Privately
              </button>
              <SyncButton
                v-if="depth === 0 && !localComment?.is_hidden && allowSync"
                v-tooltip="`Sync Comment`"
                icon-class="!h-4 !w-4"
                custom-class="!px-0 !border-0"
                :loading="localComment?.isSyncing"
                @click="$emit('sync-comment', localComment)"
              />
            </div>
            <div class="flex items-center justify-between gap-3 text-sm">
              <span v-if="hasReplies" class="cursor-pointer hover:underline" @click="showReplyBox = !showReplyBox">{{ localComment.children.length }} Replies</span>
              <span v-if="localComment.like_count">
                {{ localComment.like_count }}
                <img v-if="isInstagram" :src="inboxInstaLike" alt="Like"/>
                <img v-else :src="inboxFbLike" alt="Like"/>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Replies Container -->
    <template v-if="showReplyBox">
      <div  class="ml-10 mt-2 space-y-2 sm:ml-14">
        <template v-if="hasReplies">
          <CommentBlock
            v-for="reply in localComment.children"
            :key="reply._id"
            :comment="reply"
            :is-reply="true"
            :active-inbox-detail="activeInboxDetail"
            :add-comment="addComment"
            :delete-comment="deleteComment"
            :allow-attachment="allowAttachment"
            :max-depth="maxDepth"
            :depth="depth + 1"
            :generate-unsent-comment="generateUnsentComment"
            @like="$emit('like', $event)"
            @delete="$emit('delete', $event)"
            @hide="$emit('hide', $event)"
            @edit="$emit('edit', $event)"
            @remove-child="handleRemoveChild"
            @private-reply="$emit('private-reply', $event)"
          />
        </template>
        <div class="relative">
          <div
          class="absolute top-[-10px] left-[-18px] h-12 w-7 rounded-bl-3xl border-2 border-r-0 border-t-0 border-gray-200"
        ></div>
          <div class="rounded bg-white p-3">
            <div class="flex items-start space-x-3">
              <img
                :src="activeInboxDetail?.inbox_details?.posted_by?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                :alt="activeInboxDetail?.inbox_details?.posted_by?.name"
                class="h-10 w-10 rounded-full object-cover"
                @error="$event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
              />
              <div class="flex-1">
                <MessageComposer
                  ref="replyMessageComposer"
                  :active-inbox-detail="activeInboxDetail"
                  :allow-note="false"
                  :mini-box="true"
                  :skip-modal-render="true"
                  :allow-attachment="allowAttachment"
                  :placeholder="'Write a reply...'"
                  :disabled="localComment?.isDeleting || localComment?.isHiding || localComment?.is_hidden"
                  @send="sendMessage($event, localComment)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch, nextTick } from 'vue'
import { parseDescriptionHtml } from '@common/lib/helper'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import MessageComposer from '@src/modules/inbox-revamp/components/MessageComposer.vue'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import SyncButton from '@src/modules/inbox-revamp/components/SyncButton.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'

const { getUTCFormattedTimeZoneDate } = useComposerHelper()
const { inboxFbLike, inboxInstaLike } = useIcons()

const props = defineProps({
  comment: {
    type: Object,
    required: true
  },
  isReply: {
    type: Boolean,
    default: false
  },
  activeInboxDetail: {
    type: Object,
    required: true
  },
  addComment: {
    type: Function,
    default: (data) => {}
  },
  generateUnsentComment: {
    type: Function,
    default: (message) => {}
  },
  allowAttachment: {
    type: Boolean,
    default: true
  },
  maxDepth: {
    type: Number,
    default: 2
  },
  depth: {
    type: Number,
    default: 0
  },
  deleteComment: {
    type: Function,
    default: (data) => {}
  },
  allowSync: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['like', 'reply', 'delete', 'hide', 'edit', 'remove-child', 'private-reply', 'sync-comment'])

const localComment = ref({ ...props.comment })
const editMessageComposer = ref(null)
const replyMessageComposer = ref(null)

// Watch for prop changes
watch(() => props.comment, (newVal) => {
  localComment.value = { ...newVal }
}, { deep: true })
watch(() => props.activeInboxDetail, (newVal, oldVal) => {
  if (newVal.element_details?.element_id !== oldVal.element_details?.element_id) {
    isEditBox.value = false
  }
})
const readMore = ref(false)
const showReplyBox = ref(false)
const isEditBox = ref(false)

const isInstagram = computed(() => {
  return localComment.value?.from?.[0]?.platform_type?.toLowerCase() === 'instagram'
})

const hasReplies = computed(() => {
  return localComment.value.children?.length > 0
})

const isContentLarge = computed(() => {
  return localComment.value.message?.length > 200
})

const trimmedMessage = computed(() => {
  if(readMore.value || !isContentLarge.value) return localComment.value.message
  return localComment.value.message?.substr(0, 200) + '...'
})

const userName = computed(() => {
  return `${localComment.value?.from?.[0]?.first_name} ${localComment.value?.from?.[0]?.last_name}`
})

const toggleReadMore = () => {
  readMore.value = !readMore.value
}

const formatTime = (time) => {
  return getUTCFormattedTimeZoneDate(time).fromNow(true)
}

const sendMessage = async(message, comment) => {
  const messageObj = props.generateUnsentComment(message)

  // Initialize children array if it doesn't exist
  if (!localComment.value.children) {
    localComment.value.children = []
  }

  // Add to local state
  localComment.value.children.push(messageObj)

  try {
    const res = await props.addComment({ message, comment, isReply: true })
    if (res) {
      replaceMessage(messageObj, res)
    }
    else{
      removeFailedMessage(messageObj)
    }
  } catch (error) {
    // Remove failed message
    removeFailedMessage(messageObj)
  }
}

const replaceMessage = (oldMessage, newMessage) => {
  const index = localComment.value.children.findIndex(comment => comment._id === oldMessage._id)
  if (index !== -1) {
    localComment.value.children[index] = newMessage
  }
}

const removeFailedMessage = (messageObj) => {
  const index = localComment.value.children.findIndex(c => c._id === messageObj._id)
  if (index !== -1) {
    localComment.value.children.splice(index, 1)
  }
}

const handleDeleteComment = async (comment) => {
  const res = await props.deleteComment({ comment, isReply: props.isReply })
  console.log('res',res)
  if (props.isReply && res.isValid) {
    emits('remove-child', comment)
  }
}

const getUserProfilePic = () => {
  return localComment.value?.from?.[0]?.profile_pic ||
         localComment.value?.from?.[0]?.image ||
         'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}

const handleRemoveChild = (comment) => {
  // find index of comment
  const index = localComment.value.children.findIndex(c => c._id === comment._id)
  if (index !== -1) {
    localComment.value.children.splice(index, 1)
  }
}

const toggleEditBox = () => {
  isEditBox.value = !isEditBox.value
  if(isEditBox.value) {
    nextTick(() => {
      editMessageComposer.value?.focusTextarea()
    })
  }
}

const toggleReplyBox = () => {
  showReplyBox.value = !showReplyBox.value
  if(showReplyBox.value) {
    nextTick(() => {
      replyMessageComposer.value?.focusTextarea()
    })
  }
}

</script>
