<template>
  <CstButton variant="text" size="small" class="group" @click="buttonClick">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9998 9.99996C15.9998 10.3681 15.7013 10.6666 15.3332 10.6666H6.27598L9.80457 14.1952C10.0649 14.4556 10.0649 14.8777 9.80457 15.1381C9.54422 15.3984 9.12212 15.3984 8.86177 15.1381L4.1951 10.4714C4.06509 10.3413 4 10.171 3.99984 10.0006V16.6666C3.99984 17.0348 3.70136 17.3333 3.33317 17.3333C2.96498 17.3333 2.6665 17.0348 2.6665 16.6666V3.33329C2.6665 2.96511 2.96498 2.66663 3.33317 2.66663C3.70136 2.66663 3.99984 2.96511 3.99984 3.33329V10.0006C4 9.83021 4.06509 9.65856 4.1951 9.52855L8.86177 4.86189C9.12212 4.60153 9.54422 4.60153 9.80457 4.86189C10.0649 5.12224 10.0649 5.54435 9.80457 5.80469L6.27598 9.33329H15.3332C15.7013 9.33329 15.9998 9.63177 15.9998 9.99996Z"
        class="fill-[#979CA0] group-hover:fill-[#4A4A4A]"
      />
    </svg>
  </CstButton>
</template>

<script setup>
import CstButton from '@/src/components/UI/Button/CstButton.vue'

const emit = defineEmits(['click'])

const buttonClick = () => {
  emit('click')
}
</script>
