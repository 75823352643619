<template>
  <div class="flex flex-col h-full">
    <ConversationHeader />
    <ChatView
      v-if="viewMode === 'conversation'"
      :chat="currentConversation"
      :platform-details="currentConversationPlatform"
    />
    <PostView
      v-else
      :post="currentConversation"
      :platform-details="currentConversationPlatform"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue';

import ChatView from '@src/modules/inbox-revamp/components/ChatView.vue';
import PostView from '@src/modules/inbox-revamp/components/PostView.vue';
import ConversationHeader from '@src/modules/inbox-revamp/components/ConversationHeader.vue';
import { useRouter } from 'vue-router';
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox';

const { currentConversation, conversationList, currentConversationPlatform, markAsRead, getReadStatus } = useInbox();

const router = useRouter();
const viewMode = computed(() => currentConversation.value?.inbox_type);

// Watch for changes in currentConversation to mark as read
watch(() => currentConversation.value, async (newConversation) => {
  if (newConversation && getReadStatus(newConversation) === 'unread') {
    await markAsRead(newConversation)
  }
}, { immediate: true })

onMounted(() => {
  const conversationId = router.currentRoute.value?.params?.conversation;
  if (conversationId && !currentConversation.value) {
    const matchedConversation = conversationList.value.conversations?.find(
      (conversation) => conversation._id === conversationId
    );
    if (matchedConversation) {
      currentConversation.value = matchedConversation;
    }
  }
});

</script>
