<template>
  <div
    :id="`message-${message?.message_id}`"
    class="flex gap-3 mt-2 group"
    :class="{
      'flex-row-reverse': isMessageMine,
      'opacity-50': message?.isMessageSending,
    }"
  >
    <template v-if="message?.error">
      <div class="h-[35px] flex items-center mt-1">
        <i v-tooltip="message?.errorMessage" class="fas fa-exclamation-triangle text-red-500"></i>
      </div>
    </template>
    <div v-tooltip="`${message?.from?.[0]?.first_name} ${message?.from?.[0]?.last_name}`" class="">
      <img
        :src="message?.from?.[0]?.profile_pic"
        alt=""
        class="w-[35px] h-[35px] rounded-full mt-1 object-cover"
        :class="{ 'float-right': isMessageMine }"
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
    </div>
    <div class="space-y-1 flex-1 group">
      <template v-if="hasStory">
        <span class="italic text-xs text-[#4A4A4A] ">Replied to your story</span>
      </template>
      <template v-if="message?.attachments?.length > 0">
        <template v-for="(attachment, index) in message?.attachments" :key="index">
          <template v-if="attachment?.type === 'file'">
            <a
              :href="attachment?.file_url"
              target="_blank"
              :class="[
                'inline-flex items-center gap-2 px-3 py-2 rounded-lg',
                'bg-white transition-colors duration-200',
                'text-sm text-gray-700 hover:text-gray-900',
                isMessageMine ? 'float-right' : '',
              ]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span class="truncate max-w-[200px]">{{ attachment?.name }}</span>
            </a>
          </template>
          <template v-else-if="attachment?.type === 'image'">
            <img
              :src="attachment?.image_url"
              alt=""
              class="rounded-3xl w-[250px] object-contain border-[#f3f1f1] block"
              :class="{ 'float-right': isMessageMine }"
              @error="handleImageError"
            >
          </template>
          <template v-else-if="attachment?.type === 'share' || attachment?.type === 'story'">
            <template v-if="isLinkVideo">
              <video
                :src="attachment?.link"
                controls
                class="rounded-3xl max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%] max-h-[300px] block"
                :class="{ 'float-right': isMessageMine }"
                @error="handleLinkVideoError"
              ></video>
            </template>
            <template v-else>
              <img
                :src="attachment?.link"
                alt=""
                class="rounded-3xl w-[250px] object-contain border-[#f3f1f1] block"
                :class="{ 'float-right': isMessageMine }"
                @error="handleImageError"
              >
            </template>
          </template>
          <template v-else-if="attachment?.type === 'video'">
            <video
              :src="attachment?.video_url"
              controls
              class="rounded-3xl max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%] max-h-[300px] block"
              :class="{ 'float-right': isMessageMine }"
            ></video>
          </template>
        </template>
      </template>
      <template v-if="!message?.attachments?.length || hasStory">
        <div
          id="message"
          class="inline-block rounded-3xl px-3.5 py-3 max-w-[80%] lg:max-w-[60%] 2xl:max-w-[40%]"
          :class="{
            'bg-white border-[#f3f1f1]': !isMessageMine,
            'cstu-bg-blue-500 text-white cstu-border-blue-500 float-right': isMessageMine,
          }"
        >
          <template v-if="message?.is_unsupported">
            <span class="text-sm whitespace-pre-wrap" :class="{ 'italic': message?.is_unsupported }">
              This message may contain content types such as voice notes, stickers, GIFs, etc., which are currently unsupported due to API limitations.
            </span>
          </template>
          <template v-else>
            <HtmlRenderer
              v-if="message?.message?.length > 0"
              tag="span"
              class="text-sm whitespace-pre-wrap"
              :html-content="parsedMessage"
            />
          </template>
        </div>
      </template>
      <div>
        <span
          class="text-opacity-50 cstu-text-gray-500 text-xs flex items-center gap-3 my-2 min-h-[17px] "
          :class="{ 'float-right clear-both flex-row-reverse': isMessageMine }"
        >
          {{ formattedTime }}
          <BookmarkButton :custom-class="`${message?.is_starred ? 'block' : 'hidden group-hover:block'}`" :is-bookmarked="message?.is_starred" @click="$emit('toggle-bookmark', $event)"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import { parseDescriptionHtml } from '@common/lib/helper'
import BookmarkButton from '@src/modules/inbox-revamp/components/BookmarkButton.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'

const { getUTCFormattedTimeZoneDate } = useComposerHelper()
const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  isMessageMine: {
    type: Boolean,
    required: true
  },
})

defineEmits(['toggle-bookmark'])

const isLinkVideo = ref(true)

const handleImageError = (event) => {
  event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')
}

const handleLinkVideoError = () => {
  isLinkVideo.value = false
}

const parsedMessage = computed(() => {
  const customTextClass = props.isMessageMine ? 'text-white' : 'text-primary-cs'
  return parseDescriptionHtml(props.message?.message, props.message?.message?.length, true, false, customTextClass)
})

const formattedTime = computed(() => {
  return getUTCFormattedTimeZoneDate(props.message?.created_time).formatTime()
})

const hasStory = computed(() => {
  return props.message?.attachments?.[0]?.type === 'story'
})
</script>
