<template>
  <div class="setting_miscellaneous_component">
    <div class="component_inner">
      <AddUtm></AddUtm>
      <AddHashTag></AddHashTag>
      <create-publication-folder></create-publication-folder>
      <RemovePublicationFolderConfirmation></RemovePublicationFolderConfirmation>
      <RemoveLabelConfirmation :remove-label="removeLabel"></RemoveLabelConfirmation>
      <CreateTaglModal
        :id="selectedTags.length > 0 ? selectedTags[0]['_id'] : null"
        :action="action"
        :selected-tags="selectedTags"
        @create-tag="addToExistingTags"
      ></CreateTaglModal>

      <SaveLabelModal :edit-label="editLabel" />

      <div class="ps_box">
        <div class="box_heading">
          <h2>UTM Tracking</h2>
          <p
            >With UTM parameters attached to your links, you will have the
            ability to track your campaign’s performance in Google Analytics.
            This will also allow you to see how much traffic are you receiving
            from the links you shared on different social channels.</p
          >
        </div>
        <div class="box_content">
          <div class="top_text flex justify-between items-start">
            <div class="d-flex align-items-start">
              <label class="switch-radio">
                <input
                  v-model="getWorkspaces.activeWorkspace.utmStatus"
                  type="checkbox"
                  :checked="getWorkspaces.activeWorkspace.utmStatus"
                  @click="autoAttachUtm"
                />
                <div class="slider round" data-cy="automatic-attach"></div>
              </label>

              <div
                class="text"
                :class="{ gray_text: !getWorkspaces.activeWorkspace.utmStatus }"
              >
                <h3
                  >Automatically attach default UTM parameter with shared
                  links</h3
                >
                <p
                  >You can select default UTM parameters from the list below.</p
                >
              </div>
            </div>
            <div class="flex justify-between items-center">
              <div class="search-bar-input">
                <div class="search-bar-inner">
                  <input
                    v-model="getUtms.search"
                    type="text"
                    placeholder="Search by campaign..."
                  />
                  <button class="search_btn">
                    <i class="icon-Search"></i>
                  </button>
                </div>
              </div>
              <button
                data-cy="add-utm"
                class="btn btn-studio-theme-space py-1.5 ml-4"
                @click="addUtm"
                ><span>Add New</span></button
              >
            </div>
          </div>
          <div class="w-full rounded-xl px-0 pb-2 pt-4">
            <table class="w-full py-2">
              <thead class="focus:outline-none h-16 bg-gray-50">
                <tr>
                  <th class="pl-3 text-left">Set As Default</th>
                  <th class="text-center">Campaign Name </th>
                  <th class="text-center">Campaign Medium </th>
                  <th class="text-center">Campaign Source</th>
                  <th class="text-center">Campaign Content </th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody v-if="getUtms.loader">
                <td colspan="4" class="text-center">
                  <clip-loader color="#4165ed" :size="'12px'"></clip-loader>
                </td>
              </tbody>

              <tbody v-else-if="getUtms.search && filteredUtms.length > 0">
                <tr
                  v-for="(utm, idx) in filteredUtms"
                  :key="`umt_${idx}`"
                  style="border-top: 1px solid #f1f1f1"
                  class="focus:outline-none h-16"
                >
                  <td class="pl-3">
                    <label class="switch-radio">
                      <input
                        name="option2"
                        :checked="utm.active"
                        type="radio"
                        @click="changeUtmStatus(utm)"
                      />
                      <div class="slider round"></div>
                    </label>
                  </td>
                  <td class="text-center">{{ utm.name }}</td>
                  <td class="text-center">{{ utm.medium }}</td>
                  <td class="text-center">
                    <span v-if="utm.source">
                      {{ utm.source }}
                    </span>
                    <span v-else style="font-style: italic"> automatic </span>
                  </td>

                  <td class="text-center">
                    <template v-if="utm.content"> {{ utm.content }}</template>
                  </td>

                  <td class="text-center">
                    <div class="action_icons">
                      <i
                        v-tooltip.top="'Remove'"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        @click="
                          confirmAction('removeUtm', {
                            utm: utm._id,
                            workspace_id: utm.workspace_id,
                          })
                        "
                      >
                      </i>
                      <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        @click="editUtm(utm)"
                      ></i>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="getUtms.fetchUtms && !getUtms.search">
                <tr
                  style="border-top: 1px solid #f1f1f1"
                  class="focus:outline-none h-16"
                >
                  <td class="pl-3">
                    <label class="switch-radio">
                      <input
                        name="option2"
                        :checked="isDefaultUtmActive"
                        type="radio"
                        @click="
                          changeUtmStatus({
                            _id: 'ContentStudio',
                            workspace_id: getActiveWorkspace._id,
                          })
                        "
                      />
                      <div class="slider round"></div>
                    </label>
                  </td>
                  <td class="text-center">
                    <span>contentstudio.io</span>
                  </td>
                  <td class="text-center">referral</td>
                  <td class="text-center">contentstudio</td>

                  <td class="text-center">
                    <span></span>
                  </td>
                  <td class="text-center"></td>
                </tr>
                <tr
                  v-for="(utm, i) in getUtms.items"
                  :key="`umt_list_${i}`"
                  style="border-top: 1px solid #f1f1f1"
                  class="focus:outline-none h-16"
                >
                  <td class="pl-3">
                    <label class="switch-radio">
                      <input
                        name="option2"
                        :checked="utm.active"
                        type="radio"
                        @click="changeUtmStatus(utm)"
                      />
                      <div class="slider round" data-cy="utm-set-default"></div>
                    </label>
                  </td>
                  <td class="text-center">{{ utm.name }}</td>
                  <td class="text-center">{{ utm.medium }}</td>
                  <td class="text-center">
                    <span v-if="utm.source">
                      {{ utm.source }}
                    </span>
                    <span v-else style="font-style: italic"> automatic </span>
                  </td>
                  <td class="text-center">
                    <template v-if="utm.content"> {{ utm.content }} </template>
                  </td>

                  <td class="text-center">
                    <div class="action_icons">
                      <i
                        v-tooltip.top="'Remove'"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        data-cy="delete-utm"
                        @click="
                          confirmAction('removeUtm', {
                            utm: utm._id,
                            workspace_id: utm.workspace_id,
                          })
                        "
                      >
                      </i>
                      <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        data-cy="edit-utm"
                        @click="editUtm(utm)"
                      ></i>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="text-center">
                    <p v-if="getUtms.search" class="no_data_padding"
                      >No UTMs found for your search query, please try again</p
                    >
                    <p v-else class="no_data_padding"
                      >You don't have any Utms</p
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="ps_box">
        <div class="box_heading flex justify-between items-end">
          <div class="max-w-[63%]">
            <h2>Saved Hashtags</h2>
            <p
              >You can save a set of hashtags that you use most often and then
              use it quickly at the time of composing your posts to save
              time.</p
            >
          </div>
          <div class="flex justify-between items-center">
            <div class="search-bar-input">
              <div class="search-bar-inner">
                <input
                  v-model="getWorkspaceHashtags.search"
                  type="text"
                  placeholder="Search by hashtag..."
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
            <button
              class="btn btn-studio-theme-space py-1.5 ml-4"
              data-cy="add-new-hashtag"
              @click.prevent="addHashtagModal"
            >
              <span>Add New</span>
            </button>
          </div>
        </div>
        <div class="box_content">
          <div class="w-full rounded-xl py-2">
            <table class="w-full p-2">
              <thead class="focus:outline-none h-16 bg-gray-50">
                <tr>
                  <th class="pl-3 text-left w-1/6">Name</th>
                  <th class="text-center">Hashtags</th>
                  <th class="text-center w-[10.6%]">Actions</th>
                </tr>
              </thead>
              <tbody v-if="getWorkspaceHashtags.loader">
                <td colspan="4" class="text-center">
                  <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
                </td>
              </tbody>
              <tbody
                v-else-if="
                  getWorkspaceHashtags.search && filteredTags.length > 0
                "
              >
                <tr
                  v-for="(tag, idx) in filteredTags"
                  :key="`hashtags_${idx}`"
                  style="border-top: 1px solid #f1f1f1"
                  class="focus:outline-none h-16"
                >
                  <td class="pl-3 text-truncate">{{ tag.name }}</td>
                  <td class="text-left pt-2">
                    <div class="normal_tags">
                      <ul>
                        <li
                          v-for="(item, itemIdx) in tag.items"
                          :key="`hashtag_item_${itemIdx}`"
                        >
                          <span class="value">{{ item }}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="action_icons">
                      <i
                        v-tooltip.top="'Remove'"
                        :disabled="getWorkspaceHashtags.loader"
                        :class="{ disabled: getWorkspaceHashtags.loader }"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        @click.prevent="
                          confirmAction('removeHashtag', {
                            tag: tag._id,
                            workspace_id: tag.workspace_id,
                          })
                        "
                      >
                      </i>
                      <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        @click.prevent="editHashtagModal(tag)"
                      >
                      </i>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  getWorkspaceHashtags.items &&
                  getWorkspaceHashtags.items.length > 0 &&
                  getWorkspaceHashtags.search === ''
                "
              >
                <tr
                  v-for="(tag, workspaceIdx) in getWorkspaceHashtags.items"
                  :key="`workspace_hashtags_${workspaceIdx}`"
                  style="border-top: 1px solid #f1f1f1"
                  class="focus:outline-none h-16"
                >
                  <td class="pl-3">{{ tag.name }}</td>
                  <td class="text-left pt-2">
                    <div class="normal_tags">
                      <ul>
                        <li
                          v-for="(item, tagIdx) in tag.items"
                          :key="`tag_index_${tagIdx}`"
                        >
                          <span class="value">{{ item }}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="action_icons">
                      <i
                        v-tooltip.top="'Remove'"
                        :disabled="getWorkspaceHashtags.loader"
                        :class="{ disabled: getWorkspaceHashtags.loader }"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        data-cy="delete-hashtag"
                        @click.prevent="
                          confirmAction('removeHashtag', {
                            tag: tag._id,
                            workspace_id: tag.workspace_id,
                          })
                        "
                      >
                      </i>
                      <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        data-cy="edit-hashtag"
                        @click.prevent="editHashtagModal(tag)"
                      >
                      </i>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="text-center">
                    <p
                      v-if="getWorkspaceHashtags.search"
                      class="no_data_padding"
                      >We can't find any saved hashtags for your search query,
                      please try again</p
                    >
                    <p v-else class="no_data_padding"
                      >You don't have any saved hashtags</p
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Campaigns -->
      <div id="campaigns" class="ps_box">
        <div class="box_heading flex justify-between items-end">
          <h2>Campaigns</h2>
          <div class="flex justify-between items-center">
            <div class="search-bar-input">
              <div class="search-bar-inner">
                <input
                  v-model="campaignSearch"
                  type="text"
                  placeholder="Search by name..."
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
            <button
              class="btn btn-studio-theme-space py-1.5 ml-4"
              @click.prevent="addComposerCampaignModal"
            >
              <span>Add New</span>
            </button>
          </div>
        </div>
        <div class="box_content">
          <div class="w-full rounded-xl px-0 py-2">
            <table class="w-full p-2">
              <tbody v-if="getPublicationFolderFetchLoader">
              <td colspan="4" class="text-center">
                <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
              </td>
              </tbody>
              <tbody
                  v-else-if="campaignSearch"
                  class="grid md:grid-cols-4 md:gap-4 sm:grid-cols-2 sm:gap-2"
              >
              <template v-if="filteredCampaigns.length > 0">
                <tr
                    v-for="(campaign, filteredIdx) in filteredCampaigns"
                    :key="`filtered_compile_${filteredIdx}`"
                    style="border: 1px solid #f1f1f1"
                    class="focus:outline-none h-16 flex justify-between items-center pr-2 rounded-sm"
                >
                  <td
                      v-tooltip="`${campaign.name}`"
                      class="ml-2 text-center text-truncate xl:max-w-[58.6%] sm:max-w-[50%]"
                  >
                    <p v-if="campaign.default" class="truncate ">
                      {{ campaign.name }}
                    </p>
                    <p
v-else
                       class="
                  px-3
                  py-1.5
                  rounded-md
                  text-sm
                  flex
                  items-center
                  gap-1
                  text-white
                "
                       :class="`${campaign.color}`"
                       :style="getCampaignColor(campaign.color)"
                    >
                      {{ campaign.name }}
                    </p>
                  </td
                  >
                  <td class="flex items-center justify-center">
          <span
              v-if="campaign.default"
              class="rounded-md bg-gray-100 text-gray-700 px-2 py-0.5 mr-2.5"
          >Default</span
          >
                    <div class="action_icons">
                      <i
                          v-if="!campaign.default"
                          v-tooltip.top="'Remove'"
                          :disabled="getPublicationFolderFetchLoader"
                          :class="{ disabled: getPublicationFolderFetchLoader }"
                          class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                          @click.prevent="
                confirmAction(
                  'removePublicationFolder',
                  campaign._id,
                )
              "
                      >
                      </i>
                      <i
                          v-tooltip.top="'Edit'"
                          class="action_icon sm icon-edit-cs"
                          @click.prevent="
                editCampaign(campaign, campaign.default)
              "
                      >
                      </i>
                    </div>
                  </td>
                </tr>
              </template>

              </tbody>
              <tbody
                  v-if="campaignSearch && filteredCampaigns.length === 0"
              >
              <tr>
                <td colspan="4" class="text-center">
                  <p class="no_data_padding">
                    We can't find any campaign for your search query, please
                    try again
                  </p>
                </td>
              </tr>
              </tbody>
              <tbody v-else-if="!campaignSearch" class="grid grid-cols-4 gap-4">
              <tr
                  v-for="(campaign, compaignIdx) in getPublicationFolders"
                  :key="`campaign_index_${compaignIdx}`"
                  style="border: 1px solid #f1f1f1"
                  class="focus:outline-none h-16 flex justify-between items-center pr-2 rounded-sm"
              >
                <td
                    v-tooltip="`${campaign.name}`"
                    class="text-left pl-3 text-truncate xl:max-w-[66%] lg:max-w-[50%]"
                >
                  <p v-if="campaign.default">
                    {{ campaign.name?.length > 20 ? campaign.name.substring(0, 20) + '...' : campaign.name }}
                  </p>
                  <p
                      v-else
                      class="
                  px-3
                  py-1.5
                  rounded-md
                  text-sm
                  flex
                  items-center
                  gap-1
                  text-white
                "
                      :class="`${campaign.color}`"
                      :style="getCampaignColor(campaign.color)"
                  >
                    {{  campaign.name?.length > 20 ? campaign.name.substring(0, 20) + '...' : campaign.name }}
                  </p>
                </td
                >
                <td class="text-center">
                  <div class="action_icons">
          <span
              v-if="campaign.default"
              class="rounded-md bg-gray-100 text-gray-700 px-2 py-0.5 mr-2"
          >Default</span
          >
                    <i
                        v-else
                        v-tooltip.top="'Remove'"
                        :disabled="getPublicationFolderFetchLoader"
                        :class="{ disabled: getPublicationFolderFetchLoader }"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        @click.prevent="
              confirmAction('removePublicationFolder', campaign._id)
            "
                    >
                    </i>
                    <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        @click.prevent="editCampaign(campaign)"
                    >
                    </i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--  Labels -->
      <div id="labels" class="ps_box">
        <div class="box_heading flex justify-between items-end">
          <h2>Labels</h2>
          <div class="flex justify-between items-center">
            <div class="search-bar-input">
              <div class="search-bar-inner">
                <input
                    v-model="labelSearch"
                    type="text"
                    placeholder="Search by name..."
                />
                <button class="search_btn">
                  <i class="icon-Search"></i>
                </button>
              </div>
            </div>
            <button
                class="btn btn-studio-theme-space py-1.5 ml-4"
                @click.prevent="SaveLabelModal"
            >
              <span>Add New</span>
            </button>
          </div>
        </div>
        <div class="box_content">
          <div class="w-full rounded-xl px-0 py-2">
            <table class="w-full p-2">
              <tbody v-if="false">
              <td colspan="4" class="text-center">
                <clip-loader color="#4165ed" :size="'16px'"></clip-loader>
              </td>
              </tbody>
              <tbody
                  v-else-if="labelSearch"
                  class="grid md:grid-cols-4 md:gap-4 sm:grid-cols-2 sm:gap-2"
              >
              <template v-if="filteredLabels.length > 0">
                <tr
                    v-for="(label, filteredIdx) in filteredLabels"
                    :key="`filtered_compile_${filteredIdx}`"
                    style="border: 1px solid #f1f1f1"
                    class="focus:outline-none h-16 flex justify-between items-center pr-2 rounded-sm"
                >
                  <td
                      v-tooltip="`${label.name}`"
                      class="ml-2 text-center text-truncate xl:max-w-[58.6%] sm:max-w-[50%]"
                  >
                    <p
                        class="px-3 py-1.5 rounded-md text-sm flex items-center gap-1 text-ellipsis"
                        :style="getLabelColor(label.color)"
                    >
                      {{ label.name?.length > 20 ? label.name.substring(0, 20) + '...' : label.name }}
                    </p>
                  </td>
                  <td class="flex items-center justify-center">
                    <div class="action_icons">
                      <i
                          v-tooltip.top="'Remove'"
                          class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                          @click.prevent="confirmAction('removeLabel', label)"
                      >
                      </i>
                      <i
                          v-tooltip.top="'Edit'"
                          class="action_icon sm icon-edit-cs"
                          @click.prevent="SaveLabelModal(label)"
                      >
                      </i>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
              <tbody v-if="labelSearch && filteredLabels.length === 0">
              <tr>
                <td colspan="4" class="text-center">
                  <p class="no_data_padding">
                    We can't find any label for your search query, please
                    try again
                  </p>
                </td>
              </tr>
              </tbody>
              <tbody v-else-if="!labelSearch" class="grid grid-cols-4 gap-4">
              <tr
                  v-for="(label, Idx) in getLabels"
                  :key="`label_index_${Idx}`"
                  style="border: 1px solid #f1f1f1"
                  class="focus:outline-none h-16 flex justify-between items-center pr-2 rounded-sm"
              >
                <td
                    v-tooltip="`${label.name}`"
                    class="text-left pl-3 text-truncate xl:max-w-[66%] lg:max-w-[50%]"
                >
                  <p
                      class="px-3 py-1.5 rounded-md text-sm flex items-center gap-1"
                      :style="getLabelColor(label.color)"
                  >
                    {{ label.name?.length > 20 ? label.name.substring(0, 20) + '...' : label.name }}
                  </p>
                </td>
                <td class="text-center">
                  <div class="action_icons">
                    <i
                        v-tooltip.top="'Remove'"
                        class="action_icon sm far fa-trash-alt fa-lg text-red-500 !shadow-none border rounded-md hover:!border-gray-900"
                        @click.prevent="confirmAction('removeLabel', label)"
                    >
                    </i>
                    <i
                        v-tooltip.top="'Edit'"
                        class="action_icon sm icon-edit-cs"
                        @click.prevent="SaveLabelModal(label)"
                    >
                    </i>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <!--v-if="getActiveWorkspaceAddonsLifetime.social_inbox_beta || getActiveWorkspaceAddonsLifetime.social_inbox || isAnalyticsPlanAllowed"-->
      <div id="tags" class="ps_box setting_tag_block">
        <div class="box_heading d-flex align-items-center">
          <div class="w-full flex justify-between head_left w-100">
            <div class="max-w-[70%]">
              <h2>Tags</h2>
              <p
                >Assign your conversations/posts in Inbox using Tags. Tags allow
                you to segregate your conversations/posts in a better way. e.g
                Product feedback, bug report, sales query.</p
              >
            </div>
            <div class="flex justify-between items-end">
              <div class="head_right ml-auto">
                <div class="tag_option">
                  <div class="inner">
                    <!--v-if="getTagSelection.length > 0"-->
                    <ul v-click-away="closeDropdown" class="bottom_bar">
                      <!--v-if="getTagSelection.length == 1"-->
                      <li v-if="selectedTags.length === 1" class="more_option">
                        <div
                          v-tooltip.top="'Edit'"
                          class="content"
                          @click="showTagModal('update')"
                        >
                          <i class="option_icon fal fa-tag"></i>
                        </div>
                        <transition name="fade-list-item">
                          <div v-if="showDropdownStatus" class="marge_tooltip">
                            <div class="inner_box">
                              <div class="editor_input">
                                <input
                                  placeholder="Enter new name..."
                                  type="text"
                                />
                                <button class="btn">
                                  <i class="fal fa-check"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </transition>
                      </li>
                      <li v-if="selectedTags.length > 1" class="more_option">
                        <div
                          v-tooltip.top="'Merge'"
                          class="content"
                          @click="showTagModal('merge')"
                        >
                          <i class="option_icon fal fa-code-fork"></i>
                        </div>
                        <transition name="fade-list-item">
                          <div v-if="showDropdownStatus" class="marge_tooltip">
                            <div class="inner_box">
                              <div class="editor_input">
                                <input
                                  placeholder="Enter new name..."
                                  type="text"
                                />
                                <button class="btn">
                                  <i class="fal fa-check"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </transition>
                      </li>
                      <li v-if="selectedTags.length >= 1" class="remove_option">
                        <div
                          v-tooltip.top="'Delete'"
                          class="content"
                          @click="tagDelete"
                        >
                          <i
                            class="far fa-trash-alt fa-lg text-red-500 rounded-md hover:!border-gray-900"
                          ></i>
                        </div>

                        <b-modal id="tagDeleteConfirm" hide-header hide-footer>
                          <div class="modal_head">
                            <h4>Delete Confirmation</h4>
                            <button
                              class="modal_head__close"
                              type="button"
                              @click="$bvModal.hide('tagDeleteConfirm')"
                              >&times;</button
                            >
                          </div>
                          <div class="modal_body mt-3 mb-3">
                            <p>Are you sure you want to delete this Tag</p>
                          </div>

                          <div class="field_group m-3">
                            <button
                              type="submit"
                              class="btn btn-studio-theme-red mr-1"
                              @click="deleteTags"
                              >Delete</button
                            >
                            <button
                              type="submit"
                              class="btn btn-studio-theme-grey-space"
                              @click="$bvModal.hide('tagDeleteConfirm')"
                              >Cancel</button
                            >
                          </div>
                        </b-modal>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-studio-theme-space py-1.5 ml-4"
                @click="showTagModal('create')"
              >
                <span>Add New</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box_content">
          <div class="setting_tag_list !mt-0 !pb-4">
            <div class="tag_list_new filter_label old_label_list">
              <template v-for="(tag, tagListIdx) in tags" :key="`tag_list_index_${tagListIdx}`">
                <div
                  class="checkbox_input_image selecttag"
                >
                  <input
                    :id="tag._id + 'create'"
                    v-model="selectedTags"
                    type="checkbox"
                    :value="tag"
                  />
                  <label
                    :for="tag._id + 'create'"
                    class="checkbox_left tag-selectable"
                    style="position: relative"
                  >
                    <i class="check-icon fas fa-check"></i>
                    <span
                      v-tooltip="tag.tag_name"
                      class="mb-1 mr-1 label_tag_round_dark truncate_label"
                      :class="[tag.tag_color]"
                    >
                      {{ tag.tag_name }}
                    </span>
                  </label>
                </div>
              </template>
            </div>
          </div>
          <!--<li class="" v-for="(tag,index) in getTagList" @click.prevent="selectTagForAction(index)"-->
          <!--:class="{'active' :  getTagList[index].status}">-->
          <!--<span class="d-flex align-items-center">-->
          <!--<span class="d-flex align-items-center justify-content-center number">34</span>-->
          <!--<span class="text">{{tag.name}}</span>-->
          <!--</span>-->
          <!--</li>-->
        </div>
      </div>
    </div>
    <remove-utm></remove-utm>
    <remove-tag></remove-tag>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as _ from 'underscore'
import $ from 'jquery'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { EventBus } from '@common/lib/event-bus'
import useLabelAndCampaign
  from "@modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign";
import RemoveLabelConfirmation from "@modules/composer/components/RemoveLabelConfirmation.vue";
import CreateTaglModal from './CreateTaglModal'

const AddUtm = () => import('./AddUtm')
const AddHashTag = () => import('./AddHashTag')
const removeUtm = () => import('./dialogs/UtmRemoveConfirmation.vue')
const removeTag = () => import('./dialogs/HashtagRemoveConfirmation.vue')
const createPublicationFolder = () =>
  import('./../../composer/components/CreatePublicationFolder')
const SaveLabelModal = () => import('./../../composer/components/SaveLabelModal.vue')
const RemovePublicationFolderConfirmation = () =>
  import('./../../composer/components/RemovePublicationFolderConfirmation')
export default {
  components: {
    CreateTaglModal,
    AddUtm,
    AddHashTag,
    removeUtm,
    removeTag,
    createPublicationFolder,
    RemovePublicationFolderConfirmation,
    SaveLabelModal,
    RemoveLabelConfirmation
  },
  setup(){
    const { LABELS_COLOR_MAP, hexToRGBA, getLabelColor, getCampaignColor } = useLabelAndCampaign()
    return {
      LABELS_COLOR_MAP,
      hexToRGBA,
      getLabelColor,
      getCampaignColor
    }
  },
  data() {
    return {
      getTagSelection: [],
      showDropdownStatus: false,
      tags: [],
      selectedTags: [],
      action: 'create',
      campaignSearch: '',
      labelSearch: '',
      editLabel: null,
    }
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getWorkspaceHashtags',
      'getUtmsStatus',
      'getUtms',
      'getCurrentUtms',
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'isAnalyticsPlanAllowed',
      'getPublicationFolderFetchLoader',
      'getPublicationFolders',
      'getLabels'
    ]),
    isDefaultUtmActive() {
      let status = true

      this.getUtms.items.forEach(function (item) {
        if (item.active === 1 || item.active === true) {
          status = false
          return false
        }
      })

      return status
    },
    filteredCampaigns() {
      const campaigns = []
      const stateObject = this

      const folders = JSON.parse(JSON.stringify(this.getPublicationFolders))

      if (this.campaignSearch && folders.length > 0) {
        folders.filter((campaign) => {
          if (
            campaign.name
              .toLowerCase()
              .indexOf(stateObject.campaignSearch.toLowerCase()) >= 0
          ) {
            campaigns.push(campaign)
          }
        })
      } else {
        return campaigns.push(folders)
      }

      return campaigns
    },
    filteredLabels() {
      const labels = []
      const stateObject = this

      const folders = JSON.parse(JSON.stringify(this.getLabels))

      if (this.labelSearch && folders.length > 0) {
        folders.filter((campaign) => {
          if (
            campaign.name
              .toLowerCase()
              .indexOf(stateObject.labelSearch.toLowerCase()) >= 0
          ) {
            labels.push(campaign)
          }
        })
      } else {
        return labels.push(folders)
      }

      return labels
    },
  },
  created() {
    this.initializeMiscellaneousSection()
  },
  mounted() {
    this.fetchTags()
    if (this.getPublicationFolderStatus === false) {
      this.fetchPublicationFolders()
      this.$store.commit(composer.SET_PUBLICATION_FOLDER_STATUS, true)
    }

    this.$nextTick(() => {
      setTimeout(() => {
        console.log('Miscellanceous this.$route.hash', this.$route.hash)
        if (this.$route.hash) {
          const hash = this.$route.hash

          const element = document.querySelector(`#${hash}`)
          console.log('Miscellanceous element', element)

          if (element) {
            const topPos = element.getBoundingClientRect().top - 100

            window.scrollTo({
              top: topPos,
              behavior: 'smooth',
            })
          }
        }
      }, 500)
    })
  },

  methods: {
    ...mapMutations([
      'SET_NEW_WORKSPACE_HASHTAG',
      'RESET_NEW_WORKSPACE_HASHTAG',
    ]),
    ...mapActions([
      'fetchWorkspaceTags',
      'deleteTagsFromWorkspace',
      'getWorkspaces',
      'getPublicationFolderStatus',
        'setConfirmActionStage'
    ]),
    changeUtmStatus(utm) {
      this.editUtmStatus(utm)
    },
    closeDropdown() {
      this.showDropdownStatus = false
    },
    showDropdown() {
      this.showDropdownStatus = true
    },
    addToExistingTags(payload) {
      if (this.action === 'merge') {
        this.tags.push(payload.tag?.tag)
        this.removeFromList(_.pluck(this.selectedTags, '_id'))
      } else if (this.action === 'update') {
        const index = this.findTagIndex(payload.tag._id)
        this.$set(this.tags, index, payload.tag)
      } else {
        this.tags.push(payload.tag)
      }

      this.selectedTags = []
    },
    async fetchTags() {
      const response = await this.fetchWorkspaceTags({
        workspace_id: this.getActiveWorkspace._id,
      })
      console.log('i am response')
      console.log(response)
      if (response.isValid) {
        this.tags = _.sortBy(response.tags, 'tag_name')
      } else {
        this.tags = []
        // commonMethods.showToast(this, 3, response.message)
      }
    },
    showTagModal(action) {
      this.action = action
      this.$bvModal.show('createTagModalSetting')
    },
    findTagIndex(tagId) {
      return this.tags.findIndex((x) => x._id === tagId)
    },
    removeFromList(selectedTags) {
      const temp = this
      selectedTags.forEach(function (value, i) {
        const index = temp.findTagIndex(value)
        if (index >= 0) {
          temp.$delete(temp.tags, index)
        }
      })
      this.selectedTags = []
    },

    async deleteTags() {
      const selectedTags = _.pluck(this.selectedTags, '_id')
      const response = await this.deleteTagsFromWorkspace({
        tag_ids: selectedTags,
      })
      if (response.isValid) {
        this.removeFromList(selectedTags)
      } else {
        // commonMethods.showToast(this, 3, response.message)
      }
    },
    addUtm() {
      this.setCurrentUtm([])
      // $('#add_utm_modal').modal('show')
      //   console.debug('this',this)
      //   console.debug('$bvmodal',$bvmodal)
      this.$bvModal.show('add_utm_modal')
    },
    editUtm(tag) {
      const currentUtm = {}
      for (const key in tag) {
        if (key in tag) {
          currentUtm[key] = tag[key]
        }
      }
      this.setCurrentUtm(currentUtm)
      this.$bvModal.show('add_utm_modal')

      // setTimeout(function () {
      //  // this.$bvModal.show('add_utm_modal')
      // }, 200)
    },
    removeUtm(id, workspaceId) {
      if (!this.getUtms.loader) {
        this.deleteUtm(id, workspaceId)
      }
    },

    addHashtagModal() {
      this.RESET_NEW_WORKSPACE_HASHTAG()
      this.$bvModal.show('add_hashtag_modal')
    },
    editHashtagModal(tag) {
      this.SET_NEW_WORKSPACE_HASHTAG(JSON.parse(JSON.stringify(tag)))
      this.$bvModal.show('add_hashtag_modal')
    },
    removeHashTag(id, workspaceId) {
      this.removeTag(id, workspaceId)
    },

    addComposerCampaignModal() {
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID, '')
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, '')
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_COLOR, '')
      EventBus.$emit('setEditFolderName')
      $('#createFolder').modal('show')
    },
    SaveLabelModal(label = null) {
      this.editLabel = label?._id ? label : null;
      $('#add-label-modal').modal('show');
    },
    removeLabel(confirmation, data) {
      if (confirmation?.status) {
        this.$store.dispatch('deleteLabel', data);
        this.setConfirmActionStage('success');
      }

      this.closeConfirmAction(confirmation.type)
    },
    editCampaign(campaign) {
      console.log(campaign)
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_ID, campaign._id)
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_NAME, campaign.name)
      this.$store.commit(composer.SET_PUBLICATIONS_EDIT_COLOR, campaign.color || '')

      EventBus.$emit('setEditFolderName')
      $('#createFolder').modal('show')
    },
    ...mapActions(['setUtmsStatus', 'setUtms', 'setCurrentUtm']),
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave Misc:')
    $('#add_utm_modal').modal('hide')
    this.$bvModal.hide('add_hashtag_modal')
    next()
  },
}
</script>
