<script setup>
import { ref, onUnmounted, inject, computed } from 'vue'
import plansDetails from '@modules/billing/constants/plansDetails'
import SubscriptionPlanCard from '@modules/billing/components/SubscriptionPlanCard.vue'
import CstButton from '@ui/Button/CstButton.vue'
import PlansComparisonTable from '@modules/billing/components/PlansComparisonTable.vue'
import { EventBus } from '@common/lib/event-bus'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import PaddleCheckoutModal from '@modules/billing/components/PaddleCheckoutModal.vue'
import { useBilling } from '@modules/billing/composables/useBilling'

defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
  customClass: {
    type: String,
    default: '',
  },
  hideClose: {
    type: Boolean,
    default: false,
  },
})

// State
const {
  isCurrentBillingCycleMonthly,
} = useBilling()

const root = inject('root')
const { $bvModal } = root

const comparisonTableRef = ref(null)
const showDetailedComparison = ref(false)
const modalContext = ref('upgrade-plan')
const showScrollToTopButton = ref(false)


// Initialize isAnnually based on the user's current billing cycle
const isAnnually = ref(!isCurrentBillingCycleMonthly())

// Listen for context updates
EventBus.$on('set-subscription-modal-context', (context) => {
  modalContext.value = context
})

const changeTrialPlan = computed(
  () => modalContext.value === 'change-trial-plan'
)

onUnmounted(() => {
  EventBus.$off('set-subscription-modal-context')
  const modalBody = document.querySelector('#scroller-container')
  if (modalBody) {
    modalBody.removeEventListener('scroll', () => {})
  }
})

const scrollToTop = () => {
  const modalBody = document.querySelector('#scroller-container')
  if (modalBody) {
    modalBody?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}

const toggleDetailedComparison = () => {
  showDetailedComparison.value = !showDetailedComparison.value
  if (showDetailedComparison.value) {
    setTimeout(() => {
      comparisonTableRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 0)

    const modalBody = document.querySelector('#scroller-container')
    if (modalBody) {
      modalBody.addEventListener('scroll', () => {
        showScrollToTopButton.value =
          showDetailedComparison.value && modalBody.scrollTop > 400
      })
    }
  }
}

const handleYearlyToggle = (value) => {
  isAnnually.value = value
}

const handleConfirmPurchase = () => {
  // Hide the confirmation modal
  $bvModal.hide('plan-upgrade-purchase-confirmation-modal')
  // Emit event with billing period
  EventBus.$emit('confirm-plan-purchase', isAnnually.value)
}

const handleCancelPurchase = () => {
  EventBus.$emit('cancel-plan-purchase')
}
</script>

<template>
  <div
    id="scroller-container"
    class="space-y-6 overflow-auto h-full no-scrollbar p-6 relative"
    :class="customClass"
  >
    <div class="flex justify-center">
      <div
        v-if="!changeTrialPlan"
        class="flex items-center rounded-md bg-gray-100 p-1 w-[26rem]"
      >
        <button
          class="flex-1 rounded-md px-5 py-2.5 text-center transition !border-0"
          :class="isAnnually ? 'bg-transparent' : 'bg-white font-semibold'"
          @click="isAnnually = false"
          >Monthly
        </button>
        <button
          class="flex-1 rounded-md px-4 py-2 text-center transition !border-0"
          :class="isAnnually ? 'bg-white font-semibold' : 'bg-transparent'"
          @click="isAnnually = true"
        >
          <span class="flex items-center justify-center">
            Yearly
            <span
              class="ml-2 bg-blue-50 text-blue-500 uppercase font-bold text-xs px-3 py-1 rounded-full whitespace-nowrap"
            >
              Save up to 34%
            </span>
          </span>
        </button>
      </div>
    </div>
    <template v-if="!hideClose">
      <div
        v-tooltip="'Close'"
        class="absolute top-0 right-5 flex items-center justify-center bg-gray-100 rounded ml-[12px] w-8 h-8 cursor-pointer p-1"
        @click="$bvModal.hide('subscription-plans-modal')"
      >
        <i class="fa fa-times font-thin text-lg"></i>
      </div>
    </template>
    <div class="flex gap-6 justify-center pt-6">
      <SubscriptionPlanCard
        v-for="plan in plansDetails"
        :key="plan.id"
        :is-annually="isAnnually"
        :is-popular="plan.id === 'agency-unlimited'"
        :plan="plan"
        :change-trial-plan="changeTrialPlan"
      />
    </div>
    <div class="flex w-full justify-center">
      <CstButton
        text="Compare plans in detail"
        variant="outlined"
        :disabled="showDetailedComparison"
        @click="toggleDetailedComparison"
      />
    </div>

    <div v-if="showDetailedComparison" ref="comparisonTableRef">
      <PlansComparisonTable
        :is-annually="isAnnually"
        :change-trial-plan="changeTrialPlan"
        class="pt-12"
        @toggle-yearly="handleYearlyToggle"
      />
    </div>

    <div
      v-show="showScrollToTopButton"
      class="fixed bottom-4 left-4 z-50 cursor-pointer rounded-full bg-gray-600 opacity-60 p-2 text-white shadow-lg transition-opacity hover:bg-gray-800 hover:opacity-100"
      @click="scrollToTop"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
      </svg>
    </div>
  </div>
  <CstConfirmationPopup
    modal-name="plan-upgrade-purchase"
    primary-button-text="Proceed"
    secondary-button-text="Cancel"
    @confirmed="handleConfirmPurchase"
    @cancelled="handleCancelPurchase"
  >
    <template v-slot:head>
      <p v-if="changeTrialPlan" class="text-lg font-medium"
        >Confirm Plan Change</p
      >
      <p v-else class="text-lg font-medium">Confirm Purchase</p>
    </template>
    <template v-slot:body>
      <p v-if="changeTrialPlan" class="text-sm py-4">
        Are you sure you want to proceed with the plan change? This will update
        your current subscription plan and cannot be undone.
      </p>
      <p v-else class="text-sm py-4">
        Are you sure you want to proceed with the purchase? This will update
        your current subscription plan and cannot be undone.
      </p>
    </template>
  </CstConfirmationPopup>
  <PaddleCheckoutModal />
</template>
