<template>
  <div
    class="w-full bg-white rounded-xl border container-box-shadow transition-all duration-200 ease-in-out"
    :class="[
      { '!border-[#FFE48B] !bg-[#FFFCF3]': isNote },
      { 'flex gap-2': miniBox },
      { 'opacity-75 pointer-events-none': disabled },
      customClass,
    ]"
    tabindex="0"
    @keydown="handleKeyDown"
  >
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :accept="props.fileAccept"
      @change="handleFileSelect"
    />
    <div
      class="flex items-center justify-between px-6 py-3"
      :class="{ 'flex-1 !px-3 !py-1.5': miniBox, 'bottom-border-only': !miniBox }"
    >
      <div class="w-full">
        <template v-if="!isNote">
          <textarea
            ref="messageTextarea"
            v-model="messageText"
            class="w-full resize-none outline-none bg-transparent"
            :class="{ 'cursor-not-allowed': disabled }"
            :placeholder="placeholder"
            :rows="miniBox ? 1 : 2"
            maxlength="1000"
            :disabled="disabled"
            @input="autoResize"
          ></textarea>
        </template>
        <template v-else>
          <AtTa
            ref="atTa"
            :members="teamMembers"
            name-key="name"
            @insert="mentionMember"
          >
          <template
            v-slot:item="s"
          >
            <img
              :src="
                s.item.image
                  ? s.item.image
                  : DEFAULT_IMAGE
              "
              alt="profile image"
              class="rounded-full mr-2 w-6 h-6 object-fill"
              @error="$event.target.src = DEFAULT_IMAGE"
              />
            <span v-text="s.item.name"> </span>
          </template>
          <textarea
            ref="messageTextarea"
            v-model="messageText"
            class="w-full resize-none outline-none bg-transparent"
            :class="{ 'cursor-not-allowed': disabled }"
            :placeholder="placeholder"
            :rows="miniBox ? 1 : 2"
            maxlength="1000"
            :disabled="disabled"
            @input="autoResize"
          ></textarea>
          </AtTa>

        </template>
        <!-- File Preview -->
        <div v-if="selectedFile" class="mt-2 flex items-center gap-2 p-2 bg-gray-100 rounded-lg">
          <div class="flex items-center gap-2 flex-1">
            <i
              :class="[
                selectedFile.type.startsWith('image/') ? 'far fa-image' :
                selectedFile.type.startsWith('video/') ? 'far fa-video' : 'far fa-file',
                'text-gray-700',
              ]"
            ></i>
            <div class="flex flex-col">
              <span v-tooltip="selectedFile.name" class="text-sm truncate max-w-[135px]">{{ selectedFile.name }}</span>
              <span class="text-xs text-gray-700">{{ formatFileSize(selectedFile.size) }}</span>
            </div>
          </div>
          <button
            class="p-1 hover:bg-gray-200 rounded-full transition-colors"
            @click="removeSelectedFile"
          >
            <i class="far fa-times text-gray-700"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between px-6 py-3" :class="{ 'gap-2 !px-3 !py-1.5': miniBox }">
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-[8px]">
          <template v-for="(item, i) in filteredIconButtonMap" :key="i">
            <div v-if="!isNote || item.showOnNote" class="relative">
              <CstEmojiPicker
                v-if="item.value === 'emoji'"
                position="bottom-start"
                :ignore-collapsed-tigger="true"
                @on-select="onEmojiSelect"
              >
                <template v-slot:trigger>
                  <div v-tooltip="item.name" class="relative flex justify-center items-center rounded-lg bg-[#E6F0FC] cursor-pointer w-7 h-7">
                    <i :class="[item.icon, 'text-base leading-none text-[#75797C]']"></i>
                  </div>
                </template>
              </CstEmojiPicker>
              <div
              v-if="item.value !== 'emoji'"
                v-tooltip="item.name"
                class="relative flex justify-center items-center rounded-lg bg-[#E6F0FC] cursor-pointer w-7 h-7"
                :class="{ 'opacity-50 cursor-not-allowed': isNote && item.value === 'attachment' }"
                @click.stop="handleIconClick(item.value)"
              >
                <i :class="[item.icon, 'text-base leading-none text-[#75797C]']" ></i>
                <div v-if="item.value === 'attachment' && selectedFile" class="absolute -top-1 -right-1 w-2 h-2 rounded-full bg-blue-500"></div>
              </div>
            </div>
          </template>
          <template v-if="allowNote">
            <div class="h-6 w-px bg-gray-300"></div>
            <CstSwitch
              v-model="isNote"
              v-tooltip="'Toggle Note (Alt + N)'"
              class="mx-2"
              size="small"
              label="Note"
              label-class="!font-normal leading-none whitespace-nowrap"
            />
          </template>
        </div>
      </div>
      <div v-if="miniBox" class="h-6 w-px bg-gray-300"></div>
      <div class="flex gap-2">
        <!-- <template v-if="!miniBox">
          <div class="hidden xl:flex items-center gap-2 text-xs text-[#75797C]">
            <span class="flex items-center gap-1">
              <kbd class="px-1.5 py-0.5 rounded cstu-bg-gray-500 font-medium">Ctrl + Enter</kbd>
              <span>to send</span>
            </span>
            <template v-if="allowNote">
              <span class="text-[#D1D5DB]">•</span>
              <span class="flex items-center gap-1">
                <kbd class="px-1.5 py-0.5 rounded cstu-bg-gray-500 font-medium">Alt + N</kbd>
                <span>to toggle note</span>
              </span>
            </template>
          </div>
        </template> -->

        <Button
          v-tooltip="'Send (Ctrl + Enter)'"
          variant="filled"
          color="primary"
          size="xs"
          :disabled="isSendDisabled"
          @click="handleSend"
        >
        <template v-if="isNote">
          <span class="text-sm whitespace-nowrap leading-none">
            Add Note
          </span>
        </template>
        <template v-else>
          <div class="flex items-center gap-2.5 text-sm whitespace-nowrap leading-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M13.9275 6.01563L3.55705 0.830448C3.14778 0.626751 2.68566 0.554233 2.23367 0.622783C1.78168 0.691332 1.36183 0.897615 1.03136 1.2135C0.700901 1.52938 0.475902 1.9395 0.387043 2.38793C0.298184 2.83637 0.34979 3.3013 0.534826 3.71934L2.31261 7.69712C2.35295 7.79329 2.37372 7.89653 2.37372 8.00081C2.37372 8.10511 2.35295 8.20835 2.31261 8.30452L0.534826 12.2823C0.384234 12.6206 0.320571 12.9912 0.349624 13.3603C0.378676 13.7295 0.499524 14.0856 0.701186 14.3962C0.902839 14.7067 1.17892 14.962 1.50432 15.1387C1.82973 15.3155 2.19415 15.4082 2.56446 15.4082C2.91129 15.4048 3.25296 15.3238 3.56446 15.1712L13.9348 9.98602C14.3027 9.80096 14.6119 9.51731 14.8279 9.16682C15.044 8.81626 15.1584 8.41259 15.1584 8.00081C15.1584 7.58905 15.044 7.18537 14.8279 6.83483C14.6119 6.4843 14.3027 6.20068 13.9348 6.01563H13.9275ZM13.2682 8.66008L2.89779 13.8452C2.76161 13.9106 2.6087 13.9329 2.45956 13.9089C2.31041 13.8849 2.17216 13.8159 2.06335 13.7111C1.95455 13.6063 1.88037 13.4707 1.85078 13.3226C1.82119 13.1745 1.83759 13.0209 1.89779 12.8823L3.66816 8.90451C3.69108 8.8514 3.71087 8.79699 3.72742 8.74156H8.8311C9.02754 8.74156 9.21599 8.66352 9.35492 8.5246C9.49385 8.38569 9.5719 8.19728 9.5719 8.00081C9.5719 7.80436 9.49385 7.61595 9.35492 7.47704C9.21599 7.33812 9.02754 7.26008 8.8311 7.26008H3.72742C3.71087 7.20465 3.69108 7.15023 3.66816 7.09712L1.89779 3.11934C1.83759 2.98079 1.82119 2.82715 1.85078 2.67902C1.88037 2.53089 1.95455 2.39534 2.06335 2.29056C2.17216 2.18577 2.31041 2.11677 2.45956 2.09278C2.6087 2.0688 2.76161 2.09099 2.89779 2.15637L13.2682 7.34156C13.3895 7.40372 13.4914 7.49817 13.5624 7.61449C13.6335 7.7308 13.6712 7.86449 13.6712 8.00081C13.6712 8.13715 13.6335 8.27083 13.5624 8.38715C13.4914 8.50347 13.3895 8.59792 13.2682 8.66008Z" fill="white"/>
            </svg>
            <template v-if="!miniBox">
              Send
            </template>
          </div>
        </template>
        </Button>
      </div>
    </div>
  </div>
  <SavedReplyModal
    v-if="renderReplyModal"
    :active-inbox-detail="props.activeInboxDetail"
    :apply-saved-reply="applySavedReply"
  ></SavedReplyModal>
</template>

<script setup>
import { ref, defineEmits, defineProps, inject, nextTick, watch, computed, defineExpose, onMounted } from 'vue'
import CstSwitch from '@ui/Switch/CstSwitch'
import { Button } from '@contentstudio/ui'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import SavedReplyModal from '@src/modules/inbox/components/savereplies/SavedReplyModal'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import { useStore } from '@state/base'

const { getters } = useStore()

const DEFAULT_IMAGE = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

const root = inject('root')
const { $bvModal } = root

const props = defineProps({
  activeInboxDetail: {
    type: Object,
    default: () => ({})
  },
  initialMessage:{
    type: String,
    default: ''
  },
  customClass:{
    type: String,
    default: ''
  },
  allowNote: {
    type: Boolean,
    default: true,
  },
  miniBox: {
    type: Boolean,
    default: false
  },
  allowAttachment: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Type your message'
  },
  fileAccept: {
    type: String,
    default: 'image/*,video/*'
  }
})

const emit = defineEmits(['send'])
const fileInput = ref(null)
const selectedFile = ref(null)
const iconButtonMap = [
  {
    value: 'emoji',
    name: 'Add Emoji',
    icon: 'fal fa-smile-beam',
    showOnNote: true,
  },
  {
    value: 'attachment',
    name: 'Add Attachment',
    icon: 'far fa-paperclip',
    showOnNote: false,
  },
  {
    value: 'saved-replies',
    name: 'Saved Replies',
    icon: 'icon-Save',
    showOnNote: false,
  },
]

const messageText = ref(props.initialMessage)
const messageTextarea = ref(null)
const mentionedUsers = ref([])
const teamMembers = ref([])

// Watch for changes in initialMessage prop
watch(() => props.initialMessage, (newValue) => {
  messageText.value = newValue
})

const isNote = ref(false)
const isEmojiPickerOpen = ref(false)
const renderReplyModal = ref(false)

const filteredIconButtonMap = computed(() => {
  return iconButtonMap.filter(item => {
    if (item.value === 'attachment') {
      return props.allowAttachment
    }
    return true
  })
})

const isSendDisabled = computed(() => {
  if (props.disabled) return true;
  const hasNoContent = !messageText.value.trim() && !selectedFile.value;
  const isUnchanged = messageText.value.trim() === props.initialMessage.trim();
  return hasNoContent || (isUnchanged && !selectedFile.value);
})

onMounted(() => {
  createMentionUsers(getters.getWorkspaceInboxTeamMembers)
  nextTick(() => {
    autoResize({ target: messageTextarea.value })
  })
})

const validateMentions = () => {
  const validMentions = []
  for (const user of mentionedUsers.value) {
    // Check if @username exists in the message
    const mentionText = `@${user.name}`
    if (messageText.value.includes(mentionText)) {
      validMentions.push(user)
    }
  }
  return validMentions
}

const handleSend = () => {
  if (isSendDisabled.value) return

  const validMentions = validateMentions()

  emit('send', {
    text: messageText.value,
    isNote: isNote.value,
    attachment: selectedFile.value,
    mentionedUsers: validMentions
  })

  // Reset the input after sending
  messageText.value = ''
  selectedFile.value = null
  mentionedUsers.value = []
  // Reset textarea height
  const textarea = document.querySelector('textarea')
  if (textarea) {
    nextTick(() => {
      autoResize({ target: textarea })
    })
  }
}

const onEmojiSelect = (emojiUnicode) => {
  messageText.value = messageText.value || ''
  const textarea = document.querySelector('textarea')
  if (!textarea) return

  const cursorPosition = textarea.selectionStart
  const newText =
    messageText.value.slice(0, cursorPosition) +
    emojiUnicode +
    messageText.value.slice(cursorPosition)

  messageText.value = newText
  textarea.selectionStart = cursorPosition + emojiUnicode.length
  textarea.selectionEnd = cursorPosition + emojiUnicode.length

  // Trigger the resize after emoji insertion
  autoResize({ target: textarea })
}

const handleIconClick = (value) => {
  if (value === 'emoji') {
    isEmojiPickerOpen.value = !isEmojiPickerOpen.value
  }
  else if (value === 'attachment' && !isNote.value) {
    fileInput.value.click()
  }
  else if (value === 'saved-replies') {
    renderReplyModal.value = true
    nextTick(() => {
      $bvModal.show('savedReplyModal')
    })
  }
}

const handleKeyDown = (event) => {
  if(props.disabled) return
  // Check for Ctrl+Enter (Windows/Linux) or Cmd+Enter (Mac)
  if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
    event.preventDefault() // Prevent new line
    handleSend()
  }
  else if (event.key === 'Escape') {
    isEmojiPickerOpen.value = false
  }
  else if (props.allowNote && (event.altKey) && event.key.toLowerCase() === 'n') {
    event.preventDefault()
    isNote.value = !isNote.value
  }
}

const applySavedReply = (replyText) => {
  messageText.value = replyText
  const textarea = document.querySelector('textarea')
  if (textarea) {
    nextTick(() => {
      autoResize({ target: textarea })
    })
  }

  setTimeout(() => {
    renderReplyModal.value = false
  }, 1000)
}

const handleFileSelect = (event) => {
  const file = event.target.files[0]
  if (!file) return

  // Check file type against the fileAccept prop
  const allowedTypes = props.fileAccept.split(',').map(t => t.trim())
  const fileType = file.type
  const fileName = file.name.toLowerCase()
  let isValidType = false

  for (const type of allowedTypes) {
    if (type.startsWith('.')) {
      // Check extension
      if (fileName.endsWith(type)) {
        isValidType = true
        break
      }
    } else if (type.endsWith('/*')) {
      // Check MIME type wildcard
      const baseType = type.slice(0, -2)
      if (fileType.startsWith(baseType + '/')) {
        isValidType = true
        break
      }
    } else {
      // Check exact MIME type
      if (fileType === type) {
        isValidType = true
        break
      }
    }
  }

  if (!isValidType) {
    // Use a more generic message or potentially list allowed types
    $bvModal.msgBoxOk(`Please select a file of an allowed type (${props.fileAccept}).`, {
      title: 'Invalid File Type',
      okVariant: 'primary',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true
    })
    event.target.value = null
    return
  }

  // Check file size (10MB limit)
  const maxSize = 10 * 1024 * 1024 // 10MB in bytes
  if (file.size > maxSize) {
    $bvModal.msgBoxOk('File size should not exceed 10MB.', {
      title: 'File Too Large',
      okVariant: 'primary',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true
    })
    event.target.value = null
    return
  }

  selectedFile.value = file
}

function autoResize(event) {
  event.target.style.height = 'auto';
  event.target.style.height = event.target.scrollHeight + 'px';
}

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
}

const removeSelectedFile = () => {
  selectedFile.value = null
  if (fileInput.value) {
    fileInput.value.value = null
  }
}

const mentionMember = (userItem) => {
  // Check if user is already in mentionedUsers
  const index = mentionedUsers.value.findIndex((x) => x.id === userItem.id)
  if (index < 0) {
    mentionedUsers.value.push(userItem)
  }
}

const createMentionUsers = (members) => {
  teamMembers.value = []
  for (const item in members) {
    if (members[item]?.user && members[item]?.user_id) {
      teamMembers.value.push({
        id: members[item].user_id,
        name: members[item].user.full_name,
        image: members[item].user.image,
        email: members[item].user.email,
      })
    }
  }
}

const focusTextarea = () => {
  if (messageTextarea.value && !props.disabled) {
    messageTextarea.value.focus()
  }
}

// Watch for changes in isNote
watch(isNote, (newValue) => {
  if (newValue && selectedFile.value) {
    removeSelectedFile()
  }
  // Clear mentions when switching modes
  mentionedUsers.value = []
})

// Watch for message text changes to validate mentions
watch(messageText, () => {
  nextTick(() => {
    if (isNote.value) {
      mentionedUsers.value = validateMentions()
    }
  })
})

defineExpose({
  focusTextarea
})
</script>

<style scoped>
textarea {
  max-height: calc(1.5em * 6); /* Approximately 6 rows of text */
}
.container-box-shadow{
  box-shadow: 0px 1px 2px 0px rgba(184, 200, 224, 0.22);
}
</style>
